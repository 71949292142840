import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApplicationState } from '../../../store/index';
import UTEContactPersonModal from './UTEContactPersonModal';
import {
	ISubDomainExistsResponse,
	SUBDOMAIN_EXISTS_ENUM,
	initialUTEContactPerson,
	IUTESetting,
} from './ClientHub.model';
import { ClientHubNotifications } from '../../../helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import './ClientHub.style.scss';
import OneHubUrl from './one-hub-url';
import { isValidSubDomain } from 'src/helper/formValidations';
import SuiteModal from 'src/components/common/SuiteModal';
import UpdateURLModal from './update-url-modal';
import { checksubDomainExists, updateSubDomain } from './ClientHub.api';
import RetentionPolicy from './retention-policy';
import InfoIcon from 'src/components/svg/InfoIcon';

interface IClientHub {
	startLoader: (text: string) => void;
	stopLoader: () => void;
	notification: (statusMessage: string, statusType: StatusType) => void;
	requestUTESetting: (callback?: () => void) => void;
	updateUTESetting: (uteEnabled: boolean, callback?: () => void) => void;
	updateRetentionPolicy: (uteSetting: IUTESetting, callback: (updateResponseSuccessful: boolean) => void) => void;
	requestUTEContactPerson: (callback?: () => void) => void;
	addUTEContactPerson: (contactPerson: IUTEContactPerson, callback?: () => void) => void;
	updateUTEContactPerson: (contactPerson: IUTEContactPerson, callback?: () => void) => void;
}

export interface IUTEContactPerson {
	id: number;
	name: string;
	emailAddress: string;
	countryCode: string;
	mobileNumber: string;
}

const ClientHub: React.FC<IClientHub> = ({
	startLoader,
	stopLoader,
	notification,
	requestUTESetting,
	updateUTESetting,
	updateRetentionPolicy,
	requestUTEContactPerson,
	addUTEContactPerson,
	updateUTEContactPerson,
}) => {
	const { uteSetting, uteContactPerson } = useSelector((state: ApplicationState) => state.uteSetting);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [isUteSettingEnabled, setIsUteSettingEnabled] = useState<boolean>(false);
	const [subDomainName, setSubDomainName] = useState('');
	const [showConfirmURLPopUp, setShowConfirmURLPopUp] = useState(false);
	const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
	const [contactPerson, setContactPerson] = useState<IUTEContactPerson>(initialUTEContactPerson);
	const [isEditClicked, setIsEditClicked] = useState(false);
	const [urlError, setUrlError] = useState('');

	useEffect(() => {
		requestUTESetting();
		requestUTEContactPerson();
	}, []);

	useEffect(() => {
		setIsUteSettingEnabled(uteSetting.uteEnabled);
	}, [uteSetting.uteEnabled]);

	useEffect(() => {
		setSubDomainName(uteSetting.subdomain);
	}, [uteSetting.subdomain]);

	useEffect(() => {
		setContactPerson(uteContactPerson);
	}, [uteContactPerson]);

	const handleToggleSwitchChange = () => {
		if (contactPerson.id === 0 && !isUteSettingEnabled) {
			setIsUteSettingEnabled(!isUteSettingEnabled);
			setShowModal(true);
		} else {
			saveUteSetting();
		}
	};

	const editContactPerson = () => {
		setContactPerson(uteContactPerson);
		setShowModal(true);
	};

	const saveUteSetting = () => {
		startLoader(ClientHubNotifications.UPDATINGCLIENTHUBSETTING);
		updateUTESetting(!isUteSettingEnabled, () => {
			requestUTESetting(() => {
				stopLoader();
				notification(ClientHubNotifications.UPDATECLIENTHUBSETTINGSUCCESS, StatusType.Success);
			});
		});
	};

	const saveContactPerson = () => {
		startLoader(ClientHubNotifications.UPDATINGCONTACTPERSON);
		setShowModal(false);
		updateUTEContactPerson(contactPerson, () => {
			requestUTEContactPerson(() => {
				stopLoader();
				notification(ClientHubNotifications.EDITCONTACTPERSONSUCCESS, StatusType.Success);
			});
		});
	};

	const saveUteSettingAndContactPerson = () => {
		startLoader(ClientHubNotifications.UPDATINGCLIENTHUBSETTING);
		setShowModal(false);
		addUTEContactPerson(contactPerson, () => {
			updateUTESetting(isUteSettingEnabled, () => {
				requestUTEContactPerson(() => {
					notification(ClientHubNotifications.ADDCONTACTPERSONSUCCESS, StatusType.Success);
				});
				requestUTESetting(() => {
					stopLoader();
					notification(ClientHubNotifications.UPDATECLIENTHUBSETTINGSUCCESS, StatusType.Success);
				});
			});
		});
	};

	const closeModal = () => {
		if (contactPerson.id === 0) {
			setShowModal(false);
			setContactPerson(initialUTEContactPerson);
			setIsUteSettingEnabled(false);
		} else {
			setShowModal(false);
			setContactPerson(contactPerson);
		}
	};

	const handleEditClicked = (editClicked: boolean) => {
		setIsEditClicked(editClicked);
		if (!editClicked) {
			setSubDomainName(uteSetting.subdomain);
			setIsUpdateDisabled(true);
			setUrlError('');
		}
	};

	const handleSubDomainChange = (newSubdomain: string) => {
		setIsUpdateDisabled(false);
		setSubDomainName(newSubdomain);
		setUrlError('');
	};

	const handleUpdateSubDomain = () => {
		if (isValidSubDomain(subDomainName)) {
			setIsUpdateDisabled(true);
			checksubDomainExists(subDomainName, (subDomainExistsResponse: ISubDomainExistsResponse) => {
				setIsUpdateDisabled(false);
				const { value, error } = subDomainExistsResponse;
				if (error) {
					notification(ClientHubNotifications.ERROR_CHECK_SUBDOMAIN_EXISTS, StatusType.Error);
				} else if (value === SUBDOMAIN_EXISTS_ENUM.INVALID) {
					setUrlError(ClientHubNotifications.ERROR_SUBDOMAIN_INVALID);
				} else if (value === SUBDOMAIN_EXISTS_ENUM.EXISTS) {
					setUrlError(ClientHubNotifications.ERROR_SUBDOMAIN_EXISTS);
				} else if (value === SUBDOMAIN_EXISTS_ENUM.NOTEXISTS) {
					setUrlError('');
					setShowConfirmURLPopUp(true);
				}
			});
		} else {
			setUrlError(ClientHubNotifications.ERROR_SUBDOMAIN_INVALID);
		}
	};

	const onHide = (): void => {
		setShowConfirmURLPopUp(false);
	};

	const handleConfirmSubDomain = () => {
		startLoader(ClientHubNotifications.UPDATINGCLIENTHUBSETTING);
		setShowConfirmURLPopUp(false);
		updateSubDomain(subDomainName, (updateResponseSuccessful: boolean) => {
			if (updateResponseSuccessful) {
				setIsEditClicked(false);
				requestUTESetting();
				stopLoader();
			} else {
				notification(ClientHubNotifications.ERROR_UPDATING_SUBDOMAIN, StatusType.Error);
				stopLoader();
			}
		});
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>Client Portal Experience</h3>
			<p className='suitePageSectionDescription'>
				If enabled, clients can access their tasks and historical tax documents
				<br />
				from one centralized location. Once enabled, a contact person will need to be added.
			</p>
			<div className='switchSettingsBlock form-switch'>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='isSMSNotificationEnabled'
					data-test-auto='1247ec82-5688-4166-a6a4-cef72d641486'
					checked={isUteSettingEnabled}
					onChange={handleToggleSwitchChange}
				/>
				<span className='switchSettingsText'>Client Portal</span>
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement='right'
					rootClose
					overlay={
						<Tooltip className='clientHubTooltip'>
							Access Client Portal via email or entering the URL in the browser. Login credentials are required.
						</Tooltip>
					}>
					{({ ref, ...triggerHandler }) => (
						<span
							className='switchSettingsIcon'
							style={{ marginLeft: '-6px' }}
							data-test-auto='d5bf1213-0a4d-45eb-afef-bf4062f04e9a'
							ref={ref}
							{...triggerHandler}>
							<InfoIcon size={14} />
						</span>
					)}
				</OverlayTrigger>
			</div>

			{showModal && (
				<UTEContactPersonModal
					contactPerson={contactPerson}
					setContactPerson={setContactPerson}
					closeModal={closeModal}
					saveContactPerson={saveContactPerson}
					saveUteSettingAndContactPerson={saveUteSettingAndContactPerson}
				/>
			)}

			{showConfirmURLPopUp && (
				<SuiteModal
					theme='dark'
					title='Update URL Confirmation'
					width='500'
					hide={onHide}>
					<UpdateURLModal
						subdomain={subDomainName}
						hide={onHide}
						handleConfirmSubDomain={handleConfirmSubDomain}
					/>
				</SuiteModal>
			)}

			{isUteSettingEnabled && contactPerson.id !== 0 && (
				<>
					<Button
						data-test-auto='31bd5e56-66bc-45c6-a684-e4a3f40e681f'
						className='editContactPersonButton'
						variant='outline-primary'
						onClick={editContactPerson}>
						Edit Contact Person
					</Button>

					<OneHubUrl
						subDomain={subDomainName}
						onSubDomainChange={handleSubDomainChange}
						onUpdateSubDomain={handleUpdateSubDomain}
						isUpdateDisabled={isUpdateDisabled}
						isEditClicked={isEditClicked}
						onEditClicked={handleEditClicked}
						urlError={urlError}
					/>
					<RetentionPolicy
						uteSetting={uteSetting}
						startLoader={startLoader}
						stopLoader={stopLoader}
						notification={notification}
						updateRetentionPolicy={updateRetentionPolicy}
					/>
				</>
			)}
		</article>
	);
};

export default ClientHub;
