import '@sssuite-component-ui/react-phone-input-2/lib/style.css';
import './SaveUserModal.style.scss';

import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import PhoneInput from '@sssuite-component-ui/react-phone-input-2';
import CloseIcon from 'src/components/svg/CloseIcon';
import ExclamationError from 'src/components/svg/ExclamationError';
import InfoIcon from 'src/components/svg/InfoIcon';
import SearchIcon from 'src/components/svg/SearchIcon';
import { isValidEmailAddress, isValidPTIN, isValidTenDigitNumber } from 'src/helper/formValidations';

import CustomMultiDropdown from '../../../../components/common/customMultiDropdown';
import { CountryData } from '../../../../components/common/myAccount/profile';
import { IOfficeLocation } from '../../../../modules/clientManagement/Client.model';
import { ICustomDropdownOption } from '../../../common/filter';
import { MyAccountConstants, UserManagementConstants } from 'src/helper/Constants';
import { modificationType } from '..';
import { ISaveUserModel, IUserGroups, newUser, PageProps, SSORevokeStatus, UserReviewStatus } from '../Users.model';
import { changeTypeOfUserOfficeLocations } from './helper';
import { addNewUser, updateUserInfo } from './SaveUserModal.api';
import { IUserGroup } from '../../../../modules/securitySettings/SSOSettings/SSOSettings.model';
import { IAddUserReturn, IContactPersonViewModelList, IEditUserResponse, IEditUserReturn } from './SaveUserModal.model';
import { Loader } from 'react-overlay-loader';
import { useSelector } from 'react-redux';
import { isValidMobileNumber } from '@sssuite-js-packages/mobile-number-validator';
import { handleInvalidCountryCodeEntered } from '../../../../helper/HelperFunctions';

interface SaveUserModalProps {
	saveForUserId: number;
	setShowSaveUserModal: (showSaveUserModal: boolean) => void;
	requestUserById: (id: number, callback: (user: ISaveUserModel) => void) => void;
	companyEnableMFA: boolean;
	availableUserGroupList: IUserGroups[];
	newUserIds: number[];
	setNewUserIds: (newUserIds: number[]) => void;
	requestAllUsers: (
		reload: boolean,
		pageIndex: number,
		pageSize: number,
		searchString: string,
		newUserIds: string,
		officeLocations: string,
	) => void;
	page: PageProps;
	isO365User: boolean;
	userReviewStatus: number;
	searchText: string;
	officeLocationList: IOfficeLocation[];
	toggleContactPersonModal: (bool: boolean) => void;
	setContactPersonModalData: (data: IContactPersonViewModelList[]) => void;
	setEditedUser: (editedUser: ISaveUserModel) => void;
	setProcessType: (processType: modificationType) => void;
	selectedOfficeLocations: ICustomDropdownOption[];
}

const SaveUserModal: FC<SaveUserModalProps> = (props) => {
	const {
		saveForUserId,
		setShowSaveUserModal,
		companyEnableMFA,
		availableUserGroupList,
		newUserIds,
		setNewUserIds,
		requestAllUsers,
		page,
		isO365User,
		userReviewStatus,
		searchText,
		officeLocationList,
		toggleContactPersonModal,
		setContactPersonModalData,
		setEditedUser,
		setProcessType,
		selectedOfficeLocations,
	} = props;

	const [user, setUser] = useState(newUser);
	const [userFirstName, setUserFirstName] = useState(user.firstName);
	const [userLastName, setUserLastName] = useState(user.lastName);
	const [userEmail, setUserEmail] = useState(user.emailAddress);
	const [userConfirmEmail, setUserConfirmEmail] = useState('');
	const [userPhone, setUserPhone] = useState(user.phone);
	const [userExtension, setUserExtension] = useState(user.extension);
	const [userEnableMFA, setUserEnableMFA] = useState(saveForUserId === 0 ? companyEnableMFA : user.isMFAEnabled);
	const [userInactive, setUserInactive] = useState(saveForUserId === 0 ? false : user.inactive);
	const [savedUserIsInactive, setSavedUserInactiveStatus] = useState(saveForUserId === 0 ? false : user.inactive);
	const [userFax, setUserFax] = useState(user.fax);
	const [userCountryCode, setUserCountryCode] = useState(user.countryCode ? user.countryCode : '+1');
	const [userMobile, setUserMobile] = useState(user.mobileNumber);
	const [userPTIN, setUserPTIN] = useState(user.ptin);
	const [userRevokeStatus, setUserRevokeStatus] = useState(user.revokeStatus);
	const [initialUserOfficeLocations, setInitialUserOfficeLocations] = useState<string>('');
	const [userOfficeLocation, setUserOfficeLocation] = useState(
		changeTypeOfUserOfficeLocations(user.officeLocations, officeLocationList),
	);
	const [inProgress, setInProgress] = useState(false);
	const [noEmailMatch, setNoEmailMatch] = useState(false);
	const [searchUserGroupText, setSearchUserGroupText] = useState('');
	const ugCheckArea = useRef(null);
	const userEmailRef = useRef<HTMLInputElement>(null);
	const [mandatoryFieldError, setMandatoryFieldError] = useState({
		userFirstName: { error: false, message: '' },
		userLastName: { error: false, message: '' },
		userEmail: { error: false, message: '' },
		userConfirmEmail: { error: false, message: '' },
		userInactive: { error: false, message: '' },
		userPhone: { error: false, message: '' },
		userFax: { error: false, message: '' },
		userMobile: { error: false, message: '' },
		userPTIN: { error: false, message: '' },
		userGroup: { error: false, message: '' },
		userOfficeLocation: { error: false, message: '' },
	});

	const [selectedUserGroupList, setSelectedUserGroupList] = useState<string[]>(
		user.userGroups.length > 0 ? user.userGroups.split(',') : [],
	);

	const companyData = useSelector((store) => {
		return store?.companyData;
	});

	const [mobileNumberFullValue, setMobileNumberFullValue] = useState<string>(`${userCountryCode}${userMobile}`);

	const updateUserGroupList = (isUserIsInactive: boolean): void => {
		if (isUserIsInactive) {
			const newSelectedUserGroupList = [IUserGroup.EROOnly.toString()];
			setUserEnableMFA(false);
			setUser({
				...user,
				inactive: isUserIsInactive,
				isMFAEnabled: false,
				userGroups: newSelectedUserGroupList.join(),
			});
			setSelectedUserGroupList(newSelectedUserGroupList);
			setMandatoryFieldError({
				...mandatoryFieldError,
				userGroup: { error: false, message: '' },
				userInactive: { error: false, message: '' },
			});
			return;
		}

		if (saveForUserId === 0) {
			setUserEnableMFA(companyEnableMFA);
			setUser({ ...user, inactive: false, isMFAEnabled: companyEnableMFA, userGroups: '' });
			setSelectedUserGroupList([]);
			setMandatoryFieldError({
				...mandatoryFieldError,
				userGroup: { error: true, message: UserManagementConstants.UserGroupMissing },
				userInactive: { error: false, message: '' },
			});
		} else if (!isUserIsInactive && savedUserIsInactive) {
			setUser({ ...user, inactive: false, userGroups: '' });
			setSelectedUserGroupList([]);
			setMandatoryFieldError({
				...mandatoryFieldError,
				userGroup: { error: true, message: UserManagementConstants.UserGroupMissing },
				userInactive: { error: false, message: '' },
			});
		} else if (saveForUserId > 0) {
			setUserModel();
		}
	};

	const [filteredUserGroupList, setFilteredUserGroupList] = useState(availableUserGroupList);

	const handleClose = (): void => {
		setShowSaveUserModal(false);
	};

	const handleChangeUserFirstName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setUserFirstName(event.target.value);
	};

	const handleOnBlurFirstName = (id: string, value: string): void => {
		value = value.trim();
		if (value.length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, firstName: value });
		}
	};

	const handleChangeUserLastName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setUserLastName(event.target.value);
	};

	const handleOnBlurLastName = (id: string, value: string): void => {
		value = value.trim();
		if (value.length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, lastName: value });
		}
	};

	const handleChangeUserEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setUserEmail(event.target.value);
	};

	const handleOnBlurEmail = (id: string, value: string): void => {
		value = value.trim();
		if (value.length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (!isValidEmailAddress(value)) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please fill valid Email Address';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, emailAddress: value });
			if (userConfirmEmail === userEmail && mandatoryFieldError.userConfirmEmail.error) {
				setMandatoryFieldError({ ...mandatoryFieldError, userConfirmEmail: { error: false, message: '' } });
			}
		}
	};

	const handleChangeUserConfirmEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setUserConfirmEmail(event.target.value);
	};

	const handleOnBlurConfirmEmail = (id: string, value: string): void => {
		value = value.trim();
		if (value.length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setNoEmailMatch(false);
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value !== userEmail) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError.userConfirmEmail.error = true;
			newMandatoryFieldError.userConfirmEmail.message = 'Email address does not match';
			setNoEmailMatch(true);
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (!isValidEmailAddress(value)) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError.userConfirmEmail.error = true;
			newMandatoryFieldError.userConfirmEmail.message = 'Please fill valid Email Address';
			setNoEmailMatch(false);
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setNoEmailMatch(false);
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, confirmEmail: value });
		}
	};

	const handleChangeUserPhone = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isValidTenDigitNumber(event.target.value)) setUserPhone(event.target.value);
	};

	const handleOnBlurPhone = (id: string, value: string): void => {
		value = value.trim();
		if (value.length === 0 && userExtension.length > 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value.length > 0 && value.length < 10) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message =
				'Please enter a 10 digit phone number';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, phone: value });
		}
	};

	const handleChangeUserExtension = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setUserExtension(event.target.value);
		setUser({ ...user, extension: event.target.value });
	};

	const handleOnBlurExtension = (id: string, value: string): void => {
		if (value.trim().length === 0 && userPhone.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError.userPhone.error = false;
			newMandatoryFieldError.userPhone.message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value.trim().length > 0 && userPhone.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError.userPhone.error = true;
			newMandatoryFieldError.userPhone.message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		}
	};

	const handleChangeUserEnableMFA = (): void => {
		setUserEnableMFA(!userEnableMFA);
		setUser({ ...user, isMFAEnabled: !userEnableMFA });
	};

	const handleChangeUserInactive = (): void => {
		setUserInactive(!userInactive);
		updateUserGroupList(!userInactive);
	};

	const handleChangeUserFax = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isValidTenDigitNumber(event.target.value)) setUserFax(event.target.value);
	};

	const handleOnBlurFax = (id: string, value: string): void => {
		value = value.trim();
		if (value.length > 0 && value.length < 10) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please enter a 10 digit fax number';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, fax: value });
		}
	};

	const onMobileChange = (fullValue: string, countryObject: CountryData): void => {
		fullValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
		setUserCountryCode(`+${countryObject.dialCode}`);
		setUserMobile(fullValue.slice(countryObject.dialCode.length));
		setUser({
			...user,
			countryCode: fullValue ? `+${countryObject.dialCode}` : '',
			mobileNumber: fullValue.slice(countryObject.dialCode.length),
		});
		setMobileNumberFullValue(fullValue);
	};

	const handleOnBlurMobile = (id: string): void => {
		console.log(userCountryCode + userMobile);
		if (userMobile.trim().length === 0) {
			if (companyEnableMFA && !userInactive && userEnableMFA) {
				const newMandatoryFieldError = { ...mandatoryFieldError };
				newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
				newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
				setMandatoryFieldError(newMandatoryFieldError);
			} else {
				const newMandatoryFieldError = { ...mandatoryFieldError };
				newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
				newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
				setMandatoryFieldError(newMandatoryFieldError);
			}
		} else if (
			userMobile &&
			userMobile.trim().length &&
			(!userCountryCode || (userCountryCode && userCountryCode === ''))
		) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please select country code';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (userMobile && userCountryCode && !isValidMobileNumber(userCountryCode, userMobile)) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message =
				'Please enter a valid mobile number, according to the country code selected';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		}
	};

	const handleChangeUserPTIN = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isValidPTIN(event.target.value)) setUserPTIN(event.target.value);
	};

	const handleOfficeLocationChanges = (option: any): void => {
		setUserOfficeLocation(option);
	};

	const handleOnBlur = (): void => {
		handleOnBlurOfficeLocation('userOfficeLocation', userOfficeLocation);
	};

	useEffect(() => {
		handleOnBlur();
	}, [userOfficeLocation]);

	const handleOnBlurOfficeLocation = (id: string, value: { label: string; value: number }[]): void => {
		if (value.length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'At least one location is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({
				...user,
				officeLocations: value
					.filter((i) => i.value != -1)
					.map((x) => x.value)
					.join(','),
			});
		}
	};

	const handleOnBlurPTIN = (id: string, value: string): void => {
		value = value.trim();
		if (userInactive && value.length < 9) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please enter a valid PTIN';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (value.length > 0 && value.length < 9) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please enter a valid PTIN';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setUser({ ...user, ptin: value });
		}
	};

	const handleEnableSSOChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.checked) setUser({ ...user, revokeStatus: SSORevokeStatus.None });
		else setUser({ ...user, revokeStatus: SSORevokeStatus.PermanentlyRevoked });
	};

	const handleUserGroupSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSearchUserGroupText(event.target.value);
		const newFilteredUserGroupList = availableUserGroupList.filter((userGroup) => {
			return userGroup.groupName.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0;
		});
		setFilteredUserGroupList(newFilteredUserGroupList);
	};

	const checkUserGroupSelection = (): void => {
		if (selectedUserGroupList.length === 0)
			setMandatoryFieldError({
				...mandatoryFieldError,
				userGroup: { error: true, message: 'Cannot save a User without any Groups' },
			});
		else setMandatoryFieldError({ ...mandatoryFieldError, userGroup: { error: false, message: '' } });
	};

	const handleUserGroupCheck = (event: React.ChangeEvent<HTMLInputElement>, userGroup: IUserGroups): void => {
		if (event.target.checked) {
			const newSelectedUserGroupList = [...selectedUserGroupList, userGroup.groupId.toString()];
			setUser({ ...user, userGroups: newSelectedUserGroupList.join() });
			setSelectedUserGroupList(newSelectedUserGroupList);
			setMandatoryFieldError({ ...mandatoryFieldError, userGroup: { error: false, message: '' } });
		} else {
			const newSelectedUserGroupList = selectedUserGroupList.filter((eachUserGroupId: string) => {
				return eachUserGroupId !== userGroup.groupId.toString();
			});
			setUser({ ...user, userGroups: newSelectedUserGroupList.join() });
			setSelectedUserGroupList(newSelectedUserGroupList);
			if (newSelectedUserGroupList.length === 0)
				setMandatoryFieldError({
					...mandatoryFieldError,
					userGroup: { error: true, message: UserManagementConstants.UserGroupMissing },
				});
		}
	};

	const deselectGroup = (event: React.MouseEvent<HTMLSpanElement>, groupId: number): void => {
		if (!userInactive) {
			const newSelectedUserGroupList = selectedUserGroupList.filter((eachUserGroupId: string) => {
				return eachUserGroupId !== groupId.toString();
			});
			setUser({ ...user, userGroups: newSelectedUserGroupList.join() });
			setSelectedUserGroupList(newSelectedUserGroupList);
			if (newSelectedUserGroupList.length === 0)
				setMandatoryFieldError({
					...mandatoryFieldError,
					userGroup: { error: true, message: UserManagementConstants.UserGroupMissing },
				});
		}
	};

	const checkAllMandatoryFields = (): boolean => {
		handleOnBlurFirstName('userFirstName', userFirstName);
		handleOnBlurLastName('userLastName', userLastName);
		handleOnBlurEmail('userEmail', userEmail);
		handleOnBlurConfirmEmail('userConfirmEmail', userConfirmEmail);
		handleOnBlurPhone('userPhone', userPhone);
		handleOnBlurExtension('userExtension', userExtension);
		handleOnBlurFax('userFax', userFax);
		handleOnBlurMobile('userMobile');
		handleOnBlurPTIN('userPTIN', userPTIN);
		handleOnBlurOfficeLocation('userOfficeLocation', userOfficeLocation);
		checkUserGroupSelection();
		for (const field in mandatoryFieldError)
			if (mandatoryFieldError[field as keyof typeof mandatoryFieldError].error) return false;

		return true;
	};

	const getRemovedOfficeLocations = (): string => {
		const initialUserOfficeLocationsArray = initialUserOfficeLocations.split(/,\s*/);
		const modifiedUserOfficeLocationsArray = userOfficeLocation.map((x) => x.value.toString());
		const uncommonArray = initialUserOfficeLocationsArray.filter(
			(value) => !modifiedUserOfficeLocationsArray.includes(value),
		);
		return uncommonArray.filter((i) => i != '-1').join(',');
	};

	const handleSaveUser = (): void => {
		if (checkAllMandatoryFields() && selectedUserGroupList.length !== 0) {
			setInProgress(true);
			if (saveForUserId === 0)
				addNewUser(user, (responseData: IAddUserReturn) => {
					const { userId, errorMessage } = responseData;
					setInProgress(false);
					if (userId === 0) {
						const newMandatoryFieldError = { ...mandatoryFieldError };
						newMandatoryFieldError.userEmail.error = true;
						const messageLength = errorMessage ? errorMessage.length : 0;
						if (errorMessage && errorMessage.includes('Invalid UserGroup')) {
							newMandatoryFieldError.userEmail.error = false;
							newMandatoryFieldError.userInactive.error = true;
							newMandatoryFieldError.userInactive.message = MyAccountConstants.EROOnlyGroupErrorMessage;
						} else if (errorMessage && errorMessage.charAt(messageLength - 1) === '.')
							newMandatoryFieldError.userEmail.message = errorMessage.substring(0, messageLength - 1);
						else newMandatoryFieldError.userEmail.message = errorMessage;

						setMandatoryFieldError(newMandatoryFieldError);
						if (userEmailRef.current !== null) userEmailRef.current.focus();
					} else {
						const changedNewUserIds = [...newUserIds, userId];
						setNewUserIds(changedNewUserIds);
						requestAllUsers(
							true,
							page.pageIndex,
							page.pageSize,
							searchText,
							changedNewUserIds.join(),
							selectedOfficeLocations.map((x) => x.value).join(),
						);
						handleClose();
					}
				});
			else {
				setProcessType(modificationType.Edit);
				const editedUser = { ...user, removedOfficeLocations: getRemovedOfficeLocations() };
				setEditedUser(editedUser);
				if (userReviewStatus === UserReviewStatus.YetToReview) editedUser.reviewStatus = UserReviewStatus.Reviewed;

				updateUserInfo(editedUser, (responseData: IEditUserReturn, successResponse?: IEditUserResponse) => {
					setInProgress(false);
					if (responseData.errorStatus === 1) {
						const newMandatoryFieldError = { ...mandatoryFieldError };
						var errorMessage = responseData.errorMessage;
						if (errorMessage === MyAccountConstants.SystemAdminDeletionErrorMessage) {
							newMandatoryFieldError.userGroup.error = true;
							const messageLength = errorMessage ? errorMessage.length : 0;
							if (errorMessage && errorMessage.charAt(messageLength - 1) === '.')
								newMandatoryFieldError.userGroup.message = errorMessage.substring(0, messageLength - 1);
							else newMandatoryFieldError.userGroup.message = errorMessage;
						} else {
							newMandatoryFieldError.userEmail.error = true;
							const errorMessage = responseData.errorMessage;
							const messageLength = errorMessage ? errorMessage.length : 0;
							if (errorMessage && errorMessage.includes('Invalid UserGroup')) {
								newMandatoryFieldError.userEmail.error = false;
								newMandatoryFieldError.userInactive.error = true;
								newMandatoryFieldError.userInactive.message = MyAccountConstants.EROOnlyGroupErrorMessage;
							} else if (errorMessage && errorMessage.charAt(messageLength - 1) === '.')
								newMandatoryFieldError.userEmail.message = errorMessage.substring(0, messageLength - 1);
							else newMandatoryFieldError.userEmail.message = errorMessage;

							if (userEmailRef.current !== null) userEmailRef.current.focus();
						}
						setMandatoryFieldError(newMandatoryFieldError);
					} else if (successResponse?.contactPersons && !successResponse?.isUpdated) {
						setContactPersonModalData(successResponse.contactPersons);
						toggleContactPersonModal(true);
					} else {
						requestAllUsers(
							true,
							page.pageIndex,
							page.pageSize,
							searchText,
							newUserIds.join(),
							selectedOfficeLocations.map((x) => x.value).join(),
						);
						handleClose();
					}
				});
			}
		}
	};

	const setUserModel = (): void => {
		if (saveForUserId !== 0) {
			setInProgress(true);
			props.requestUserById(saveForUserId, (user: ISaveUserModel) => {
				setUser({ ...user, confirmEmail: user.emailAddress, reviewStatus: userReviewStatus });
				setInitialUserOfficeLocations(user.officeLocations);
				setUserFirstName(user.firstName);
				setUserLastName(user.lastName);
				setUserEmail(user.emailAddress);
				setUserInactive(user.inactive);
				setSavedUserInactiveStatus(user.inactive);
				setUserConfirmEmail(user.emailAddress);
				setUserPhone(user.phone ? user.phone : '');
				setUserExtension(user.extension ? user.extension : '');
				setUserEnableMFA(user.isMFAEnabled);
				setUserFax(user.fax ? user.fax : '');
				setUserCountryCode(user.countryCode ? user.countryCode : '+1');
				setUserMobile(user.mobileNumber ? user.mobileNumber : '');
				setUserPTIN(user.ptin ? user.ptin : '');
				setUserRevokeStatus(user.revokeStatus);
				setSelectedUserGroupList(user.userGroups.split(','));
				setUserOfficeLocation(changeTypeOfUserOfficeLocations(user.officeLocations, officeLocationList));
				setInProgress(false);
				setMobileNumberFullValue(`${user.countryCode}${user.mobileNumber}`);
			});
		}
		setMandatoryFieldError({
			userFirstName: { error: false, message: '' },
			userLastName: { error: false, message: '' },
			userEmail: { error: false, message: '' },
			userInactive: { error: false, message: '' },
			userConfirmEmail: { error: false, message: '' },
			userPhone: { error: false, message: '' },
			userFax: { error: false, message: '' },
			userMobile: { error: false, message: '' },
			userPTIN: { error: false, message: '' },
			userGroup: { error: false, message: '' },
			userOfficeLocation: { error: false, message: '' },
		});

		setUser({ ...user, isMFAEnabled: userEnableMFA });
		if (userOfficeLocation.length === 1) setUser({ ...user, officeLocations: userOfficeLocation[0].value.toString() });
	};

	useEffect(() => {
		setUserModel();
	}, []);

	useEffect(() => {
		if (ugCheckArea.current) {
			const current = ugCheckArea.current as HTMLElement;
			Array.from(current.children).map((element) => {
				const elementId = element.id.slice(0, element.id.indexOf('_li'));
				selectedUserGroupList.map((userGroupId) => {
					const input = element.children[`${elementId}_input` as any] as HTMLInputElement;
					if (userGroupId === elementId && !input.checked) input.checked = true;
				});
			});
		}
	}, [filteredUserGroupList]);

	return (
		<Modal
			show={true}
			onHide={handleClose}
			className='modalClass saveUserModal'>
			<Modal.Header className='saveUserModalHeader'>
				<div>
					<Modal.Title>{saveForUserId === 0 ? 'Add New User' : 'Edit User'}</Modal.Title>
				</div>
				<div
					data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
					className='closeIcon'
					onClick={handleClose}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body className='show-grid'>
				<Container>
					<Form>
						<div className='flexCenter'>
							<h1 className='personalInformationText'>Personal Information</h1>
							<span
								style={{ paddingTop: 0 }}
								className='saveUserFieldErrorMessage requiredTag'>
								* <span className='requiredTag__text'>Required</span>
							</span>
						</div>
						<Row>
							<Col
								xs={9}
								md={6}>
								<Form.Group
									className='mb-3'
									controlId='userFirstName'>
									<Form.Label>
										First Name <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='text'
										value={userFirstName}
										onChange={handleChangeUserFirstName}
										onBlur={(e) => {
											handleOnBlurFirstName(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userFirstName.error ? 'saveUserFieldErrorBorder' : ''}`}
										maxLength={100}
										disabled={isO365User}
									/>
									<div
										id='userFirstNameError'
										className={`fieldErrorSVG ${
											mandatoryFieldError.userFirstName.error ? 'saveUserFieldErrorMessage' : ''
										}`}>
										{mandatoryFieldError.userFirstName.message}
									</div>
								</Form.Group>
							</Col>
							<Col
								xs={9}
								md={6}>
								<Form.Group
									className='mb-3'
									controlId='userLastName'>
									<Form.Label>
										Last Name <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='text'
										value={userLastName}
										onChange={handleChangeUserLastName}
										onBlur={(e) => {
											handleOnBlurLastName(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userLastName.error ? 'saveUserFieldErrorBorder' : ''}`}
										maxLength={100}
										disabled={isO365User}
									/>
									<div
										id='userLastNameError'
										className={`${mandatoryFieldError.userLastName.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userLastName.message}
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col
								xs={12}
								md={12}
								className='alignUserInactive'>
								<input
									type='checkbox'
									name='userInactive'
									id='userInactive'
									className='userInactive'
									checked={userInactive}
									onChange={handleChangeUserInactive}
								/>
								<label htmlFor='userInactive'>
									ERO only (inactive user){' '}
									<span title={UserManagementConstants.InactiveUserSelectionnMessage}>
										<InfoIcon />
									</span>
								</label>
								<span
									id='userInactiveError'
									className={`${
										mandatoryFieldError.userInactive.error ? 'saveUserFieldErrorMessage userInactiveError' : ''
									}`}>
									{mandatoryFieldError.userInactive.message}
								</span>
							</Col>
						</Row>
						<Row>
							<Col
								xs={18}
								md={12}>
								<Form.Group
									className='mb-3'
									controlId='userEmail'>
									<Form.Label>
										Email Address <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='email'
										value={userEmail}
										onChange={handleChangeUserEmail}
										onBlur={(e) => {
											handleOnBlurEmail(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userEmail.error ? 'saveUserFieldErrorBorder' : ''}`}
										maxLength={100}
										ref={userEmailRef}
										disabled={isO365User}
									/>
									<div
										id='userEmailError'
										className={`${mandatoryFieldError.userEmail.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userEmail.message}
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col
								xs={18}
								md={12}>
								<Form.Group
									className='mb-3'
									controlId='userConfirmEmail'>
									<Form.Label>
										Confirm Email <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='email'
										value={userConfirmEmail}
										onChange={handleChangeUserConfirmEmail}
										onBlur={(e) => {
											handleOnBlurConfirmEmail(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userConfirmEmail.error ? 'saveUserFieldErrorBorder' : ''}`}
										maxLength={100}
										disabled={isO365User}
									/>
									<span className={`noEmailMatch${noEmailMatch ? ' showError' : ''}`}>
										<ExclamationError />
									</span>
									<div
										id='userConfirmEmailError'
										className={`${mandatoryFieldError.userConfirmEmail.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userConfirmEmail.message}
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col
								xs={9}
								md={6}>
								<Form.Group
									className='mb-3'
									controlId='userPhone'>
									<Form.Label className='isOptional'>
										<div>Phone</div>
										<div className='optional_text'>{userExtension && userExtension.length > 0 ? '' : 'Optional'}</div>
									</Form.Label>
									<Form.Control
										type='text'
										value={userPhone}
										onChange={handleChangeUserPhone}
										onBlur={(e) => {
											handleOnBlurPhone(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userPhone.error ? 'saveUserFieldErrorBorder' : ''}`}
									/>
									<div
										id='userPhoneError'
										className={`${mandatoryFieldError.userPhone.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userPhone.message}
									</div>
								</Form.Group>
							</Col>
							<Col
								xs={3}
								md={2}>
								<Form.Group
									className='mb-3'
									controlId='userExtension'>
									<Form.Label>Ext.</Form.Label>
									<Form.Control
										type='text'
										value={userExtension}
										onChange={handleChangeUserExtension}
										onBlur={(e) => {
											handleOnBlurExtension(e.target.id, e.target.value);
										}}
										maxLength={6}
									/>
								</Form.Group>
							</Col>
							<Col
								xs={6}
								md={4}
								className='alignMFACol'>
								<input
									type='checkbox'
									name='userEnableMFA'
									id='userEnableMFA'
									disabled={!companyEnableMFA || userInactive}
									className='userEnableMFA'
									checked={userEnableMFA}
									onChange={handleChangeUserEnableMFA}
								/>
								<label htmlFor='userEnableMFA'>
									Enable MFA{' '}
									<span
										title={
											companyEnableMFA
												? 'To use multifactor authentication, you need a mobile number.'
												: "Multi-factor authentication isn't enabled for your account.\nFor more information, contact your system administrator."
										}>
										<InfoIcon />
									</span>
								</label>
							</Col>
						</Row>
						<Row>
							<Col
								xs={9}
								md={6}>
								<Form.Group
									className='mb-3'
									controlId='userFax'>
									<Form.Label className='isOptional'>
										<div>Fax</div>
										<div className='optional_text'>Optional</div>
									</Form.Label>
									<Form.Control
										type='text'
										value={userFax}
										onChange={handleChangeUserFax}
										onBlur={(e) => {
											handleOnBlurFax(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userFax.error ? 'saveUserFieldErrorBorder' : ''}`}
									/>
									<div
										id='userFaxError'
										className={`${mandatoryFieldError.userFax.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userFax.message}
									</div>
								</Form.Group>
							</Col>
							<Col
								xs={9}
								md={6}>
								<div className='mobileSection'>
									<label
										htmlFor='userMobile'
										className='isOptional'>
										<span>
											Mobile <span className='asterisk'>{companyEnableMFA && !userInactive && userEnableMFA ? '*' : ''}</span>
										</span>
										<span className='optional_text'>{companyEnableMFA && !userInactive && userEnableMFA ? '' : 'Optional'}</span>
									</label>
									<PhoneInput
										containerClass={`${
											mandatoryFieldError.userMobile.error ? 'saveUserFieldErrorBorder ' : ''
										}inputCCMobile`}
										country={'us'}
										autoFormat={true}
										value={mobileNumberFullValue}
										onChange={onMobileChange}
										onBlur={() => {
											handleOnBlurMobile('userMobile');
										}}
									/>
									<div
										id='userMobileError'
										className={`${mandatoryFieldError.userMobile.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userMobile.message}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col
								xs={9}
								md={6}>
								<Form.Group
									className='mb-3'
									controlId='userPTIN'>
									<Form.Label className='ptinInfo isOptional'>
										<div>
											PTIN{userInactive ? <span className='asterisk'> * </span> : ' '}
											<span title='PTIN Format: P12345678'>
												<InfoIcon />
											</span>
										</div>
										<div className='optional_text'>Optional</div>
									</Form.Label>
									<Form.Control
										type='text'
										value={userPTIN}
										onChange={handleChangeUserPTIN}
										onBlur={(e) => {
											handleOnBlurPTIN(e.target.id, e.target.value);
										}}
										className={`${mandatoryFieldError.userPTIN.error ? 'saveUserFieldErrorBorder' : ''}`}
									/>
									<div
										id='userPTINError'
										className={`${mandatoryFieldError.userPTIN.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userPTIN.message}
									</div>
								</Form.Group>
							</Col>
							<Col
								xs={9}
								md={6}>
								<Form.Group className='mb-3'>
									<Form.Label>
										Office Location <span className='asterisk'>*</span>
									</Form.Label>
									<CustomMultiDropdown
										id='userOfficeLocation'
										selectedOptions={userOfficeLocation}
										setSelectedOption={handleOfficeLocationChanges}
										options={officeLocationList.map((x) => ({
											label: x.name,
											value: x.locationId,
											id: x.locationNumber,
										}))}
										height={31}
										borderColor={mandatoryFieldError.userOfficeLocation.error ? '#CC4A43' : '#898D91'}
										customPlaceHolder='&nbsp;'
										title={
											companyData?.companyProfile.companyInfo.adminUser === user.userId
												? 'Office Location cannot be edited as this user is the Primary Admin for the account'
												: ''
										}
										disabled={
											officeLocationList?.length === 1 ||
											companyData?.companyProfile.companyInfo.adminUser === user.userId
												? true
												: false
										}
										className={`${
											officeLocationList?.length === 1 ||
											companyData?.companyProfile.companyInfo.adminUser === user.userId
												? ' disabled-dropdown'
												: ''
										}`}
										handleOnBlur={handleOnBlur}
									/>
									<div
										id='userOfficeLocationError'
										className={`${mandatoryFieldError.userOfficeLocation.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userOfficeLocation.message}
									</div>
								</Form.Group>
							</Col>
						</Row>
						{userRevokeStatus === SSORevokeStatus.PermanentlyRevoked && (
							<Row>
								<Col
									xs={9}
									md={6}
									className='enableSSODiv'>
									<input
										type='checkbox'
										name='enableSSO'
										id='enableSSO'
										className='enableSSO'
										onChange={handleEnableSSOChange}
									/>
									<label
										htmlFor='enableSSO'
										className='enableSSOLabel'>
										Enable Single Sign On Access
									</label>
								</Col>
							</Row>
						)}
						<Row>
							<Col
								xs={18}
								md={12}>
								<Form.Group
									className='mb-3'
									controlId='userGroups'>
									<Form.Label>
										User Group <span className='asterisk'>*</span>
									</Form.Label>
									<div className={`ugTags${mandatoryFieldError.userGroup.error ? ' saveUserFieldErrorBorder' : ''}`}>
										{selectedUserGroupList.map((userGroupId: string) => {
											for (const eachGroup of availableUserGroupList)
												if (eachGroup.groupId.toString() === userGroupId)
													return (
														<span
															key={`${userGroupId}`}
															className={userInactive ? 'eachUGTag disabled' : 'eachUGTag'}
															style={{
																backgroundColor: eachGroup.BackgroundColor,
																border: `1px solid ${eachGroup.borderColor}`,
																color: eachGroup.fontColor,
															}}>
															<span className='tagName'>{eachGroup.groupName}</span>
															<span
																data-test-auto={`e50d8f35-d739-4a4f-9b8d-84efda05efc3${userGroupId}`}
																id={`${userGroupId}`}
																className='tagDeselect'
																onClick={(e) => {
																	deselectGroup(e, userGroupId as any);
																}}>
																{!userInactive ? <CloseIcon /> : ''}
															</span>
														</span>
													);
										})}
									</div>
									<div
										id='userGroupError'
										className={`${mandatoryFieldError.userGroup.error ? 'saveUserFieldErrorMessage' : ''}`}>
										{mandatoryFieldError.userGroup.message}
									</div>
								</Form.Group>
							</Col>
						</Row>

						{userInactive ? (
							''
						) : (
							<>
								<div className='searchUserGroupDiv'>
									<Form.Control
										type='text'
										className='searchUserGroup'
										value={searchUserGroupText}
										onChange={handleUserGroupSearch}
									/>
									<span>
										<SearchIcon />
									</span>
								</div>
								<ul
									className='ugCheckArea'
									ref={ugCheckArea}>
									{filteredUserGroupList.length === 0 ? (
										<li className='eachUGCheck'>No results found</li>
									) : (
										filteredUserGroupList
											.sort((a, b) => a.groupName.toLowerCase().localeCompare(b.groupName))
											.map((userGroup) => {
												return (
													<li
														className='eachUGCheck'
														data-name={`${userGroup.groupId}_li`}
														id={`${userGroup.groupId}_li`}
														key={`${userGroup.groupId}`}>
														<input
															type='checkbox'
															className='ugCheckbox'
															id={`${userGroup.groupId}_id`}
															name={`${userGroup.groupId}_input`}
															onChange={(e) => {
																handleUserGroupCheck(e, userGroup as any);
															}}
															value={userGroup.groupName}
															checked={selectedUserGroupList.some((selectedUserGroupId) => {
																return selectedUserGroupId === userGroup.groupId.toString();
															})}
														/>
														<label
															htmlFor={`${userGroup.groupId}_id`}
															data-name={`${userGroup.groupId}_label`}
															id={`${userGroup.groupId}_label`}>
															{userGroup.groupName}
														</label>
													</li>
												);
											})
									)}
								</ul>
							</>
						)}
					</Form>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Loader loading={inProgress} />
				<Button
					data-test-auto='bf271b75-04e5-4238-bebc-b1f3667be192'
					variant='secondary'
					onClick={handleClose}>
					Cancel
				</Button>
				<Button
					disabled={inProgress}
					data-test-auto='6e15750b-3c9d-49a9-b272-49e048689c94'
					variant='primary'
					onClick={handleSaveUser}>
					{' '}
					{saveForUserId === 0 ? 'Add User' : userReviewStatus === UserReviewStatus.YetToReview ? 'Reviewed' : 'Update'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SaveUserModal;
