import React, { useEffect, useState } from 'react';
import UsageReportHeader from './usageReportHeader';
import UsageReportTable from './usageReportTable';
import {
	DateRangeOptions,
	IPageProps,
	ISortColumn,
	IDropdownStates,
	IUsageReport,
	buildQueryForRunReport,
	buildQueryForExportToExcel,
	dateRangeOptions,
	ssrReportColumns,
	checkDropdownState,
} from './UsageReport.model';
import UsageReportPagination from './usageReportPagination';
import { exportUsageReportToExcel } from './UsageReport.api';
import { getKeyByValue } from '../../helper/HelperFunctions';
import { IOfficeLocation } from '../clientManagement/Client.model';
import { fetchOfficeLocation } from '../clientManagement/Client.api';
import { ICustomDropdownOption } from '../../components/common/customDropdown';

interface IReportsProps {
	usageReport: IUsageReport;
	requestClientLedgerData(query: string): void;
	requestResourceIds(): void;
}

const oneDay = 24 * 60 * 60 * 1000;

const Reports: React.FC<IReportsProps> = (props) => {
	const { usageReport, requestClientLedgerData, requestResourceIds } = props;

	const [page, setPage] = useState<IPageProps>({ pageIndex: 1, pageSize: 10 });
	const [initialLoad, setInitialLoad] = useState<boolean>(true);
	const [sortColumn, setSortColumn] = useState<ISortColumn>({
		sortBy: getKeyByValue(ssrReportColumns, ssrReportColumns.usageDate),
		sortOrder: 'desc',
	});
	const [dropdownStates, setDropdownStates] = useState<IDropdownStates>({
		productType: null,
		returnType: null,
		dateType: dateRangeOptions[3],
		startDate: null,
		endDate: null,
		officeLocaltion: [],
	});
	const [isMonthlyReport, setIsMonthlyReport] = useState<boolean>(false);
	const [officeLocaltionList, setOfficeLocaltionList] = useState<ICustomDropdownOption[]>([]);

	useEffect(() => {
		requestResourceIds();
		fetchOfficeLocation((officeLocation: IOfficeLocation[]) => {
			const list = officeLocation.map((location) => ({
				label: location.name,
				value: location.locationId,
				id: location.locationNumber,
			}));
			setOfficeLocaltionList(list);
			setDropdownStates({
				...dropdownStates,
				officeLocaltion:
					officeLocation.length == 1
						? [
								{
									label: officeLocation[0].name,
									value: officeLocation[0].locationId,
									id: officeLocation[0].locationNumber,
								},
						  ]
						: [],
			});
		});
	}, []);

	useEffect(() => {
		dropdownStates.dateType?.value && calculateDateRanges(dropdownStates.dateType?.value);
	}, [dropdownStates.dateType]);

	useEffect(() => {
		const startDate = dropdownStates.startDate;
		const endDate = dropdownStates.endDate;
		if (startDate && endDate) {
			const diffDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay));
			if (
				dropdownStates.dateType?.value === DateRangeOptions.LastYear ||
				dropdownStates.dateType?.value === DateRangeOptions.YearToDate ||
				diffDays > 180
			) {
				setIsMonthlyReport(true);
			} else {
				setIsMonthlyReport(false);
			}
		}
	}, [dropdownStates.startDate, dropdownStates.endDate]);

	useEffect(() => {
		if (!initialLoad && checkDropdownState(dropdownStates, !initialLoad)) {
			requestClientLedgerData(buildQueryForRunReport(dropdownStates, page, sortColumn, isMonthlyReport));
		}
	}, [sortColumn]);

	const calculateDateRanges = (range: number) => {
		const endDate = new Date();
		if (range === DateRangeOptions.Last7Days) {
			setDropdownStates({
				...dropdownStates,
				startDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 7),
				endDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1),
			});
		} else if (range === DateRangeOptions.LastMonth) {
			setDropdownStates({
				...dropdownStates,
				startDate: new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1),
				endDate: new Date(endDate.getFullYear(), endDate.getMonth(), 0),
			});
		} else if (range === DateRangeOptions.LastYear) {
			setDropdownStates({
				...dropdownStates,
				startDate: new Date(endDate.getFullYear() - 1, 0, 1),
				endDate: new Date(endDate.getFullYear() - 1, 11, 31),
			});
		} else if (range === DateRangeOptions.MonthToDate) {
			setDropdownStates({
				...dropdownStates,
				startDate: new Date(endDate.getFullYear(), endDate.getMonth(), 1),
				endDate,
			});
		} else if (range === DateRangeOptions.YearToDate) {
			setDropdownStates({ ...dropdownStates, startDate: new Date(endDate.getFullYear(), 0, 1), endDate });
		} else if (range === DateRangeOptions.Custom) {
			setDropdownStates({
				...dropdownStates,
				startDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 31),
				endDate,
			});
		}
	};

	const goToPage = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(usageReport.count / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);

			requestClientLedgerData(buildQueryForRunReport(dropdownStates, newPage, sortColumn, isMonthlyReport));
		}
	};

	const onRunReport = () => {
		if (checkDropdownState(dropdownStates, true)) {
			setInitialLoad(false);
			const newPageIndex = 1;
			resetPagination(newPageIndex);
			requestClientLedgerData(
				buildQueryForRunReport(dropdownStates, { ...page, pageIndex: newPageIndex }, sortColumn, isMonthlyReport),
			);
		}
	};

	const exportToExcel = () => {
		if (checkDropdownState(dropdownStates, true) && dropdownStates.productType) {
			exportUsageReportToExcel(
				buildQueryForExportToExcel(dropdownStates, sortColumn, isMonthlyReport),
				dropdownStates.productType.label,
			);
		}
	};

	const resetPagination = (pageIndex: number, pageSize?: number) => {
		setPage({ pageIndex: pageIndex, pageSize: pageSize ?? page.pageSize });
	};

	return (
		<>
			<article>
				<UsageReportHeader
					initialLoad={initialLoad}
					usageReport={usageReport}
					dropdownStates={dropdownStates}
					setDropdownStates={setDropdownStates}
					onRunReport={onRunReport}
					exportToExcel={exportToExcel}
					officeLocaltionList={officeLocaltionList}
				/>
				<UsageReportTable
					usageReport={usageReport}
					initialLoad={initialLoad}
					onRunReport={onRunReport}
					sortColumn={sortColumn}
					setSortColumn={setSortColumn}
				/>
				{!initialLoad && (
					<UsageReportPagination
						count={usageReport.count}
						page={page}
						goToPage={goToPage}
					/>
				)}
			</article>
		</>
	);
};

export default Reports;
