import { Reducer } from "redux";
import { actionTypes } from "./GFR.action";
import { Credentials, GFRCredentialsSaveResponse, GFRDrawers, GFRIndex, GFRIndexMapCustomization, GFRIndexValue, GFRSettings, GFRSettingsState, KnownAction } from "./GFR.model";
import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from 'src/helper/Constants';
import { AppThunkAction } from 'src/store/index';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';

export const actionCreators = {
    getGFRSettings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + '/api/gfr/settings')
            .then((response: AxiosResponse<GFRSettings>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_GFR_SETTINGS, data: data });
            })
            .catch((error) => {
                logger && logger.trackWarning('Error while Get GFR Settings', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    saveGfrCredentials: (credentials: Credentials, callback?: (isSuccess: boolean, error?: string) => void): AppThunkAction<KnownAction> => (dispatch, getstate) => {
        axios.post(API_BASE_URL + '/api/gfr/save-credentials', credentials)
            .then((response: AxiosResponse<GFRCredentialsSaveResponse>) => {
                const data = response.data;


                if (callback) {
                    callback(data.success);
                }

                if (data.success) {
                    dispatch({ type: actionTypes.SAVE_GFR_CREDENTIALS, data: data });
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: "Successfully connected with GoFileRoom.", statusType: StatusType.Success });
                }
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while Save GFR Credentials', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText;
                if (callback) {
                    callback(false, statusMessage);
                }
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            });
    },

    disconnectGFR: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getstate) => {

        axios.delete(API_BASE_URL + '/api/gfr/disconnect')
            .then((response: AxiosResponse<boolean>) => {
                dispatch({ type: actionTypes.DISCONNECT_GFR });
                if (callback) {
                    callback();
                }
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: "No longer connected with GoFileRoom.", statusType: StatusType.Success });
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while disconnect GFR', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
                }
            });
    },
    getGFRDrawers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: loaderTypes.LOADING, show: true, text: 'Loading GofileRoom Drawers...' });

        axios.get(API_BASE_URL + '/api/gfr/drawers')
            .then((response: AxiosResponse<GFRDrawers[]>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_GFR_DRAWERS, data: data });
                dispatch({ type: loaderTypes.LOADING, show: false, text: '' });
            })
            .catch((error) => {
                logger && logger.trackWarning('Error while Get GFR Drawers', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                dispatch({ type: loaderTypes.LOADING, show: false, text: '' });

                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },
    getGFRDrawerIndexes: (drawerId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        dispatch({ type: loaderTypes.LOADING, show: true, text: 'Loading GofileRoom Indexes...' });

        axios.get(API_BASE_URL + `/api/gfr/drawer/${drawerId}/indexes`)
        .then((response: AxiosResponse<GFRIndex[]>) => {
            const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_GFR_DRAWER_INDEXES, data: data });
                dispatch({ type: loaderTypes.LOADING, show: false, text: '' });

            })
            .catch((error) => {
                logger && logger.trackWarning('Error while Get GFR Drawer Indexes', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                dispatch({ type: loaderTypes.LOADING, show: false, text: '' });

                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    getGFRIndexeValues: (indexId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + `/api/gfr/drawer/${indexId}/indexes`)
            .then((response: AxiosResponse<GFRIndexValue[]>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_GFR_INDEX_VALUES, data: data });
            })
            .catch((error) => {
                logger && logger.trackWarning('Error while Get GFR Index Values', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },

    saveIndexMapping: (customization: GFRIndexMapCustomization, callback?: (isSuccess: boolean, error?: string) => void): AppThunkAction<KnownAction> => (dispatch, getstate) => {
        dispatch({ type: loaderTypes.LOADING, show: true, text: 'Saving GofileRoom Index Mapping...' });

        axios.post(API_BASE_URL + '/api/gfr/save-index-mapping', customization)
            .then((response: AxiosResponse<any>) => {
                const data = response.data;

                if (callback) {
                    callback(true);
                }
                dispatch({ type: actionTypes.SAVE_GFR_INDEX_MAPPING });
                dispatch({ type: loaderTypes.LOADING, show: false, text: '' });
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: "GoFileRoom Mapping successful.", statusType: StatusType.Success });
            })
            .catch(function (error) {
                logger && logger.trackWarning('Error while Save GofileRoom Index Mapping', { 'Error': error?.response?.data });
                const statusMessage: any = error.statusText;
                if (callback) {
                    callback(false, statusMessage);
                }
                dispatch({ type: loaderTypes.LOADING, show: false, text: '' });
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            });
    },

    showValidation: (msg: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: msg, statusType: StatusType.Warning });
    }
}

const defaultState: GFRSettingsState = {
    gfrSettings: {
        isConnected: false,
        username: '',
        isGFREnabled:false,
        gfrIndexMapCustomization: undefined
    },
    gfrDrawers: [],
    gfrIndexes: []
};

export const reducer: Reducer<GFRSettingsState> = (state = defaultState, incomingAction) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case actionTypes.RESPONSE_GFR_SETTINGS:
            var gfrSettings = action?.data ?? defaultState;
            return {
                gfrSettings: gfrSettings,
            };
        case actionTypes.SAVE_GFR_CREDENTIALS:
            return {
                ...state,
                gfrSettings: {
                    ...state.gfrSettings,
                    isConnected: true,
                    username: action.data?.username
                }
            };

        case actionTypes.DISCONNECT_GFR:
            return {
                gfrSettings: {
                    isConnected: false,
                    username: '',
                    isGFREnabled: state.gfrSettings.isGFREnabled
                }
            };

        case actionTypes.RESPONSE_GFR_DRAWERS:
            return {
                ...state,
                gfrDrawers: action.data
            };
        case actionTypes.RESPONSE_GFR_DRAWER_INDEXES:
            return {
                ...state,
                gfrIndexes: action.data
            };
        case actionTypes.SAVE_GFR_INDEX_MAPPING:
            return {
                ...state
            };

        default:
            return state;
    }
};