import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuiteModal from 'src/components/common/SuiteModal';
import { SMSNotificationsConstant } from 'src/helper/Constants';
import { smsNotificationConstants } from 'src/helper/rbac-constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';

import { ISMSNotificationRequest, ISMSNotificationResponse } from './SMSNotifications.model';
import styles from './SMSNotifications.module.scss';
import { getSMSNotification, updateSMSNotification } from './SMSNotifications.store';
import { logger } from 'src/oidcClient/authProvider';
import InfoIcon from 'src/components/svg/InfoIcon';

const SMSNotifications: FC = () => {
	const [isSMSNotificationEnabled, setIsSMSNotificationEnabled] = useState(false);
	const [showConsentInfoModal, setShowConsentInfoModal] = useState(false);
	const dispatch = useDispatch();

	const companyData = useSelector((store) => {
		return store?.companyData;
	});

	useEffect(() => {
		fetchSMSNotification();
	}, []);

	const fetchSMSNotification = (): void => {
		getSMSNotification((responseObject: ISMSNotificationResponse) => {
			if (responseObject.error)
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: SMSNotificationsConstant.FetchSMSNotificationError,
				});
			else setIsSMSNotificationEnabled(responseObject.isSMSNotificationEnabled);
		});
	};

	const handleToggleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		logger && logger.trackTrace('handleToggleSwitchChange: Changing SMS Notifications settings in general settings');
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Updating SMS Text Notification Settings...',
		});
		const switched = event.target.checked;
		const requestObject: ISMSNotificationRequest = {
			isSMSNotificationEnabled: switched,
		};
		updateSMSNotification(smsNotificationConstants.smsNotificationControl, requestObject, (response: boolean) => {
			dispatch({
				type: loaderTypes.LOADING,
				show: false,
				text: '',
			});
			if (response) {
				setIsSMSNotificationEnabled(switched);
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Success,
					statusMessage: SMSNotificationsConstant.UpdateSMSNotificationSuccess,
				});
				if (switched) setShowConsentInfoModal(true);
			} else
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: SMSNotificationsConstant.UpdateSMSNotificationError,
				});
		});
	};

	const onHide = (): void => {
		setShowConsentInfoModal(false);
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>SMS Text Notifications</h3>
			<p className='suitePageSectionDescription'>
				To receive text messages, a client must provide explicit consent.
				<br />
				Your client can opt-out anytime by replying &quot;STOP&quot; via text.
			</p>
			<div className='switchSettingsBlock form-switch'>
				<input
					className='switchSettingsButton form-check-input'
					type='checkbox'
					id='isSMSNotificationEnabled'
					data-test-auto='7e455f4d-05df-402c-b18f-3dd43eb9585e'
					checked={isSMSNotificationEnabled}
					onChange={handleToggleSwitchChange}
					data-resource-id={smsNotificationConstants.smsNotificationControl}
				/>
				<span className='switchSettingsText'>SMS Text Notification</span>
				{isSMSNotificationEnabled && (
					<span
						className='switchSettingsIcon'
						onClick={() => {
							setShowConsentInfoModal(true);
						}}
						data-test-auto='1109aebc-9c96-4dfb-a1ec-79531c0ea0d0'
						style={{ marginLeft: '-6px' }}>
						<InfoIcon size={14} />
					</span>
				)}
			</div>
			{showConsentInfoModal && (
				<SuiteModal
					width='500'
					theme='light'
					title='Consent Preview Language'
					hide={onHide}>
					<section className={styles.bodySection}>
						<div className={styles.bodyHeading}>Would you like to receive SMS Text Notification?</div>
						<div className={styles.bodyText}>
							By responding &quot;Yes&quot;, I authorize{' '}
							<span>{companyData?.companyProfile?.companyInfo?.companyName}</span> and its service providers (including
							SafeSend) to send me notifications and other messages at the number provided on this form via text (SMS)
							using automated dialing technology. Consent is not a condition for purchase. Message and data rates may
							apply. To opt-out at any time, reply &quot;STOP&quot;.
						</div>
					</section>
				</SuiteModal>
			)}
		</article>
	);
};

export default SMSNotifications;
