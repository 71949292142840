import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';
import {
	ChangePasswordStatus,
	IContactPersonViewModel,
	IUserDeleteResponse,
	IUserGroups,
	IUserLocation,
} from './Users.model';
import { userTabConstants } from '../../../helper/rbac-constants';
import { ICompanyMfaSettings } from '../../securitySettings/MFASettings/MFASettings.model';
import { download } from '../../../helper/FileUtilities';
import { logger } from 'src/oidcClient/authProvider';

export function getAllUserGroups(callback: (userGroups: IUserGroups[]) => void) {
    const getAllUserGroupsData = {
        isGetAllUserGroups: false,
		error: '',
    };
	axios
		.get(API_BASE_URL + '/api/user-group/all-groups')
        .then((response: AxiosResponse<IUserGroups[]>) => {
            const { data } = response;
            callback(data);
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while getAllUserGroups', { 'Error': error?.response?.data });
			getAllUserGroupsData.error = 'Error while checking getAllUserGroups.';
        });
}

export function getCompanyMFAEnabled(callback: (companyEnableMFA: boolean) => void) {
	axios
		.get(API_BASE_URL + '/api/mfa/mfa-setting')
        .then((response: AxiosResponse<ICompanyMfaSettings>) => {
            const { data } = response;
            callback(data.isMfaEnabled);
		})
		.catch((error) => {
            logger && logger.trackWarning('Error while getCompanyMFAEnabled', { 'Error': error?.response?.data });
        });
}

export function getIsUseSignatureStampEnabled(callback: (isUseSignatureStampEnabled: boolean) => void) {
	axios
		.get(API_BASE_URL + '/api/company-settings/is-use-signature-stamp-enabled')
        .then((response: AxiosResponse) => {
            const { data } = response;
            callback(data);
		})
		.catch(() => {
            logger && logger.trackWarning('Error while getIsUseSignatureStampEnabled', { 'Error': error?.response?.data });
        });
}

export function resetUserPassword(userId: number, callback: (status: number) => void) {
	axios
		.post(
			API_BASE_URL + '/api/user-management/change-password/' + userId,
			{},
			{
        headers: {
					'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.resetPassword}`,
				},
			},
		)
        .then((response: AxiosResponse) => {
            const { data } = response;
            if (data.isSuccess) {
                callback(data.status);
            } else {
                callback(ChangePasswordStatus.None);
            }
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while resetUserPassword', { 'Error': error?.response?.data });
            callback(ChangePasswordStatus.None);
        });
}

export function revokeO365UserAccess(
	userEmailRevoke: string,
	revokeStatusSelected: number,
	callback: (status: boolean) => void,
) {
	axios
		.post(
			API_BASE_URL + '/api/user-management/revoke-access/' + userEmailRevoke + '/' + revokeStatusSelected,
			{},
			{
        headers: {
					'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.revokeOffice365}`,
				},
			},
		)
        .then((response: AxiosResponse) => {
            callback(response.data);
		})
		.catch(() => {
			logger && logger.trackWarning('Error while revokeO365UserAccess', { 'Error': error?.response?.data });
            callback(false);
        });
}

export function deleteUser(
	userId: number,
	checkOnlyUserIsDeletable: boolean,
	successCallback: (data: IUserDeleteResponse) => void,
	errorCallback: () => void,
) {
	axios
		.delete(API_BASE_URL + '/api/user-management/' + userId + '/' + checkOnlyUserIsDeletable, {
        headers: {
            'X-Resource-Id': `${userTabConstants.prefix}_${userTabConstants.grid}_${userTabConstants.deleteButton}`,
            'Content-Type': 'application/json',
			},
    })
        .then((response: AxiosResponse) => {
            const { data } = response;
            successCallback(data);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while deleteUser', { 'Error': error?.response?.data });
            errorCallback();
        });
}

export function updateDefaultContactPerson(
	userId: number,
	updatingToUserId: number,
	callback: (status: boolean) => void,
) {
	axios
		.put(API_BASE_URL + '/api/user-management/default-contact-person/' + userId + '/' + updatingToUserId)
        .then((response: AxiosResponse) => {
            const { data } = response;
            callback(data);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while updateDefaultContactPerson', { 'Error': error?.response?.data });
            callback(false);
        });
}

export function getAllUsersWithLocation(callback: (availableUsers: IUserLocation[]) => void) {
	axios
		.get(API_BASE_URL + '/api/dropdown/all-users-with-location/')
        .then((response: AxiosResponse<IUserLocation[]>) => {
            callback(response.data);
		})
		.catch((error) => {
			logger && logger.trackWarning('Error while getAllUsersWithLocation', { 'Error': error?.response?.data });
		});
}

export function downloadImportUserTemplate(errorCallback: () => void) {
	axios
		.get(API_BASE_URL + '/api/user-management/download-import-user-template', {
			headers: { 'X-Resource-Id': `${userTabConstants.suitePrefix}_${userTabConstants.downloadImportUserTemplate}` },
		})
		.then((response) => {
			if (response.status === 200) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				download(url, 'user_import_template.csv');
			}
		})
		.catch((error) => {
			errorCallback();
		});
}

export const getUsersImportLink = async (
	callback: (source: { sas: string; fileName: string }) => void,
	errorCallback: () => void,
) => {
	try {
		const response: AxiosResponse = await axios.get(API_BASE_URL + '/api/user-management/um-import-uploadlink', {
			headers: { 'X-Resource-Id': `${userTabConstants.suitePrefix}_${userTabConstants.import}` },
        });
		if (response.status === 200) {
			callback(response.data);
		}
	} catch (error) {
		console.log('Error while generation user import link', error);
		errorCallback();
	}
};

export const saveUserImport = async (fileName: string, friendlyFileName: string, errorCallback: () => void) => {
	try {
		const response: AxiosResponse = await axios.post(
			API_BASE_URL +
				'/api/user-management/save-user-import' +
				`?fileName=${fileName}&friendlyFileName=${encodeURIComponent(friendlyFileName)}`,
			{ headers: { 'X-Resource-Id': `${userTabConstants.suitePrefix}_${userTabConstants.import}` } },
		);
	} catch (error) {
		console.log('Failed to import users from csv', error);
		errorCallback();
}
};
