import React, { Component, createRef, RefObject } from 'react';
import { Modal, Button, Form, Row, Col, Table } from 'react-bootstrap';
import { GFRDataFields, GFRIndexMap, GFRIndexMapCustomization, GFRIndexValueMap, GFRIndexValueMapCust, GFRSettingsState } from '../GFR.model';
import styles from './indexMapping.module.scss';
import * as _ from 'lodash';

interface MapToGoFileRoomProps {
  handleClose: () => void;
  getGFRDrawers: () => void;
  getGFRDrawerIndexes: (drawerID: string) => void;
  saveIndexMapping: (indexMapping: GFRIndexMapCustomization, callback?: (isSuccess: boolean, error?: string) => void) => void;
  getGFRIndexeValues: (indexId: string) => void;
  getGFRSettings: () => void;
  showValidation: (msg: string) => void;
  gfrSettingsState: GFRSettingsState;
}

interface MapToGoFileRoomState {
  fieldCustomization: GFRIndexMapCustomization;
}

const getDataFields = () => {
  return Object.keys(GFRDataFields).filter(key => isNaN(Number(key))).map((fieldEnum) => {
    var enumValue = GFRDataFields[fieldEnum as keyof typeof GFRDataFields];
    return enumValue;
  });
}

const defaultIndexMap: GFRIndexMap = {
  field: GFRDataFields.ClientId,
  gfrIndexID: '',
  gfrIndexName: '',
  gfrIndexType: '',
  isListIndex: false,
  listItemsMap: {},
}

const dataFieldsName = {
  [GFRDataFields.ClientId]: 'ClientID',
  [GFRDataFields.ClientName]: 'Client Name',
  [GFRDataFields.EngagementType]: 'Engagement Type',
  [GFRDataFields.DocumentType]: 'Document Type',
  [GFRDataFields.Taxyear]: 'Tax Year',
  [GFRDataFields.EroSigner]: 'ERO/Signer',
  [GFRDataFields.SentBy]: 'Sent By',
  [GFRDataFields.OfficeLocation]: 'Office Location',
  [GFRDataFields.SignedDate]: 'Signed Date',
}

export class MapToGoFileRoom extends Component<MapToGoFileRoomProps, MapToGoFileRoomState> {

  private htmlSelectFieldRefs: { [key: string]: RefObject<HTMLSelectElement> } = {};
  private htmlInputFieldRefs: { [key: string]: RefObject<HTMLInputElement> } = {};

  constructor(props: MapToGoFileRoomProps) {
    super(props);

    var indexMapExists = props.gfrSettingsState.gfrSettings.gfrIndexMapCustomization != undefined;

    this.state = {
      fieldCustomization: indexMapExists
        ? this.addMissingFields(_.cloneDeep(this.props.gfrSettingsState.gfrSettings.gfrIndexMapCustomization))
        : this.defaultFieldCustomization
    };
    this.htmlSelectFieldRefs = this.getHtmlFieldsRefObj();
    this.props.getGFRDrawers();
    if (indexMapExists && props.gfrSettingsState.gfrSettings.gfrIndexMapCustomization?.drawerId) {
      this.props.getGFRDrawerIndexes(props.gfrSettingsState.gfrSettings.gfrIndexMapCustomization.drawerId);
    }
  }

  addMissingFields = (fieldCustomization: GFRIndexMapCustomization) => {
    fieldCustomization.gfrIndexMapList = getDataFields().map((enumValue) => {
      var fieldObj = fieldCustomization.gfrIndexMapList.find(x => x.field == enumValue) ||
      {
        ..._.cloneDeep(defaultIndexMap),
        field: enumValue
      };
      return fieldObj;
    }).sort((a, b) => a.field - b.field);
    return fieldCustomization;
  }

  defaultFieldCustomization: GFRIndexMapCustomization = {
    drawerId: '',
    gfrIndexMapList: getDataFields().map((enumValue) => {
      return {
        ..._.cloneDeep(defaultIndexMap),
        field: enumValue,
      } as GFRIndexMap;
    })
  };

  handleDrawerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    var drawerId = event.target.value;
    this.setState((prevState) => {

      if (prevState.fieldCustomization.drawerId != drawerId && drawerId == this.props.gfrSettingsState.gfrSettings.gfrIndexMapCustomization?.drawerId) {
        return {
          ...prevState,
          fieldCustomization: {
            ...JSON.parse(JSON.stringify(this.props.gfrSettingsState.gfrSettings.gfrIndexMapCustomization)),
            drawerId: drawerId
          },
        }
      }

      if (prevState.fieldCustomization.drawerId != drawerId) {
        return {
          ...prevState,
          fieldCustomization: {
            ...this.defaultFieldCustomization,
            drawerId: drawerId
          },
        }
      }

      return {
        ...prevState,
        fieldCustomization: {
          ...prevState.fieldCustomization,
          drawerId: drawerId
        },
      }
    });
    if (drawerId != '')
      this.props.getGFRDrawerIndexes(drawerId);
    else
      this.props.gfrSettingsState.gfrIndexes = [];
  };

  handleFieldChange = (field: GFRIndexMap) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldCustomization: {
        ...prevState.fieldCustomization,
        gfrIndexMapList: prevState.fieldCustomization.gfrIndexMapList.map((item) => {
          if (item.field === field.field) {
            var indexdata = this.props.gfrSettingsState.gfrIndexes.find(x => x.indexId === event.target.value) || { indexName: '', indexType: '', length: 0, required: false, listId: 0, lookupAutoUpdate: false, childIndexId: '' };
            return {
              ...item,
              gfrIndexID: event.target.value,
              gfrIndexName: indexdata.indexName,
              gfrIndexType: indexdata.indexType,
              isListIndex: indexdata.listId > 0,
              required: indexdata.required,
              listItemsMap: {}
            };
          }
          return item;
        }),
      },
    }));
  };

  handleIndexValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: GFRIndexValueMap, fieldObj: GFRIndexMap) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldCustomization: {
        ...prevState.fieldCustomization,
        gfrIndexMapList: prevState.fieldCustomization.gfrIndexMapList.map((item) => {
          if (item.field === fieldObj.field) {
            item.listItemsMap[index.fieldKey] = e.target.value;
          }
          return item;
        }),
      },
    }));
  }

  handleSave = () => {
    if (!this.validateCustomization())
      return;

    var postData = {
      ...this.state.fieldCustomization,
      gfrIndexMapList: this.state.fieldCustomization.gfrIndexMapList.filter((item) => item.gfrIndexID !== '')
    }
    this.props.saveIndexMapping(postData, this.saveCallback);
  };

  validateCustomization = () => {
    var mapList = this.state.fieldCustomization.gfrIndexMapList;
    if (this.state.fieldCustomization.drawerId === '') {
      this.props.showValidation('Please select a Drawer.');
      this.focusField(this.htmlSelectFieldRefs['DrawerId_ref']);
      return false;
    }

    var requiredGFRIndexes = this.props.gfrSettingsState.gfrIndexes.filter(x => x.required).map(x => x.indexId);
    var mappedIndexes = mapList.filter(x => x.gfrIndexID !== '').map(x => x.gfrIndexID);
    if (requiredGFRIndexes.some(x => !mappedIndexes.includes(x))) {
      this.props.showValidation('Please map all required GFR Indexes.');
      var notMappedField = mapList.find(x => x.gfrIndexID === '') || mapList.at(0);
      this.htmlSelectFieldRefs[notMappedField?.field + '_ref']?.current?.focus();
      this.focusField(this.htmlSelectFieldRefs[notMappedField?.field + '_ref']);
      return false;
    }

    var fieldValueValidation = mapList.filter(x => x.gfrIndexID !== '' && (x.field == GFRDataFields.EngagementType || x.field == GFRDataFields.DocumentType) && x.isListIndex)
      .find((item) => Object.keys(item.listItemsMap).length < this.GFRIndexValueMaps[item.field]!.length || Object.keys(item.listItemsMap).some(x => item.listItemsMap[x] === ''));

    if (fieldValueValidation && fieldValueValidation != undefined) {
      this.props.showValidation(`Please enter all ${GFRDataFields[fieldValueValidation.field]} filed values.`);

      var fieldRef = `${fieldValueValidation.field}_${this.GFRIndexValueMaps[fieldValueValidation.field]?.map(x => x.fieldKey)
        .find(x => fieldValueValidation?.listItemsMap[x] == undefined || fieldValueValidation?.listItemsMap[x] == '')?.replace(' ', '_')}_ref`;

      this.htmlInputFieldRefs[fieldRef]?.current?.focus();
      this.focusField(this.htmlInputFieldRefs[fieldRef]);

      return false;
    }

    return true;
  }

  focusField = (fieldref: RefObject<HTMLSelectElement> | RefObject<HTMLInputElement>) => {

    setTimeout(() => {

      if (fieldref?.current) {
        fieldref.current.style.border = '';
      }

    }, 5000);

    if (fieldref?.current) {
      fieldref.current.focus();
      fieldref.current.style.border = '1px solid red';
    }
  }

  saveCallback = (isSuccess: boolean, error?: string) => {
    if (isSuccess) {
      this.props.getGFRSettings();
      this.handleCancel();
    }
  }

  handleCancel = () => {
    this.props.gfrSettingsState.gfrIndexes = [];
    this.props.handleClose();
  }

  getHtmlFieldsRefObj = () => {
    var htmlFieldRefs: { [key: string]: RefObject<HTMLSelectElement> } = {};
    getDataFields().forEach((field) => {
      htmlFieldRefs[field + '_ref'] = createRef<HTMLSelectElement>();
      this.addHtmlInputFieldRefs(field);
    });
    htmlFieldRefs['DrawerId_ref'] = createRef<HTMLSelectElement>();
    return htmlFieldRefs;
  }

  addHtmlInputFieldRefs = (field: GFRDataFields) => {
    if (this.GFRIndexValueMaps.hasOwnProperty(field)) {
      this.GFRIndexValueMaps[field]!.forEach((index) => {
        var refName = `${field}_${index.fieldKey.replace(' ', '_')}_ref`;
        this.htmlInputFieldRefs[refName] = createRef<HTMLInputElement>();
      });
    }
  }

  GFRIndexValueMaps: GFRIndexValueMapCust = {
    [GFRDataFields.EngagementType]: [
      { fieldText: 'Form 1040', fieldKey: "E1040" },
      { fieldText: 'Form 1065', fieldKey: "E1065" },
      { fieldText: 'Form 1120', fieldKey: "E1120" },
      { fieldText: 'Form 1120S', fieldKey: "E1120S" },
      { fieldText: 'Form 1041', fieldKey: "E1041" },
      { fieldText: 'Form 1040NR', fieldKey: "E1040NR" },
      { fieldText: 'Form 990', fieldKey: "E990" },
      { fieldText: 'Form 990PF', fieldKey: "E990PF" },
      { fieldText: 'Form 990EZ', fieldKey: "E990EZ" }
    ],
    [GFRDataFields.DocumentType]: [
      { fieldText: 'E-File', fieldKey: "Efile" }
    ]
  }

  render() {

    return (
      <Modal show={true} onHide={this.handleCancel} className={styles.modal_styles} dialogClassName={styles.dialog_styles} centered>
        <Modal.Header closeButton className={styles.modal_header}>
          <Modal.Title className={styles.modal_title}>Map to GoFileRoom</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          <Form>

            {/* Drawer Selector */}
            <Form.Group controlId="selectDrawer" className="mb-4">
              <Form.Label>Select Drawer</Form.Label>
              <Form.Select
                value={this.state.fieldCustomization.drawerId}
                onChange={this.handleDrawerChange}
                style={{ width: '50%' }}
                ref={this.htmlSelectFieldRefs['DrawerId_ref']}
              >
                <option value="" className={styles.select_placeholder}>Select Drawer...</option>
                {this.props.gfrSettingsState.gfrDrawers?.map((drawer) => (
                  <option value={drawer.drawerID}>{drawer.drawerName}</option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Field Mapping Table*/}
            <Table bordered>
              <thead>
                <tr>
                  <th style={{ width: '45%' }}>SafeSend Data</th>
                  <th style={{ width: '55%' }}>GoFileRoom Index</th>
                </tr>
              </thead>
              <tbody>
                {getDataFields().map((enumValue) => {
                  var fieldObj = this.state.fieldCustomization.gfrIndexMapList.find(x => x.field == enumValue) ||
                    {
                      ..._.cloneDeep(defaultIndexMap),
                      field: enumValue,
                    } as GFRIndexMap;
                  return (
                    <tr key={fieldObj.field}>
                      <td> <div className={styles.index_field_div}>{dataFieldsName[fieldObj.field]}</div>

                        {fieldObj.isListIndex && (

                          (this.GFRIndexValueMaps[fieldObj.field] || []).map((index) => (
                            <div className={styles.index_value_text}>
                              &emsp; &emsp; &emsp; {index.fieldText}
                            </div>
                          ))
                        )}

                      </td>
                      <td>
                        <Form.Select
                          value={this.state.fieldCustomization.gfrIndexMapList.find(x => x.field == fieldObj.field)?.gfrIndexID}
                          onChange={this.handleFieldChange(fieldObj)}
                          id={GFRDataFields[fieldObj.field] + '_dropdown'}
                          ref={this.htmlSelectFieldRefs[fieldObj.field + '_ref']}
                        >
                          <option value="" className={styles.select_placeholder}>{`Map ${dataFieldsName[fieldObj.field]}`}</option>

                          {this.props.gfrSettingsState.gfrIndexes?.map((index) => (
                            <option value={index.indexId}>{index.indexName}</option>
                          ))}

                        </Form.Select>

                        <div>
                          {fieldObj.isListIndex && (

                            (this.GFRIndexValueMaps[fieldObj.field] || []).map((index) => (
                              <input
                                type="text"
                                className="form-control"
                                id={index.fieldKey}
                                placeholder={`Map ${index.fieldText} Index Value`}
                                value={this.state.fieldCustomization.gfrIndexMapList.find(x => x.field == fieldObj.field)?.listItemsMap[index.fieldKey] || ''}
                                onChange={(e) => this.handleIndexValueChange(e, index, fieldObj)}
                                style={{ marginTop: '6px' }}
                                ref={this.htmlInputFieldRefs[`${fieldObj.field}_${index.fieldKey.replace(' ', '_')}_ref`]}
                              />
                            ))
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.modal_footer}>
          <Button variant="secondary" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default MapToGoFileRoom;
