import React from 'react';
import BreadCrumbNav from '../../components/userManagement/BreadCrumbNav';
import { EventSettingsState ,initialClientAPIDetail, initialEventSettings
     ,initialProductSubscriptions ,IClientAPIDetail,IEventSettings, EventType } from '../../modules/developer-section/developer-section.model'
import  * as EventSettingsStore  from '../../modules/developer-section/developer-section.store'
import { EventSettingsConstants, LoadingScreenConstants , ProductType } from '../../helper/Constants';
import { AppNotifier as VenusNotifier } from '../../helper/AppNotifier';
import { ProductSubscription } from '../../modules/developer-section/product-subscription/index';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { EventsTable } from '../../modules/developer-section/event-table';
import EventModal from '../../modules/developer-section/event-modal';
import EventsAPIModal from '../../modules/developer-section/event-api-modal';
import styles from  './index.module.scss';
import  './common.style.scss';
import { developersectionResources } from '../../helper/rbac-constants'
import { ICompanyProfile } from '../common/companySettings/companySettings.types';
import { logger } from '../../oidcClient/authProvider';
import { getWebhookFeatureEnabled } from '../../modules/developer-section/developer-section.apis';

type EventSettingsProps =
    {
        eventSettingsState: EventSettingsStore.IEventSettingsStoreState,
        companyProfileState: ICompanyProfile
    }  & typeof EventSettingsStore.actionCreators

    type ExtendedEventSettingsState = EventSettingsState & {
        webhookFeatureEnabled: boolean;
    };
    
    export class EventSettings extends React.Component<EventSettingsProps, ExtendedEventSettingsState> {
    
        constructor(props: EventSettingsProps) {
            super(props);
    
            this.state = {
                showAPISettingsModal: false,
                showEventModal: false,
                clientAPIDetail: initialClientAPIDetail,
                eventSettings: initialEventSettings,
                productSubscription : initialProductSubscriptions,
                webhookFeatureEnabled: false
            };    
        }
    
        componentDidMount() {
            logger && logger.trackPageView('Navigated to Developer Section');
            this.props.requestEventSettings(true);
            this.props.getProduct();         
            getWebhookFeatureEnabled()
            .then((isEnabled) => this.setState({ webhookFeatureEnabled: isEnabled }));
        }
      
        private setAPISettingsModal = (eventSettingsId: number, resourceId?: string) => {
    
            if (this.state.showAPISettingsModal == false) {
    
                const documentEvents = this.props.eventSettingsState.documentEvents;
                let tmpeventApi: IClientAPIDetail = documentEvents.clientAPIDetail.find(x => x.eventSettingsId == eventSettingsId) as IClientAPIDetail;
    
                if (!tmpeventApi) {
                    tmpeventApi = initialClientAPIDetail;
                    tmpeventApi.eventSettingsId = eventSettingsId;
                }
    
                this.setState({
                    clientAPIDetail: tmpeventApi,
                    showAPISettingsModal: true,
                    resourceId: resourceId
                });
    
            }
            else {
                this.setState({
                    showAPISettingsModal: false,
                    resourceId: resourceId
                });
            }
        }
    
    
        private toggleAPISettingsModal = (show: boolean) => {
    
            this.setState({
                showAPISettingsModal: show
            });
        }
    
    
        private toggleAPIModal = (show: boolean) => {
            this.setState({
                showEventModal: show
            });
        }
    
        private handleEventEdit = (eventType: EventType,productType : ProductType) => {
    
            const documentEvents = this.props.eventSettingsState.documentEvents;
            const tmpEventSettings : IEventSettings = documentEvents.eventSettings
                                                    .find(x => x.eventType === eventType && x.productType === productType) as  IEventSettings;       
    
            if (tmpEventSettings != null) {
                this.setState({
                    eventSettings: tmpEventSettings,
                    showEventModal: true
                });
            }
    
        }

        private handleEventSave = (updatedEmails: string,resourceId?:string) => {
            this.toggleAPIModal(false);
            const eventSetting = this.state.eventSettings;
            eventSetting.failureMail = updatedEmails;
            this.props.addDocumentEvent(eventSetting, () => { this.props.requestEventSettings(true) }, resourceId);
        }
    
        private handleChangeEventStatus = (eventType: EventType, productType : ProductType, eventSettingsId: number, enabled: boolean, resourceId?: string) => {
    
            const clientAPIDetails = this.props.eventSettingsState.documentEvents.clientAPIDetail;
    
            if (clientAPIDetails.some(x => x.eventSettingsId == eventSettingsId)) {
                this.props.changeEventStatus(eventType, productType, enabled, () => { this.props.requestEventSettings(true) }, resourceId);
            } else {
                VenusNotifier.Warning(EventSettingsConstants.EventAPINotFoundMessage);
            }    
        }
    
        private handleResumeService = (eventType : EventType, productType : ProductType) => {
            this.props.resumeEventService(eventType,productType, () => { this.props.requestEventSettings(true) });
        }
    
    
        private handleAddDocumentEventAPI = (clientAPIDetail: IClientAPIDetail, resourceId?: string) => {
            this.toggleAPISettingsModal(false);
            this.props.addDocumentEventAPI(clientAPIDetail, () => { this.props.requestEventSettings(true)}, resourceId);
        }

                
        private getProductStatus = (productId:number) =>{
            const { companyProfileState } = this.props;
            const companyProducts = companyProfileState && companyProfileState.companyProducts;
            const product = companyProducts && companyProducts.find((product) => product.productId === productId);
            return product ? product.productStatus : 2;
        }

        public render() {
    
            const breadCrumbConfig = [
                {
                    name: 'SafeSend One',
                    activePage: false,
                    link: '/',
                },
                {
                    name: 'Developer Section',
                    activePage: true,
                    link: '',
                },
            ];
    
            const documentEvents = this.props.eventSettingsState.documentEvents;
            const productSubscription =  this.props.eventSettingsState.productSubscriptions.length  ? this.props.eventSettingsState.productSubscriptions[0] : this.state.productSubscription ;
    
            const ssrEvents = documentEvents.eventSettings.filter(x => x.productType == 1 );            
            const orgEvents = documentEvents.eventSettings.filter(x => x.productType == 4 );            
            const sigEvents = documentEvents.eventSettings.filter(x => x.productType == 2 );
            const sseEvents = documentEvents.eventSettings.filter(x => x.productType == 6 );
            const gtrEvents = documentEvents.eventSettings.filter(x => x.productType == 7 );

			//productType == Suite  and eventyType = ClientInformationChange
            const clientManagementEvents = documentEvents.eventSettings.filter(x => x.productType == 1001 && x.eventType == 1);

            
            const isSignatureEnabled = this.getProductStatus(ProductType.SignaturesOneOff) === 1;
            const isOrganizerEnabled = this.getProductStatus(ProductType.Organizers) === 1;
            const isExchangeEnabled = this.getProductStatus(ProductType.Exchange) === 1;
            const isGtrEnabled = this.getProductStatus(ProductType.Gather) === 1;

            return (
            <div className={styles.content}>
                <section className={styles.developer_section}>
                    <BreadCrumbNav config={breadCrumbConfig} />
                    <h1 className={styles.developer_section_heading}>Developer Section</h1>
                    <section className="mainSection">
                        <Loader loading={this.props.eventSettingsState.isLoading}
                                    text={LoadingScreenConstants.LOADING} />
                        {this.state.webhookFeatureEnabled && (<div id="divEvent">

                            <h1 className={styles.table_header}>Returns</h1>
                             <EventsTable events={ ssrEvents }
                                eventApi={documentEvents.clientAPIDetail}
                                onApiSettingsActionClick={this.setAPISettingsModal}                
                                onResumeService={this.handleResumeService}
                                onChangeEventStatus={this.handleChangeEventStatus}
                                onEventEdit={this.handleEventEdit} 
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                onDownloadDocument={this.props.downloaddocumentAsync}
                                productType={ProductType.Returns}
                                onDownloadLog={this.props.downloadLogAsync}
                                />
                            <br/>

                            {isSignatureEnabled && (
                            <><h1 className={styles.table_header}>Signatures</h1><EventsTable events={sigEvents}
                                            eventApi={documentEvents.clientAPIDetail}
                                            onApiSettingsActionClick={this.setAPISettingsModal}
                                            onResumeService={this.handleResumeService}
                                            onChangeEventStatus={this.handleChangeEventStatus}
                                            onEventEdit={this.handleEventEdit}
                                            parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                            onDownloadDocument={this.props.downloaddocumentAsync}
                                            productType={ProductType.SignaturesOneOff} 
                                            onDownloadLog={this.props.downloadLogAsync}
                                            /><br /></>
                            )}

                            {isOrganizerEnabled && (
                            <><h1 className={styles.table_header}>Organizers</h1>
                            <EventsTable events={orgEvents}
                                eventApi={documentEvents.clientAPIDetail}
                                onApiSettingsActionClick={this.setAPISettingsModal}                
                                onResumeService={this.handleResumeService}
                                onChangeEventStatus={this.handleChangeEventStatus}
                                onEventEdit={this.handleEventEdit} 
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                onDownloadDocument={this.props.downloaddocumentAsync}
                                productType={ProductType.Organizers}
                                onDownloadLog={this.props.downloadLogAsync}
                                />
                            <br/></>
                            )}

                            {isExchangeEnabled && (
                            <><h1 className={styles.table_header}>Exchange</h1>
                            <EventsTable events={sseEvents}
                                eventApi={documentEvents.clientAPIDetail}
                                onApiSettingsActionClick={this.setAPISettingsModal}                
                                onResumeService={this.handleResumeService}
                                onChangeEventStatus={this.handleChangeEventStatus}
                                onEventEdit={this.handleEventEdit} 
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                onDownloadDocument={this.props.downloaddocumentAsync}
                                productType={ProductType.Exchange}
                                onDownloadLog={this.props.downloadLogAsync}
                                />
                            <br/></>
                            )}

                            <h1 className={styles.table_header}>Gather</h1>
                             <EventsTable events={ gtrEvents }
                                eventApi={documentEvents.clientAPIDetail}
                                onApiSettingsActionClick={this.setAPISettingsModal}                
                                onResumeService={this.handleResumeService}
                                onChangeEventStatus={this.handleChangeEventStatus}
                                onEventEdit={this.handleEventEdit} 
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                onDownloadDocument={this.props.downloaddocumentAsync}
                                productType={ProductType.Gather}
                                onDownloadLog={this.props.downloadLogAsync}
                                />
                            <br/>

                            <h1 className={styles.table_header}>Client Management</h1>
                             <EventsTable events={ clientManagementEvents }
                                eventApi={documentEvents.clientAPIDetail}
                                onApiSettingsActionClick={this.setAPISettingsModal}                
                                onResumeService={this.handleResumeService}
                                onChangeEventStatus={this.handleChangeEventStatus}
                                onEventEdit={this.handleEventEdit} 
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                onDownloadDocument={this.props.downloaddocumentAsync}
                                productType={ProductType.SSSuitePortal}
                                onDownloadLog={this.props.downloadLogAsync}
                                />
                            <br/>
                            



                            <EventsAPIModal show={this.state.showAPISettingsModal}
                                onToggleAPISettingsModal={this.toggleAPISettingsModal}
                                eventAPI={this.state.clientAPIDetail}
                                onTestConnection={this.props.testEndpointAsync}
                                onAddDocumentEventAPI={this.handleAddDocumentEventAPI}
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}
                                />
                
                            <EventModal show={this.state.showEventModal}
                                event={this.state.eventSettings}                                
                                onToggleEventModal={this.toggleAPIModal} onSave={this.handleEventSave} 
                                parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}/>
                                                       
                            <br/>
                        </div>
                        )}
                        <ProductSubscription onGetProduct={this.props.getProduct}
                            onSubscribe={this.props.subscribe}
                            onRegeneratePrimaryKey={this.props.regeneratePrimaryKey}
                            onRegenerateSecondaryKey = {this.props.regenerateSecondaryKey}
                            productSubscription={productSubscription} 
                            parentResourceIdentifier={developersectionResources.DeveloperSectionPrefix}/>   
                    </section>  
                </section>
            </div>
            );
        }
    }

    export default EventSettings;