import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { AppThunkAction } from '../../../store/index';
import { actionTypes } from './ClientHub.action';
import { logger } from '../../../oidcClient/authProvider';
import { API_BASE_URL } from '../../../helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { IUTEContactPerson, IUTESetting, IUTEState, KnownAction, initialState } from './ClientHub.model';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';

export const actionCreators = {
	requestUTESetting:
		(callback?: () => void): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				const response: AxiosResponse<IUTESetting> = await axios.get(API_BASE_URL + '/api/ute-setting');
				dispatch({ type: actionTypes.RECEIVE_UTE_SETTING, uteSetting: response.data });
				callback && callback();
			} catch (error: any) {
				logger && logger.trackWarning('Error while requestUTESetting', { Error: error?.response?.data });
				const statusMessage: any = error.statusText;
				if (typeof statusMessage === 'string') {
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error,
					});
				}
			}
		},

	updateUTESetting:
		(uteEnabled: boolean, callback?: () => void): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				await axios.put(
					API_BASE_URL + '/api/ute-setting',
					{ uteEnabled },
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
						},
						withCredentials: true,
					},
				);
				callback && callback();
			} catch (error: any) {
				logger && logger.trackWarning('Error while updateUTESetting', { Error: error?.response?.data });
				const statusMessage: any = error.statusText;
				if (typeof statusMessage === 'string') {
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error,
					});
				}
			}
		},
		
		updateRetentionPolicy:
		(uteSetting: IUTESetting, callback: (updateResponseSuccessful: boolean) => void): AppThunkAction<KnownAction> =>
		async (dispatch) => {
			try {
				await axios.put(
					API_BASE_URL + '/api/ute-setting/ute-retention-policy',
					uteSetting,
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
						},
						withCredentials: true,
					},
				);
				callback(true);
			} catch (error: any) {
				logger && logger.trackWarning('Error while updateRetentionPolicy', { Error: error?.response?.data });
				const statusMessage: any = error.statusText;
				if (typeof statusMessage === 'string') {
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error,
					});
				}
				callback(false);
			}
		},
	
	requestUTEContactPerson:
		(callback?: () => void): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				const response: AxiosResponse<IUTEContactPerson> = await axios.get(
					API_BASE_URL + '/api/ute-setting/get-contact-Person',
				);
				if (response.data) {
					dispatch({ type: actionTypes.RECEIVE_UTE_CONTACT_PERSON, uteContactPerson: response.data });
				}
				callback && callback();
			} catch (error: any) {
				logger && logger.trackWarning('Error while requestUTEContactPerson', { Error: error?.response?.data });
				const statusMessage: any = error.statusText;
				if (typeof statusMessage === 'string') {
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error,
					});
				}
			}
		},

	addUTEContactPerson:
		(contactPerson: IUTEContactPerson, callback?: () => void): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				await axios.post(API_BASE_URL + '/api/ute-setting', contactPerson, {
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
					withCredentials: true,
				});
				callback && callback();
			} catch (error: any) {
				logger && logger.trackWarning('Error while addUTEContactPerson', { Error: error?.response?.data });
				const statusMessage: any = error.statusText;
				if (typeof statusMessage === 'string') {
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error,
					});
				}
			}
		},

	updateUTEContactPerson:
		(contactPerson: IUTEContactPerson, callback?: () => void): AppThunkAction<KnownAction> =>
		async (dispatch, getState) => {
			try {
				await axios.put(API_BASE_URL + '/api/ute-setting/update-contact-person', contactPerson, {
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
					withCredentials: true,
				});
				callback && callback();
			} catch (error: any) {
				logger && logger.trackWarning('Error while updateUTEContactPerson', { Error: error?.response?.data });
				const statusMessage: any = error.statusText;
				if (typeof statusMessage === 'string') {
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: statusMessage,
						statusType: StatusType.Error,
					});
				}
			}
		},
};

const unloadedState: IUTEState = initialState;

export const reducer: Reducer<IUTEState> = (state = unloadedState, incomingAction) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case actionTypes.RECEIVE_UTE_SETTING:
			return {
				...state,
				uteSetting: action.uteSetting,
			} as IUTEState;

		case actionTypes.RECEIVE_UTE_CONTACT_PERSON:
			return {
				...state,
				uteContactPerson: action.uteContactPerson,
			} as IUTEState;

		default:
			return state;
	}
};
