import React, { type FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HorizontalDivider from 'src/components/common/HorizontalDivider';
import OverlayLoader from 'src/components/common/OverlayLoader';
import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';
import type * as IPFilteringStore from 'src/modules/securitySettings/ip-filtering-option/ip-filtering.api';
import MFASettings from 'src/modules/securitySettings/MFASettings';
import type * as CMFASettingsStore from 'src/modules/securitySettings/MFASettings/CMFASettings.store';
import {
	type ICompanyMfaSettings,
	type IMFASettingsState,
} from 'src/modules/securitySettings/MFASettings/MFASettings.model';
import type * as MFASettingsStore from 'src/modules/securitySettings/MFASettings/MFASettings.store';
import PasswordPolicy from 'src/modules/securitySettings/PasswordPolicy';
import {
	type IDefinedPasswordPolicy,
	type IPasswordPolicySettings,
} from 'src/modules/securitySettings/PasswordPolicy/PasswordPolicySettings.model';
import SSOSettings from 'src/modules/securitySettings/SSOSettings';
import { type ISSOSettings } from 'src/modules/securitySettings/SSOSettings/SSOSettings.model';
import type * as SSOSettingsStore from 'src/modules/securitySettings/SSOSettings/SSOSettings.store';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';

import IpFilteringList from '../../modules/securitySettings/ip-filtering-list';
import IpFilterOption from '../../modules/securitySettings/ip-filtering-option';
import { logger } from '../../oidcClient/authProvider';
import { type IInitialState } from '../../modules/securitySettings/ip-filtering-option/ip-filtering-options.types';
import { type IOverlayLoader } from '../common/overlayLoader/OverlayLoader.model';
import styles from './SecuritySettings.module.scss';


export type SecuritySettingsProps = {
	ssoSettings: ISSOSettings[];
	mfaSettings: IMFASettingsState;
	cMfaSettings: ICompanyMfaSettings;
	passwordPolicySettings: IPasswordPolicySettings;
	definedPasswordPolicies: IDefinedPasswordPolicy[];
	overlayLoader: IOverlayLoader;
	requestPasswordPolicySettings: () => void;
	requestDefinedPasswordPolicies: () => void;
	updatePasswordPolicySettings: () => void;
	ipFilteringOptions: IInitialState;
} & typeof SSOSettingsStore.actionCreators &
	typeof MFASettingsStore.actionCreators &
	typeof CMFASettingsStore.actionCreators &
	typeof IPFilteringStore.actionCreators;

const SecuritySettings: FC<SecuritySettingsProps> = (props) => {
	const breadCrumbConfig = [
		{
			name: 'SafeSend One',
			activePage: false,
			link: '/',
		},
		{
			name: 'Security',
			activePage: true,
			link: '',
		},
	];

	const dispatch = useDispatch();

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Security Settings');
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Loading...',
		});
	}, []);

	return (
		<div className='suitePageContent'>
			<section className='suitePageInnerContent'>
				<div className={styles.securitySettingsFixedHeader}>
					<BreadCrumbNav config={breadCrumbConfig} />
					<h1 className='suitePageHeading'>Security</h1>
				</div>
				<OverlayLoader
					show={props.overlayLoader.show}
					text={props.overlayLoader.text}
				/>
				<section className={styles.mainSection}>
					<IpFilterOption
						ipFilterList={props.ipFilterList}
						isFilteringEnabled={props.ipFilteringOptions.isFilteringEnabled}
						clientIpAddress={props.ipFilteringOptions.clientIpAddress}
						requestIpFilterOption={props.requestFilteringOption}
						updateIpFilterOption={props.updateFilteringOption}
						getClientIpAddress={props.getClientIpAddress}
					/>
					<IpFilteringList
						isFilteringEnabled={props.ipFilteringOptions.isFilteringEnabled}
						updateIpFilterOption={props.updateFilteringOption}
						clientIpAddress={props.ipFilteringOptions.clientIpAddress}
						ipFilterList={props.ipFilterList}
						requestIpList={props.requestIpList}
						updateIp={props.editIpList}
						addIp={props.addToIpList}
						deleteIp={props.deleteIpFromList}
					/>
					<HorizontalDivider />
					<MFASettings
						requestMFASettings={props.requestMFASettings}
						updateMFASettings={props.updateMFASettings}
						switchMFASettingsIU={props.switchMFASettingsIU}
						switchMFASettingsTP={props.switchMFASettingsTP}
						mfaSettings={props.mfaSettings}
						cMfaSettings={props.cMfaSettings}
						saveMFAIPAddress={props.saveMFAIPAddress}
						deleteMFAIPAddress={props.deleteMFAIPAddress}
						requestMFAIPAddress={props.requestMFAIPAddress}
						updateMFAIPAddress={props.updateMFAIPAddress}
						requestMFAIPUser={props.requestMFAIPUser}
						updateMFAEnableForAllUser={props.updateMFAEnableForAllUser}
						updateMFAEnableForUser={props.updateMFAEnableForUser}
					/>
					<HorizontalDivider />
					<PasswordPolicy
						passwordPolicySettings={props.passwordPolicySettings}
						definedPasswordPolicies={props.definedPasswordPolicies}
						requestPasswordPolicySettings={props.requestPasswordPolicySettings}
						requestDefinedPasswordPolicies={props.requestDefinedPasswordPolicies}
						updatePasswordPolicySettings={props.updatePasswordPolicySettings}
					/>
					<HorizontalDivider />
					<SSOSettings
						getSingleSignOnSettings={props.getSingleSignOnSettings}
						updateSSOSettings={props.updateSSOSettings}
						switchSingleSignOnSetting={props.switchSingleSignOnSetting}
						validateAzureADGroup={props.validateAzureADGroup}
					/>
				</section>
			</section>
		</div>
	);
};

export default SecuritySettings;
