import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './ProductDownloads.module.scss';
import Download from 'src/components/svg/Download';
import InfoIcon from 'src/components/svg/InfoIcon';
import {
	ICompanyProfile,
	ProductStatus,
	ProductType,
	SubscriptionTier,
} from 'src/pages/common/companySettings/companySettings.types';
import { ProductDownloadsConstant } from 'src/helper/Constants';
import {
	downloadMailMergeExe,
	downloadTicTieCalculate32bitMsi,
	downloadTicTieCalculate64bitMsi,
	downloadWebAddInManifest,
} from './ProductDownloads.store';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';
import { InfoCircle } from 'src/components/svg/IconCollection';

interface ProductDownloadsProps {
	companyProfile: ICompanyProfile;
}

const ProductDownloads: FC<ProductDownloadsProps> = (props) => {
	const [IsSignatureEnabled, setIsSignatureEnabled] = useState(false);
	const [IsExchangeEnabled, setIsExchangeEnabled] = useState(false);
	const [IsTicTieCalculateEnabled, setIsTicTieCalculateEnabled] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		let companyProducts = props.companyProfile.companyProducts;
		companyProducts.forEach((product) => {
			if (product.productId === ProductType.SignaturesMailMerge) {
				setIsSignatureEnabled(
					product.isSubscribed ? (product.productStatus === ProductStatus.Enabled ? true : false) : false,
				);
			} else if (product.productId === ProductType.Exchange) {
				setIsExchangeEnabled(
					product.isSubscribed ? (product.productStatus === ProductStatus.Enabled ? true : false) : false,
				);
			} else if (product.productId === ProductType.TicTieCalculate) {
				setIsTicTieCalculateEnabled(
					product.isSubscribed ? (product.productStatus === ProductStatus.Enabled ? true : false) : false,
				);
			}
		});
		dispatch({
			type: loaderTypes.LOADING,
			show: false,
			text: '',
		});
	}, [props.companyProfile]);

	const handleDownloadMailMerge = () => {
		logger && logger.trackTrace('handleDownloadMailMerge: Product downloads in general settings');
		downloadMailMergeExe('Suite_Gnrl_PrdctDwnlds_BtnDwnld', (success: boolean) => {
			if (!success) {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusMessage: ProductDownloadsConstant.SignatureFlowSettingsDownloadNoteError,
					statusType: StatusType.Error,
				});
			}
		});
	};
	const handleDownloadWebAddIn = () => {
		logger && logger.trackTrace('handleDownloadWebAddIn: Product downloads in general settings');
		downloadWebAddInManifest('Suite_Gnrl_PrdctDwnlds_BtnDwnld', (success: boolean) => {
			if (!success) {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusMessage: ProductDownloadsConstant.ExchangeWebAddInDownloadNoteError,
					statusType: StatusType.Error,
				});
			}
		});
	};
	const handleDownloadTicTieCalculate64bit = () => {
		logger && logger.trackTrace('handleDownloadTicTieCalculate64bit: Product downloads in general settings');
		downloadTicTieCalculate64bitMsi('Suite_Gnrl_PrdctDwnlds_BtnDwnld', (success: boolean) => {
			if (!success) {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusMessage: ProductDownloadsConstant.TicTieCalculateDownloadNoteError,
					statusType: StatusType.Error,
				});
			}
		});
	};

	const handleDownloadTicTieCalculate32bit = () => {
		logger && logger.trackTrace('handleDownloadTicTieCalculate32bit: Product downloads in general settings');
		downloadTicTieCalculate32bitMsi('Suite_Gnrl_PrdctDwnlds_BtnDwnld', (success: boolean) => {
			if (!success) {
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusMessage: ProductDownloadsConstant.TicTieCalculateDownloadNoteError,
					statusType: StatusType.Error,
				});
			}
		});
	};

	return (
		<article className='suitePageSection'>
			<h3 className='suitePageSectionHeader'>Product Downloads</h3>
			<h4 className='suitePageSectionSubHeader'>Mail Merge</h4>
			<p className='suitePageSectionDescription'>
				To enable personalization features for your <span className={styles.fontWieght600}>SafeSend Signatures</span>{' '}
				documents,
				<br />
				you need to download and install software.
			</p>
			<div className={styles.mailMergeDownload}>
				<button
					className={`${styles.downloadButton} ${IsSignatureEnabled ? '' : styles.disabledButton}`}
					title={IsSignatureEnabled ? ProductDownloadsConstant.SignatureFlowSettingsDownloadNote : ''}
					data-resource-id='SUITE_Gnrl_PrdctDwnlds_BtnDwnld'
					onClick={handleDownloadMailMerge}>
					<span>
						<Download />
					</span>
					Download
				</button>
				{!IsSignatureEnabled && (
					<span title={ProductDownloadsConstant.SignatureFlowSettingsDownloadDisableNote}>
						<InfoIcon />
					</span>
				)}
			</div>
			<h4 className='suitePageSectionSubHeader'>Web Add-in</h4>
			<p className='suitePageSectionDescription'>
				For a secure file-sharing experience, we recommend that you download
				<br />
				and install our <span className={styles.fontWieght700}>SafeSend Exchange Web Add-in</span> into your Outlook.
			</p>
			<div className={styles.webAddInDownload}>
				<button
					className={`${styles.downloadButton} ${IsExchangeEnabled ? '' : styles.disabledButton}`}
					title={IsExchangeEnabled ? ProductDownloadsConstant.ExchangeWebAddInDownloadNote : ''}
					data-resource-id='SUITE_Gnrl_PrdctDwnlds_BtnDwnld'
					onClick={handleDownloadWebAddIn}>
					<span>
						<Download />
					</span>
					Download
				</button>
				{!IsExchangeEnabled && (
					<span
						title={
							props.companyProfile.companySubscription.id === SubscriptionTier.SuiteEssentials
								? ProductDownloadsConstant.ProductSubscriptionError
								: ProductDownloadsConstant.ExchangeWebAddInDownloadDisableNote
						}>
						<InfoIcon />
					</span>
				)}
			</div>

			<h4 className='suitePageSectionSubHeader'>
				TicTie Calculate Plug-In &nbsp;
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement='right'
					rootClose
					overlay={
						<Tooltip id='tictie-download-tooltip'>{ProductDownloadsConstant.TicTieCalculateDownloadWarning}</Tooltip>
					}>
					{({ ref, ...triggerHandler }) => (
						<span
							ref={ref}
							{...triggerHandler}>
							<span className={styles.ticTieInfo}>
								<InfoIcon size={14} />
							</span>
						</span>
					)}
				</OverlayTrigger>
			</h4>
			<p className='suitePageSectionDescription'>
				To organize and annotate digitally, download the TTC Plug-In for Adobe Acrobat.
				<br />
				<br />
				Note: Adobe Acrobat 2020, 2017, DC (Standard or Professional) are supported.
				<br />
				Close all instances of Adobe Acrobat before installing.
			</p>
			<div className={styles.ticTieCalculateDownload}>
				<button
					className={`${styles.downloadButton} ${IsTicTieCalculateEnabled ? '' : styles.disabledButton}`}
					title={IsTicTieCalculateEnabled ? ProductDownloadsConstant.TicTieCalculateDownloadNote : ''}
					data-resource-id='SUITE_Gnrl_PrdctDwnlds_BtnDwnld'
					onClick={handleDownloadTicTieCalculate64bit}>
					<span>
						<Download />
					</span>
					Download for 64-Bit Acrobat
				</button>{' '}
				&nbsp;
				<button
					className={`${styles.downloadButton} ${IsTicTieCalculateEnabled ? '' : styles.disabledButton}`}
					title={IsTicTieCalculateEnabled ? ProductDownloadsConstant.TicTieCalculateDownloadNote : ''}
					data-resource-id='SUITE_Gnrl_PrdctDwnlds_BtnDwnld'
					onClick={handleDownloadTicTieCalculate32bit}>
					<span>
						<Download />
					</span>
					Download for 32-Bit Acrobat
				</button>
				{!IsTicTieCalculateEnabled && (
					<span
						title={
							props.companyProfile.companySubscription.id === SubscriptionTier.SuiteEssentials
								? ProductDownloadsConstant.ProductSubscriptionError
								: ProductDownloadsConstant.TicTieCalculateDownloadDisableNote
						}>
						<InfoIcon />
					</span>
				)}
			</div>
		</article>
	);
};

export default ProductDownloads;
