import { actionTypes } from './ClientHub.action';
import { NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { OverlayLoaderAction } from '../../../pages/common/overlayLoader/OverlayLoader.model';

export interface IUTESetting {
    uteEnabled: boolean;
    uteModified: string;
    uteRetentionPolicy: IUTERetentionPolicy;
	subdomain: string;
}

export interface IUTERetentionPolicy {
    uteRetentionPeriod: number;
    notifyExpiry: number;
    retentionPolicyModified: string;
}


export interface IUTEContactPerson {
	id: number;
	name: string;
	emailAddress: string;
	countryCode: string;
	mobileNumber: string;
}

export const initialUTEContactPerson: IUTEContactPerson = {
	id: 0,
	name: '',
	emailAddress: '',
	countryCode: '',
	mobileNumber: '',
};

export interface IUTEState {
	uteSetting: IUTESetting;
	uteContactPerson: IUTEContactPerson;
}

export const initialState: IUTEState = {
	uteSetting: {
		uteEnabled: false,
		uteModified: new Date().toString(),
		uteRetentionPolicy: {
			uteRetentionPeriod: 0,
			notifyExpiry: 0,
			retentionPolicyModified: new Date().toString(),
		},
		subdomain: '',
	},
	uteContactPerson: initialUTEContactPerson,
};

export enum SUBDOMAIN_EXISTS_ENUM {
	'NOTEXISTS' = 1,
	'EXISTS' = 2,
	'INVALID' = 3,
}

export type ISubDomainExistsResponse = {
	value: SUBDOMAIN_EXISTS_ENUM;
	error: boolean;
};

interface ReceiveUTESetting {
	type: actionTypes.RECEIVE_UTE_SETTING;
	uteSetting: IUTESetting;
}

interface ReceiveUTEContactPerson {
	type: actionTypes.RECEIVE_UTE_CONTACT_PERSON;
	uteContactPerson: IUTEContactPerson;
}

export type KnownAction = ReceiveUTESetting | ReceiveUTEContactPerson | NotificationAction | OverlayLoaderAction;
