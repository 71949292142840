export const MessageNoteInfoIcon: React.FC = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g clip-path='url(#clip0_3539_244)'>
				<path
					d='M7.99963 15.9993C12.4179 15.9993 15.9996 12.4175 15.9996 7.99927C15.9996 3.58099 12.4179 -0.000732422 7.99963 -0.000732422C3.58136 -0.000732422 -0.000366211 3.58099 -0.000366211 7.99927C-0.000366211 12.4175 3.58136 15.9993 7.99963 15.9993ZM8.93034 6.58716L7.92838 11.2922C7.85807 11.6321 7.95768 11.8254 8.23307 11.8254C8.42643 11.8254 8.7194 11.7551 8.91862 11.5793L8.83073 11.9954C8.54362 12.3411 7.91081 12.593 7.36588 12.593C6.66276 12.593 6.36393 12.1711 6.55729 11.2747L7.29557 7.80591C7.36002 7.51294 7.30143 7.40747 7.00846 7.33716L6.55729 7.25513L6.63932 6.87427L8.93034 6.58716ZM7.99963 5.49927C7.44735 5.49927 6.99963 5.05155 6.99963 4.49927C6.99963 3.94698 7.44735 3.49927 7.99963 3.49927C8.55192 3.49927 8.99963 3.94698 8.99963 4.49927C8.99963 5.05155 8.55192 5.49927 7.99963 5.49927Z'
					fill='#0973BA'
				/>
			</g>
			<defs>
				<clipPath id='clip0_3539_244'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
