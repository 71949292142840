import { useEffect, useState } from 'react';
import styles from './retention-policy.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import { ClientHubNotifications } from '../../../../helper/Constants';
import { IUTESetting } from '../ClientHub.model';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import InfoIcon from 'src/components/svg/InfoIcon';

type RetentionPolicyProps = {
	uteSetting: IUTESetting;
	startLoader: (text: string) => void;
	stopLoader: () => void;
	notification: (statusMessage: string, statusType: StatusType) => void;
	updateRetentionPolicy: (uteSetting: IUTESetting, callback: (updateResponseSuccessful: boolean) => void) => void;
};

const RetentionPolicy = ({
	uteSetting,
	startLoader,
	stopLoader,
	notification,
	updateRetentionPolicy,
}: RetentionPolicyProps) => {
	const [retentionPolicySettingLocal, setRetentionPolicySettingLocal] = useState({
		retentionPeriod: 0,
		notifyBefore: 0,
	});
	const [isUpdateRetentionDisabled, setIsUpdateRetentionDisabled] = useState<boolean>(true);

	useEffect(() => {
		if (uteSetting.uteRetentionPolicy) {
			setRetentionPolicySettingLocal({
				retentionPeriod: uteSetting.uteRetentionPolicy.uteRetentionPeriod,
				notifyBefore: uteSetting.uteRetentionPolicy.notifyExpiry,
			});
		}
	}, [uteSetting.uteRetentionPolicy]);

	const isOptionDisabled = (option: number) => {
		if (retentionPolicySettingLocal.retentionPeriod === 30 && [30, 60, 90].includes(option)) return true;
		if (retentionPolicySettingLocal.retentionPeriod === 90 && option === 90) return true;
		return false;
	};

	const handleRetentionPolicySettingChange = (field: string, value: number) => {
		setRetentionPolicySettingLocal((prev) => ({
			...prev,
			[field]: value,
		}));
		setIsUpdateRetentionDisabled(false);
	};

	const handleUpdateRetention = () => {
		if (
			(retentionPolicySettingLocal.retentionPeriod === 30 &&
				[30, 60, 90].includes(retentionPolicySettingLocal.notifyBefore)) ||
			(retentionPolicySettingLocal.retentionPeriod === 90 && retentionPolicySettingLocal.notifyBefore === 90)
		) {
			notification(ClientHubNotifications.ERROR_RETENTION_POLICY_CONFLICT, StatusType.Error);
			return;
		}
		startLoader(ClientHubNotifications.UPDATINGRETENTIONPOLICY);

		const updatedRetentionPolicy = {
			...uteSetting,
			uteRetentionPolicy: {
				...uteSetting.uteRetentionPolicy,
				uteRetentionPeriod: retentionPolicySettingLocal.retentionPeriod,
				notifyExpiry: retentionPolicySettingLocal.notifyBefore,
			},
		};

		updateRetentionPolicy(updatedRetentionPolicy, (updateResponseSuccessful: boolean) => {
			if (updateResponseSuccessful) {
				notification(ClientHubNotifications.UPDATERETENTIONPOLICYSUCCESS, StatusType.Success);
			} else {
				notification(ClientHubNotifications.ERROR_UPDATING_RETENTION_POLICY, StatusType.Error);
			}
			stopLoader();
		});

		setIsUpdateRetentionDisabled(true);
	};

	return (
		<section className={styles.retentionPolicySection}>
			<h3 className={styles.header}>Retention Policy</h3>
			<p className='suitePageSectionDescription'>
				Set the retention policy for tasks and documents found in Client Portal.
			</p>
			<div className={styles.policyContainer}>
				<div className={styles.dropdownWithTooltip}>
					<label htmlFor='retentionPeriod'>Retention Period</label>
					<OverlayTrigger
						trigger={['hover', 'focus']}
						placement='bottom'
						overlay={
							<Tooltip
								className='clientHubTooltip'
								style={{
									width: '269px',
								}}>
								Set a global default retention period for all files in Client Portal. If an individual document's
								retention period is shorter, it will take precedence.
							</Tooltip>
						}>
						<span className={styles.tooltipIcon}>
							<InfoIcon size={14} />
						</span>
					</OverlayTrigger>
					<select
						id='retentionPeriod'
						className={styles.dropdown}
						value={retentionPolicySettingLocal.retentionPeriod}
						onChange={(e) => handleRetentionPolicySettingChange('retentionPeriod', Number(e.target.value))}>
						<option value={30}>30 days</option>
						<option value={90}>90 days</option>
						<option value={180}>180 days</option>
						<option value={365}>1 year</option>
						<option value={730}>2 years</option>
						<option value={1095}>3 years</option>
						<option value={1461}>4 years</option>
						<option value={1826}>5 years</option>
					</select>
				</div>

				<div className={styles.notifyBeforeContainer}>
					<label htmlFor='notifyBefore'>Notify Taxpayer of Expiry</label>
					<OverlayTrigger
						trigger={['hover', 'focus']}
						placement='bottom'
						overlay={
							<Tooltip
								className='clientHubTooltip'
								style={{
									width: '268px',
								}}>
								Alert clients of upcoming file expiration via email and through Client Portal.
							</Tooltip>
						}>
						<span className={styles.tooltipIcon}>
							<InfoIcon size={14} />
						</span>
					</OverlayTrigger>
					<select
						id='notifyBefore'
						className={styles.dropdown}
						value={retentionPolicySettingLocal.notifyBefore}
						onChange={(e) => handleRetentionPolicySettingChange('notifyBefore', Number(e.target.value))}>
						<option value={3}>3 days before</option>
						<option value={7}>1 week before</option>
						<option value={14}>2 weeks before</option>
						<option
							value={30}
							disabled={isOptionDisabled(30)}>
							30 days before
						</option>
						<option
							value={60}
							disabled={isOptionDisabled(60)}>
							60 days before
						</option>
						<option
							value={90}
							disabled={isOptionDisabled(90)}>
							90 days before
						</option>
					</select>
				</div>

				<div className={styles.buttonContainer}>
					<ButtonWithTextIcon
						label='Update Retention'
						className={styles.updateButton}
						disabled={isUpdateRetentionDisabled}
						disabledText='Please make changes to enable the button'
						onClick={handleUpdateRetention}
					/>
				</div>
			</div>
		</section>
	);
};

export default RetentionPolicy;
