import { NotificationAction, StatusType } from '../../pages/common/notification/notification.reducer';
import { actionTypes } from './InOfficeESigning.actions';

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    DeliveredToTaxCaddy,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed,
    UploadInProgress,
    TaxCaddyDeliveryFailed,
    AlternateDelivery,
    DownloadingFailed,
    AlternateAndUploaded,
    PartiallySigned,
    SignatureStampingFailed,
    ESignedInOffice,
    AwaitingDraftReview,
    DraftApproved,
    DraftRejected
}

export interface IInOfficeEsignReturn {
	isLoading: boolean;
	inOfficeReturnList: IInOfficeReturnModel[];
	count: number;
}

export interface IInOfficeReturnModel {
	taxYear: number;
	clientId: string;
	controllerGuid: string;
	clientGuid: string;
	name: string;
	documentId: number;
	signatureStatus: number;
	groupName: string;
	totalCount: number;
}

export const intialInOfficeESignReturn: IInOfficeEsignReturn = {
	isLoading: false,
	inOfficeReturnList: [],
	count: 0,
};

export type KnownAction = DispatchAction | NotificationAction;

export type DispatchAction = RequestInOfficeESigningAction | ReceiveInOfficeESigningAction | ErrorInOfficeReturnAction;

interface ErrorInOfficeReturnAction {
	type: actionTypes.ERROR_INOFFICE_RETURNS;
	statusMessage: any;
	statusType: StatusType.Error;
}

interface RequestInOfficeESigningAction {
	type: actionTypes.REQUEST_INOFFICE_RETURNS;
}

interface ReceiveInOfficeESigningAction {
	type: actionTypes.RECEIVE_INOFFICE_RETURNS;
	inOfficeReturnList: IInOfficeReturnModel[];
}
