import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { AppThunkAction } from 'src/store/index';
import { IBillingDetailData, IPaymentDetails, IPaymentViewModel, IPurchaseData, KnownAction, initialBillingDetailData, initializePaymentDetails, intialPurchaseViewModel } from './PurchaseReturn.model';
import { actionTypes } from './PurchaseReturn.actions';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import { API_BASE_URL, PaymentHistoryConstants, PurchaseReturnConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    getElectronicFiledBillingDetail: (callback: (data?: any) => void):
        AppThunkAction<KnownAction> => (dispatch) => {
            axios.get(`${API_BASE_URL}/api/returns/payment-history/electronic-filed-billing-detail`)
                .then((response: AxiosResponse<IBillingDetailData>) => {
                    const { data } = response;
                    if (callback && data) {
                        callback(data);
                    }
                    dispatch({
                        type: actionTypes.RECEIVE_BILLING_DETAIL, billingDetail: data
                    });
                }).catch((error) => {
                    logger && logger.trackWarning('Error while getElectronicFiledBillingDetail', { 'Error': error?.response?.data});
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: PaymentHistoryConstants.StatusMessage.FetchPaymentHistoryError,
                        statusType: StatusType.Error
                    });
                    dispatch({
                        type: loaderTypes.LOADING,
                        show: false,
                        text: '',
                    });
                });
        },

    savePurchase: (purchaseViewModel: IPaymentViewModel, callback?: (data: any, onError: boolean) => void): AppThunkAction<KnownAction> => (dispatch) => {
        axios.post(API_BASE_URL + '/api/returns/pay-now/initiate-payment', purchaseViewModel)
            .then((response: AxiosResponse<IPaymentDetails>) => {
                const { data } = response;
                if (data && data.payemtSessionId) {
                    if (callback) {
                        callback(data, false);
                    }
                } else {
                    if (callback) {
                        callback(data, true);
                    }
                }
            })
            .catch((error) => {
				logger && logger.trackWarning('Error while savePurchase', { 'Error': error?.response?.data});
                if (callback) {
                    callback('error', true);
                }
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: PurchaseReturnConstants.FailureSavePurchase,
                    statusType: StatusType.Error,
                });
            })
    },

    getPaymentSetUpToken: (paymentSetUpInfo: IPaymentSetUpInfo, callback?: (data: any, onError: boolean) => void): AppThunkAction<KnownAction> => (dispatch) => {
        axios.post(API_BASE_URL + '/api/Payasyougo/GetPaymentSetUpToken', paymentSetUpInfo)
            .then((response: AxiosResponse) => {
                const { data } = response;
                if (data && data.id) {
                    if (callback) {
                        callback(data, false);
                    }
                } else {
                    if (callback) {
                        callback(data, true);
                    }
                }
            })
            .catch((error) => {
				logger && logger.trackWarning('Error while getPaymentSetUpToken', { 'Error': error?.response?.data});
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: PurchaseReturnConstants.FailureGenerateToken,
                    statusType: StatusType.Error,
                });
            })
    },
};

const unloadedState: IPurchaseData = {
    billingDetail: initialBillingDetailData,
    purchaseViewModel: intialPurchaseViewModel,
    paymentDetails: initializePaymentDetails,
};

export const reducer: Reducer<IBillingDetailData> = (state: IPurchaseData = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_BILLING_DETAIL:
            return {
                billingDetail: action.billingDetail,
                purchaseViewModel: state.purchaseViewModel,
                paymentDetails: initializePaymentDetails,
            } as IPurchaseData;
        default:
            return unloadedState;
    }
};