import React, { ReactNode } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { GroupBase, ValueContainerProps, components } from 'react-select';
import './CustomMultiDropdown.style.scss';
import DownIcon from '../../../components/svg/DownIcon';
import CloseIcon from '../../../components/svg/CloseIcon';
import { ICustomDropdownOption } from '../customDropdown/CustomDropdownWithCustomOptions';

interface ValueComponentProps extends ValueContainerProps<any, boolean, GroupBase<any>> {
	hasValue: boolean;
	children: ReactNode;
}

export const ValueComponent: React.FC<ValueContainerProps<any, boolean, GroupBase<any>>> = ({ children, ...props }) => {
	const { hasValue, selectProps } = props;

	const locationNames = selectProps.value
		?.filter((current_location: ICustomDropdownOption) => current_location.value !== -1)
		.map((location: ICustomDropdownOption) => location.label);

	const length = locationNames.length;

	const popover = (
		<Popover
			id='popover-basic'
			className='overlay-popover'>
			{<p className='overlay-popover_text'>{locationNames?.join(', ')}</p>}
		</Popover>
	);

	if (!hasValue || length === 0) {
		return (
			<components.ValueContainer {...props}>
				{
					<div className='value-container'>
						<div className='text-ellipsis value-container_placeholder'>{props.selectProps.placeholder}</div>
					</div>
				}
				{React.Children.map(children, (child: any) => {
					return child?.props.id === 'clickableInput' ? child : null;
				})}
			</components.ValueContainer>
		);
	}
	if (length === 1) {
		const locationNumber = selectProps.value?.filter(
			(current_location: ICustomDropdownOption) => current_location.value !== -1,
		)[0]?.value;
		return (
			<components.ValueContainer {...props}>
				{
					<div className='value-container'>
						<div
							className='text-ellipsis value-container_label'
							title={locationNames?.[0]}>
							{locationNames?.[0]}
						</div>
						<div className='value-container_hyphen'>-</div>
						<div
							className='text-ellipsis value-container_value'
							title={`Location #${locationNumber}`}>{`Location #${locationNumber}`}</div>
					</div>
				}
				{React.Children.map(children, (child: any) => {
					return child?.props.id === 'clickableInput' ? child : null;
				})}
			</components.ValueContainer>
		);
	}
	return (
		<components.ValueContainer {...props}>
			{
				<div className='user-popover'>
					<OverlayTrigger
						key='moregroups'
						placement='bottom'
						overlay={popover}>
						<span className='moreGroups'>{`${length} Locations Selected`}</span>
					</OverlayTrigger>
				</div>
			}
			{React.Children.map(children, (child: any) => {
				return child?.props.id === 'clickableInput' ? child : null;
			})}
		</components.ValueContainer>
	);
};

export const OptionComponent = (props: any) => {
	const isSelectAllOption = props.value === -1;
	const locationNumber = !isSelectAllOption ? props.options.filter((x: any) => x.value === props.value)[0]?.id : null;

	return (
		<div>
			<components.Option {...props}>
				<div className='option-container'>
					<input
						className='option-container_input'
						type='checkbox'
						checked={props.isSelected}
						onChange={() => null}
					/>
					<label className='option-container_label'>
						<div
							className='text-ellipsis option-container_label--name'
							title={props.label}>
							{props.label}
						</div>
						{!isSelectAllOption && (
							<>
								<div className='option-container_label--hyphen'>-</div>
								<div
									className='text-ellipsis option-container_label--value'
									title={`Location #${locationNumber}`}>{`Location #${locationNumber}`}</div>
							</>
						)}
					</label>
				</div>
			</components.Option>
		</div>
	);
};

export const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<DownIcon />
		</components.DropdownIndicator>
	);
};

export const ClearIndicator = (props: any) => {
	return (
		<components.ClearIndicator {...props}>
			<CloseIcon />
		</components.ClearIndicator>
	);
};
