import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomDropdown, { ICustomDropdownOption } from '../../../../components/common/customDropdown';
import MessageNote, { MessageNoteType } from '../../../../components/common/message-note/message-note';
import { IDragAndDropData } from '@components/common/DragAndDrop/DragAndDrop.model';
import styles from './default-contact-person.module.scss';

interface DefaultContactPersonProps {
	selectedDefaultContactPerson: ICustomDropdownOption | null;
	handleDefaultContactPersonChange: (option: ICustomDropdownOption | null) => void;
	usersInLocation: IDragAndDropData<string, number, string | undefined>[];
	noDefaultContactPersonError: boolean;
}

const DefaultContactPerson: React.FC<DefaultContactPersonProps> = ({
	selectedDefaultContactPerson,
	handleDefaultContactPersonChange,
	usersInLocation,
	noDefaultContactPersonError,
}) => {
	return (
		<div className={styles.defaultContactPersonSection}>
			<MessageNote
				note='If the original contact user is deleted, the default contact person will automatically be assigned to all applicable requests.'
				noteType={MessageNoteType.Info}
			/>
			<Row className={styles.defaultcontactPersonRowWrapper}>
				<Col
					lg={3}
					className={styles.defaultcontactPersonLabel}>
					<div className={styles.title}>Default Contact Person</div>
				</Col>
				<Col
					className={styles.defaultcontactPersonDropdown}
					lg={7}>
					<CustomDropdown
						id={'defaultContactPerson'}
						selectedOption={selectedDefaultContactPerson}
						setSelectedOption={handleDefaultContactPersonChange}
						options={usersInLocation.map((x) => ({ label: x.name, value: x.value }))}
						customPlaceholderStyles={'#565A5E'}
						clearable={false}
						scrollableMenu={true}
						height={31}
						borderColor={noDefaultContactPersonError ? '#CC4A43' : '#898D91'}
					/>
				</Col>
			</Row>

			{noDefaultContactPersonError && (
				<Row>
					<Col lg={{ span: 7, offset: 3 }}>
						<div className={styles.noDefaultContactPersonError}>This field is required.</div>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default DefaultContactPerson;

