import axios from 'axios';
import { IInOfficeReturnModel, SignatureStatus } from './InOfficeESigning.model';
import { API_BASE_URL } from '../../helper/Constants';
import { AppNotifier } from '../../helper/AppNotifier';

export const checkReturnStatus = async (returnItem: IInOfficeReturnModel, callback: () => void) => {
	try {
		const response = await axios.get(API_BASE_URL + '/api/inoffice-esigning/signature-status/' + returnItem.documentId);
		if ([SignatureStatus.AwaitingESign, SignatureStatus.PartiallySigned, SignatureStatus.AwaitingDraftReview].includes(response.data)) {
			callback();
		} else {
			AppNotifier.Error('Signing has been completed. Please refresh this page.');
		}
		return;
	} catch (error) {
		console.log(error);
	}
};
