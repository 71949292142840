/* eslint-disable max-lines-per-function */
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { actionTypes } from 'src/pages/branding-management/branding-management.actions';

import ButtonWithTextIcon from '../../../components/common/button-with-text-icon';
import ConfirmationPopup from '../../../components/common/confirmationPopup/index';
import { ReactComponent as EditIcon } from '../../../components/svg/pencil.svg';
import { ReactComponent as TrashIcon } from '../../../components/svg/trash.svg';
import { brandingSecResources } from '../../../helper/rbac-constants';
import ImageCard from '../partials/image-card/image-card';
import LogoUploadOverlay from '../partials/logo-upload-overlay/logo-upload-overlay';
import SectionDescription from '../partials/section-description/section-description';
import SectionHeader from '../partials/section-header/section-header';
import { useCompanyLogo } from './company-logo.hooks';
import { type ICompanyLogoProps } from './company-logo.types';
import styles from './index.module.scss';
import { logger } from '../../../oidcClient/authProvider';

const CompanyLogo: FC<ICompanyLogoProps> = (props) => {
	const {
		logoPath,
		setShowLogoDeletePopup,
		showLogoDeletePopup,
		showLogoEditPopup,
		setShowLogoEditPopup,
		isLogoLoading,
		isSsrLogo,
		retrivedLogoIsDefault,
		requestCompanyUploadLink,
		getCompanyLogo,
		logoUploadLink,
		updateTempCompanyLogo,
		deleteCompanyLogo,
		updateReminders,
	} = useCompanyLogo(props);

	const dispatch = useDispatch();

	const handleCancel = (): void => {
		getCompanyLogo();
		setShowLogoEditPopup(false);
	};

	const handleSave = (): void => {
		logger && logger.trackTrace('handleSave: Saving company logo');
		setShowLogoEditPopup(false);
		getCompanyLogo();
		updateReminders();
	};

	const handleCheckboxSave = (): void => {
		if ((!isSsrLogo && !logoPath.includes('blob:')) || !retrivedLogoIsDefault)
			deleteCompanyLogo(() => {
				getCompanyLogo();
				updateReminders();
			});
		else getCompanyLogo();

		dispatch({ type: actionTypes.RESET_CUSTOM_COMPANY_LOGO_UPLOAD_LINK });
		setShowLogoEditPopup(false);
	};

	const handleDeleteLogo = (): void => {
		logger && logger.trackTrace('handleDeleteLogo: Delete company button clicked');
		deleteCompanyLogo(() => {
			getCompanyLogo();
			updateReminders();
		});
		dispatch({ type: actionTypes.RESET_CUSTOM_COMPANY_LOGO_UPLOAD_LINK });
	};

	return (
		<div className={styles.company_logo_wrapper}>
			<div className={styles.company_logo_header}>
				<SectionHeader title='Company Logo' />
			</div>
			<div className={styles.company_logo_description}>
				<SectionDescription
					text={`It will be utilized across all web pages, emails, and notifications.
						Please note that the dimensions of the company logo should not exceed 300x100px.`}
				/>
			</div>
			<div className={styles.company_logo_card}>
				<ImageCard
					src={logoPath}
					alt='company logo'
					isLoading={isLogoLoading}
				/>
			</div>
			<div className={styles.company_logo_buttons_wrapper}>
				<ButtonWithTextIcon
					label='Edit'
					SvgIcon={<EditIcon />}
					className={styles.company_logo_action_buttons}
					onClick={() => {
						setShowLogoEditPopup(true);
					}}
					data-test-auto='branding-management_company-logo_edit'
					data-resource-id={`${brandingSecResources.prefix}_${brandingSecResources.companyLogo}_${brandingSecResources.EditButton}`}
					bordered
				/>
				<ButtonWithTextIcon
					disabled={isSsrLogo}
					disabledText='SafeSend default logo cannot be deleted.'
					label='Delete'
					SvgIcon={<TrashIcon />}
					className={styles.company_logo_action_buttons}
					onClick={() => {
						setShowLogoDeletePopup(true);
					}}
					data-test-auto='branding-management_company-logo_delete'
					data-resource-id={`${brandingSecResources.prefix}_${brandingSecResources.companyLogo}_${brandingSecResources.DeleteButton}`}
					bordered
				/>
			</div>
			<ConfirmationPopup
				show={showLogoDeletePopup}
				actionButtonType={'danger'}
				cancelText='No, Keep It'
				header='Delete Logo'
				message={
					'Are you sure you wish to delete your primary company logo and replace it with the SafeSend One logo instead?'
				}
				okText='Yes, Delete It'
				onCancel={() => {
					setShowLogoDeletePopup(false);
				}}
				onOk={() => {
					handleDeleteLogo();
					setShowLogoDeletePopup(false);
				}}
				onHide={() => {
					setShowLogoDeletePopup(false);
				}}
				actionDisabled={false}
			/>
			<LogoUploadOverlay
				logo={logoPath}
				showDefaultLogoCheckbox={true}
				isSsrLogo={isSsrLogo}
				show={showLogoEditPopup}
				header='Upload Logo'
				requestUploadLink={requestCompanyUploadLink}
				uploadLink={logoUploadLink}
				callbackAfterUpload={updateTempCompanyLogo}
				dataTestPrefix='branding-management_company-logo_upload-logo'
				handleCheckboxSave={handleCheckboxSave}
				description={
					"Upload your Company Logo here. Please ensure that the dimensions of the company's logo do not exceed 300x100px."
				}
				onHide={() => {
					setShowLogoEditPopup(false);
				}}
				onCancel={() => {
					handleCancel();
				}}
				onSave={() => {
					handleSave();
				}}
			/>
		</div>
	);
};

export default CompanyLogo;
