import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IProductData } from "../../pages/welcome/welcome.types";
import { ApplicationState } from "../../store";
import { getAddOnFeatureLogo, getProductLogo } from "../svg/LogoCollection";
import { ProductType } from "src/helper/Constants";

const ProductList: FC = () => {
    const products = useSelector((state: ApplicationState) => state.welcomeState.productList);
    const addonFeatures = useSelector((state: ApplicationState) => state.welcomeState.addOnFeatureList);
    const onProductClick = (data: IProductData) => {
        if (!data.disabled) window.open(data.route, '_blank', 'noopener=true');
    };

    const onAddonFeatureClick = (data: IProductData) => {
        if (!data.disabled) window.open(data.route, '_self', 'noopener=true');
    };

    return <section className="product-section">
        <Container fluid="sm">
            <div className="product-list">
                {products.map((data, index) => {
                    return <div className={`logo-wrapper ${data.disabled ? "disabled" : ""}`}
                        key={index}
                        id={`grid-${ProductType[Number(data.icon)]}`}
                        onClick={() => onProductClick(data)}>
                        <div className="ellipse">
                            {getProductLogo(data.icon)}
                        </div>
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: data.caption }} />
                    </div>;
                })}
                {addonFeatures.map((data, index) => {
                    return <div className={`logo-wrapper ${data.disabled ? "disabled" : ""}`}
                        key={index}
                        id={`addongrid-${ProductType[Number(data.icon)]}`}
                        onClick={() => onAddonFeatureClick(data)}>
                        <div className="ellipse">
                            {getAddOnFeatureLogo(data.icon)}
                        </div>
                        <div className="mt-3" dangerouslySetInnerHTML={{ __html: data.caption }} />
                    </div>;
                })}
            </div>
        </Container>
    </section>;
};

export default ProductList;