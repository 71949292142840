import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { InfoIcon } from "../svg/IconCollection";

const Banner = () => {
    const [hide, setHide] = useState<boolean>(false);

    const onButtonClick = () => {
        window.open("https://safesend.com/learn/product-education-center/ ");
    };
    return <section className="banner-section">
        <Container fluid="sm">
            {/* THIS ALERT WILL BE HIDDEN FOR NOW */}
            <Alert show={false} onClose={() => setHide(true)} dismissible>
                <InfoIcon />
                <span className="ms-2">Holy guacamole! You should check in on some of those fields below.</span>
            </Alert>
            <Container className="container">
                <Row className="banner-container">
                    <Col className="image-column p-0" xs={6}>
                    </Col>
                    <Col className="content">
                        <h3>BECOME A POWER USER</h3>
                        <div className="description">Get the most out of all of our features with easy, interactive <br /> walkthroughs that'll make you a SafeSend One expert in no time.</div>
                        <div>
                        <button
                            className="button-primary-green-rounded btn-shamrock mt-3"
                            onClick={onButtonClick}
                            >
                            BROWSE TUTORIALS
                        </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    </section>;
};

export default Banner;