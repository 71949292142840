import { NotificationAction } from "src/pages/common/notification/notification.reducer";
import { actionTypes } from "./GFR.action";
import { OverlayLoaderAction } from 'src/pages/common/overlayLoader/OverlayLoader.model';

export interface GFRSettings {
    isConnected: boolean;
    username: string;
    gfrIndexMapCustomization?: GFRIndexMapCustomization;
    isGFREnabled: boolean;
}

export interface GFRSettingsState {
    gfrSettings: GFRSettings;
    gfrDrawers: GFRDrawers[];
    gfrIndexes: GFRIndex[];
}

export interface Credentials {
    loginname: string;
    password: string;
}

export interface GFRCredentialsSaveResponse {
    success: boolean;
    username: string;
}

export interface GFRDrawers {
    drawerID: string;
    drawerName: string;
    permissions: Permissions[];
}

export interface Permissions {
    view: boolean;
    edit: boolean;
    add: boolean;
    Delete: boolean;
    LookupManagement: string;
}

export interface GFRIndex {
    indexId: string,
    indexName: string,
    indexType: string,
    length: number,
    required: boolean,
    listId: number,
    lookupAutoUpdate: boolean,
    childIndexId: string
}

export interface GFRIndexMap {
    field : GFRDataFields
    gfrIndexID: string;
    gfrIndexName: string;
    gfrIndexType: string;
    isListIndex: boolean;
    listItemsMap: { [key: string]: string; }
    required?: boolean,
}

export enum GFRDataFields {
    ClientId = 1,
    ClientName,
    EngagementType,
    DocumentType,
    Taxyear,
    EroSigner,
    SentBy,
    OfficeLocation,
    SignedDate,
}

export interface GFRIndexMapCustomization {
    drawerId: string;
    gfrIndexMapList: GFRIndexMap[];
}

export interface GFRIndexValue {
    listId: number,
    hasChildren: true,
    childListId: number,
    listEntryId: number,
    value: string,
    childIndexId: string
}

export interface GFRIndexValueMap{
    fieldText:string;
    fieldKey:string;
}

export interface GFRIndexValueMapCust{
    [key: string]: GFRIndexValueMap[] | undefined; 
}


export interface ResponseGFRSettings {
    type: actionTypes.RESPONSE_GFR_SETTINGS;
    data: GFRSettings;
}

export interface SaveGFRCredentilas {
    type: actionTypes.SAVE_GFR_CREDENTIALS;
    data: GFRCredentialsSaveResponse;
}

export interface DeleteGFRCredentilas {
    type: actionTypes.DISCONNECT_GFR;
}


export interface ResponseGFRDrawers {
    type: actionTypes.RESPONSE_GFR_DRAWERS;
    data: GFRDrawers[];
}

export interface ResponseGFRDrawerIndexes {
    type: actionTypes.RESPONSE_GFR_DRAWER_INDEXES;
    data: GFRIndex[];
}

export interface ResponseGFRIndexValues {
    type: actionTypes.RESPONSE_GFR_INDEX_VALUES;
    data: GFRIndexValue[];
}

export interface SaveGFRIndexMapping {
    type: actionTypes.SAVE_GFR_INDEX_MAPPING;
}


export type KnownAction =
    | ResponseGFRSettings
    | SaveGFRCredentilas
    | DeleteGFRCredentilas
    | ResponseGFRDrawers
    | ResponseGFRDrawerIndexes
    | ResponseGFRIndexValues
    | SaveGFRIndexMapping
    | NotificationAction
    | OverlayLoaderAction;