import React from 'react';
import moment from 'moment';
import './UsageReportTable.style.scss';
import {
	ICompanyLedgerModelV2,
	ISortColumn,
	IUsageReport,
	reportColumns,
	ssrReportColumns,
} from '../UsageReport.model';
import { getKeyByValue } from '../../../helper/HelperFunctions';
import { usageReportResources } from '../../../helper/rbac-constants';

interface IUsageReportTableProps {
	usageReport: IUsageReport;
	initialLoad: boolean;
	onRunReport(): void;
	sortColumn: ISortColumn;
	setSortColumn(sortColumn: ISortColumn): void;
}

const UsageReportTable: React.FC<IUsageReportTableProps> = (props) => {
	const {
		usageReport: { isLoading, usageReport },
		initialLoad,
		onRunReport,
		sortColumn,
		setSortColumn,
	} = props;

	const getColumns = () => {
		let columns;
		if (usageReport.every((data) => data.adjustments === undefined || data.deletedAfterDelivery === undefined || data.recalled === undefined || data.reupload === undefined)) {
			columns = reportColumns;
		} else {
			columns = ssrReportColumns;
		}
		return Object.values(columns);
	};

	const handleSort = (text: string) => {
		const newSortColumn = { ...sortColumn };
		const sortingColumn = getKeyByValue(ssrReportColumns, text);

		switch (text) {
			case ssrReportColumns.usageDate:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case ssrReportColumns.delivered:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case ssrReportColumns.deletedAfterDelivery:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case ssrReportColumns.adjustments:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;

			case ssrReportColumns.totalUsage:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;
			case ssrReportColumns.recalled:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;
			case ssrReportColumns.reupload:
				if (newSortColumn.sortBy === sortingColumn) {
					newSortColumn.sortOrder = newSortColumn.sortOrder === 'desc' ? 'asc' : 'desc';
				} else {
					newSortColumn.sortBy = sortingColumn;
					newSortColumn.sortOrder = 'desc';
				}
				setSortColumn(newSortColumn);
				break;
			default:
				break;
		}
	};

	const sortClass = (sortBy: string, columnName: string) => {
		const sortString = getKeyByValue(ssrReportColumns, columnName);
		if (sortBy === sortString && columnName === ssrReportColumns.usageDate) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === sortString && columnName === ssrReportColumns.delivered) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === sortString && columnName === ssrReportColumns.adjustments) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === sortString && columnName === ssrReportColumns.deletedAfterDelivery) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === sortString && columnName === ssrReportColumns.totalUsage) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === sortString && columnName === ssrReportColumns.recalled) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc';
		} else if (sortBy === sortString && columnName === ssrReportColumns.reupload) {
			return sortColumn.sortOrder === 'asc' ? 'fa-sort-asc' : 'fa-sort-desc'; 
		} else {
			return 'fa-sort';
		}
	};

	return (
		<table className='table table-striped table-hover usageReportTable'>
			<thead className='table-header'>
				<tr>
					{getColumns().map((column: string, index: number) => {
						if (column == ssrReportColumns.deletedAfterDelivery) {
							return( <th
								className={'W210 clickableColumns'} 
								scope='col'
								key={index}
								onClick={() => handleSort(column)}>
								{column} <i className={`fa fa-fw ${sortClass(sortColumn.sortBy, column)} sortIcon`}></i>
							</th>
							)
						}
						if (column == ssrReportColumns.reupload) {
							return (<th
								className={'W210 clickableColumns'}
								scope='col'
								key={index}
								onClick={() => handleSort(column)}>
								<span title="Reprocess/Upload Corrected Return after recall">
									{column}
								</span> <i className={`fa fa-fw ${sortClass(sortColumn.sortBy, column)} sortIcon`}></i>
							</th>
							)
						}
						return (														
							<th
								className={'widthAuto clickableColumns'}
								scope='col'
								key={index}
								onClick={() => handleSort(column)}>
								{column} <i className={`fa fa-fw ${sortClass(sortColumn.sortBy, column)} sortIcon`}></i>
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{initialLoad ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={getColumns().length}
							className='usageReportInitialLoad'>
							<div className='loadingArea'>
								<button
									data-test-auto='f33e066d-4fb5-4071-a7cf-a8815144daa2'
									className='runReportBtn'
									onClick={onRunReport}
									data-resource-id={usageReportResources.Prefix + usageReportResources.RunReport}>
									Run Report
								</button>
								<div className='infoText'>Click to generate your report</div>
							</div>
						</td>
					</tr>
				) : isLoading ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={getColumns().length}
							className='usageReportLoadingArea'>
							<div className='text-center'>
								<div
									className='spinner-border'
									role='status'></div>
								<div>
									<span className='usageReportLoadingText'>Loading...</span>
								</div>
							</div>
						</td>
					</tr>
				) : usageReport.length === 0 ? (
					<tr>
						<td
							data-toggle='collapse'
							colSpan={getColumns().length}
							className='usageReportLoadingArea text-center'>
							No matching records found
						</td>
					</tr>
				) : (
					usageReport.map((data: ICompanyLedgerModelV2, index: number) => {
						return (
							<tr key={index}>
								<td>{moment(data.usageDate).format('MM/DD/YYYY')}</td>
								<td>{data.delivered}</td>
								{data.deletedAfterDelivery === undefined ? <></> : <td>{data.deletedAfterDelivery}</td>}
								{data.recalled === undefined ? <></> : <td>{data.recalled}</td>}
								{data.reupload === undefined ? <></> : <td>{data.reupload}</td>}
								{data.adjustments === undefined ? <></> : <td>{data.adjustments}</td>} 
								<td>{data.totalUsage}</td>
							</tr>
						);
					})
				)}
			</tbody>
		</table>
	);
};

export default UsageReportTable;
