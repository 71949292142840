import { Component, createRef, RefObject } from 'react';
import styles from './GFR.module.scss';
import { Button } from 'react-bootstrap';
import SuiteModal from 'src/components/common/SuiteModal';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import Settings from 'src/components/svg/Settings';
import GFRLogin from './Login/Login';
import { Credentials, GFRIndexMapCustomization, GFRSettingsState } from './GFR.model';
import DisconnectModal from '../CCH/DisconnectModal';
import MapToGoFileRoom from './IndexMapping/IndexMapping';

interface GFRSettingsProps {
    gfrSettingsState: GFRSettingsState;
    gfrStore: GFRStoreActions;
}

interface GFRStoreActions {
    getGFRSettings: () => void;
    saveGfrCredentials: (credentials: Credentials, callback?: (isSuccess: boolean, error?: string) => void) => void;
    disconnectGFR: (callback?: () => void) => void;
    getGFRDrawers: () => void;
    getGFRDrawerIndexes: (drawerID:string) => void;
    saveIndexMapping: (indexMapping: GFRIndexMapCustomization, callback?: (isSuccess: boolean, error?: string) => void) => void;
    getGFRIndexeValues: (indexId: string) => void;
    showValidation: (message: string) => void;
}


interface State {
    showLoginPopup: boolean;
    showDisconnectAlert: boolean;
    showLoginValidation: boolean;
    alertMessage: string;
    showMapToGoFileRoom: boolean;
}

const disconnectMessage = `By disconnecting from GoFileRoom integration you will no longer be able to send documents to GoFileRoom from SafeSend. This will affect all users within your SafeSend ONE account. Your login to the GoFileRoom application will not be impacted. Do you want to disconnect?`;

class GFRSettings extends Component<GFRSettingsProps, State> {

    private usernameRef: RefObject<HTMLInputElement>;
    private alertMsg: string = 'Please Enter valid Credentials';

    constructor(props: GFRSettingsProps) {
        super(props);
        this.state = {
            showLoginPopup: false,
            showDisconnectAlert: false,
            showLoginValidation: false,
            alertMessage: this.alertMsg,
            showMapToGoFileRoom: false

        };
        this.usernameRef = createRef<HTMLInputElement>();

        this.onLoginClick = this.onLoginClick.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
        this.onDisconnectCancel = this.onDisconnectCancel.bind(this);
        this.onDisconnectConfirm = this.onDisconnectConfirm.bind(this);
        this.closeLoginPopup = this.closeLoginPopup.bind(this);
        this.removeCredentials = this.removeCredentials.bind(this);

    }

    onLoginClick = () => {
        this.setState({ ...this.state, showLoginPopup: true });
    }

    onDisconnect = () => {
        this.setState({ ...this.state, showDisconnectAlert: true });
    }

    onDisconnectCancel = () => {
        this.setState({ ...this.state, showDisconnectAlert: false });
    }

    onDisconnectConfirm = () => {
        this.removeCredentials();
        this.setState({ ...this.state, showDisconnectAlert: false });
    }

    closeLoginPopup = () => {
        this.setState({ ...this.state, showLoginPopup: false });
    }

    removeCredentials() {
        this.props.gfrStore.disconnectGFR();
    }

    onMapToGoFileRoomSettingsClick = () => {
        this.setState({ ...this.state, showMapToGoFileRoom: true });
    }

    onMapToGoFileRoomClose = () => {
        this.setState({ ...this.state, showMapToGoFileRoom: false });
    }

    render() {


        var loginInfoText = this.props.gfrSettingsState.gfrSettings.isConnected
            ? <span>
                Log in with a GoFileRoom account to send returns directly from SafeSend to GoFileRoom. <br />
                Only one login can be used at a time.</span>
            : <span>Login with your GoFileRoom user name and password to send returns to GoFileRoom. </span>;


        var disconnectTemplate = <div style={{ fontSize: '0.94rem' }}> {disconnectMessage} </div>

        return (
            <article className='suitePageSection'>
                <h3 className='suitePageSectionHeader'>GoFileRoom Integration</h3>
                <h3 className='suitePageSectionSubHeader'>Login</h3>
                <p className='suitePageSectionDescription'>
                    {loginInfoText}
                </p>
                <div className={styles.action_button_wrapper}>
                    {this.props.gfrSettingsState.gfrSettings.isConnected ? (
                        <div className={styles.connectionstatus}>
                            <div>
                                <span>Logged in as </span>
                                <span className={styles.connected}>{this.props.gfrSettingsState.gfrSettings.username}</span>
                            </div>
                            <div className={styles.status_container}>
                                <Button
                                    data-test-auto='49aa4626-e4df-4f68-b9de-352bff00304e'
                                    variant='secondary'
                                    className={styles.disconnect}
                                    onClick={this.onDisconnect}>
                                    Disconnect
                                </Button>

                                <div className={styles.status_indicator}>
                                    <span className={styles.status_connected_dot}></span>
                                    <span className={styles.status_text}>Connected</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.status_container}>

                            <ButtonWithTextIcon
                                className={styles.action_button}
                                label='Login to GFR'
                                onClick={this.onLoginClick}
                            />

                            <div className={styles.status_indicator}>
                                <span className={styles.status_disconnected_dot}></span>
                                <span className={styles.status_text}>Not Connected</span>
                            </div>
                        </div>

                    )}
                </div>

                {
                    this.props.gfrSettingsState.gfrSettings.isConnected &&
                    <div>
                        <h3 className='suitePageSectionSubHeader'>Manage GoFileRoom</h3>
                        <p className='suitePageSectionDescription'>
                            Assign a drawer and indexes we will send signed returns too..
                        </p>
                        <div className='switchSettingsBlock form-switch'>
                            <span >Map Account</span>
                            <div style={{ paddingLeft: '1rem', marginBottom: '6px' }} onClick={this.onMapToGoFileRoomSettingsClick}>
                                <Settings />
                            </div>
                        </div>
                    </div>
                }

                {this.state.showDisconnectAlert && (
                    <SuiteModal
                        width='400'
                        theme='light'
                        title='Disconnect from GoFileRoom'
                        hide={this.onDisconnectCancel}>
                        <DisconnectModal
                            onDisconnectAlertYes={this.onDisconnectConfirm}
                            onDisconnectAlertNo={this.onDisconnectCancel}
                            message={disconnectMessage}
                            msgTemplate={disconnectTemplate}
                        />
                    </SuiteModal>
                )}

                <GFRLogin
                    show={this.state.showLoginPopup}
                    onClose={this.closeLoginPopup}
                    onSubmit={this.props.gfrStore.saveGfrCredentials}
                />

                {this.state.showMapToGoFileRoom && <MapToGoFileRoom
                    handleClose={this.onMapToGoFileRoomClose}
                    getGFRDrawers={this.props.gfrStore.getGFRDrawers}
                    getGFRDrawerIndexes={this.props.gfrStore.getGFRDrawerIndexes}
                    saveIndexMapping={this.props.gfrStore.saveIndexMapping}
                    getGFRIndexeValues={this.props.gfrStore.getGFRIndexeValues}
                    getGFRSettings={this.props.gfrStore.getGFRSettings}
                    gfrSettingsState={this.props.gfrSettingsState}
                    showValidation={this.props.gfrStore.showValidation}
                />}
            </article>
        );
    }
}

export default GFRSettings;