import { FC } from 'react';
import { MessageNoteInfoIcon } from '../../svg/message-note-info-icon';
import './message-note.scss';
import { MessageNoteWarningIcon } from '../../svg/message-note-warning-icon';

export interface MessageNoteProps {
	noteType: MessageNoteType;
	note: string;
	height?: number;
}

export enum MessageNoteType {
	Warning = 'warning',
	Info = 'info',
}

const MessageNote: FC<MessageNoteProps> = (props) => {
	return (
		<div
			className='message_note'
			style={{ minHeight: props.height && props.height > 0 ? props.height : 63, height: 'auto' }}>
			<div className='icon'>
				{props.noteType === MessageNoteType.Warning ? (
					<MessageNoteWarningIcon />
				) : props.noteType === MessageNoteType.Info ? (
					<MessageNoteInfoIcon />
				) : (
					''
				)}
			</div>
			<div
				className='text'
				dangerouslySetInnerHTML={{ __html: props.note }}></div>
		</div>
	);
};

export default MessageNote;
