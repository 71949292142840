import { Component } from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import styles from './consent.module.scss';

interface ConsentModalProps {

    show: boolean;
    title: string;
    message: string;
    onAccept: () => void;
    onDecline: () => void;
}

class Consent extends Component<ConsentModalProps> {
    constructor(props: ConsentModalProps) {
        super(props);
    }

    render() {
        const { show, message, onAccept, onDecline, title } = this.props;
        return (
            <div>
                <Modal show={show} onHide={onDecline} backdropClassName={styles.consent_backdrop} className={styles.modal_z_index} >

                    <Modal.Header className='lighttheme'>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <h6 className="consentmsg">{message} </h6>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className={styles.consentdiv}>
                            <button type="submit" className="btn btn-secondary" onClick={onDecline}>Decline</button>
                            <button type="submit" className="btn btn-primary" onClick={onAccept}>Accept</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Consent;