export interface SvgIconProps {
	width?: number;
	height?: number;
	className?: string;
	onClick?: any;
	primaryColor?: string;
	secondaryColor?: string;
	fillColor?: string;
}

export const MessageNoteWarningIcon: React.FC<SvgIconProps> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width || 16}
			height={props.height || 16}
			className={props.className}
			onClick={props.onClick}
			viewBox='0 0 16 16'
			fill='none'>
			<g clip-path='url(#clip0_1789_1081)'>
				<path
					d='M8.9817 1.56522C8.88271 1.39278 8.73998 1.24952 8.56791 1.14989C8.39584 1.05026 8.20053 0.997803 8.0017 0.997803C7.80287 0.997803 7.60756 1.05026 7.43549 1.14989C7.26343 1.24952 7.12069 1.39278 7.0217 1.56522L0.164702 13.2322C-0.292298 14.0102 0.255702 14.9992 1.1447 14.9992H14.8577C15.7467 14.9992 16.2957 14.0092 15.8377 13.2322L8.9817 1.56522ZM7.9997 4.99922C8.5347 4.99922 8.9537 5.46122 8.8997 5.99422L8.5497 9.50122C8.53794 9.63899 8.4749 9.76733 8.37306 9.86086C8.27121 9.95438 8.13797 10.0063 7.9997 10.0063C7.86143 10.0063 7.72819 9.95438 7.62635 9.86086C7.5245 9.76733 7.46146 9.63899 7.4497 9.50122L7.0997 5.99422C7.08713 5.86845 7.10105 5.74145 7.14055 5.62139C7.18005 5.50132 7.24426 5.39087 7.32905 5.29713C7.41383 5.20339 7.51731 5.12846 7.63282 5.07715C7.74833 5.02584 7.87331 4.99929 7.9997 4.99922ZM8.0017 10.9992C8.26692 10.9992 8.52127 11.1046 8.70881 11.2921C8.89634 11.4796 9.0017 11.734 9.0017 11.9992C9.0017 12.2644 8.89634 12.5188 8.70881 12.7063C8.52127 12.8939 8.26692 12.9992 8.0017 12.9992C7.73649 12.9992 7.48213 12.8939 7.2946 12.7063C7.10706 12.5188 7.0017 12.2644 7.0017 11.9992C7.0017 11.734 7.10706 11.4796 7.2946 11.2921C7.48213 11.1046 7.73649 10.9992 8.0017 10.9992Z'
					fill='#0973BA'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1789_1081'>
					<rect
						width='16'
						height='16'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
