import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUserPasswordResetRequest, approveUserPasswordResetRequest } from "./ApprovePassword.api";
import WidgetIcon from "../../components/svg/WidgetIcon";
import styles from "./ApprovePasword.module.scss";
import { IApprovePassUserData } from "./ApprovePassword.model";
import { logger } from '../../oidcClient/authProvider';

const ApprovePassword: React.FC = () => {
    const [searchParams] = useSearchParams();
    const param = searchParams.get('param');

    const [requestData, setRequestData] = useState<IApprovePassUserData>();

    useEffect(() => {
        if (param) {
            getUserPasswordResetRequest(param, (result: IApprovePassUserData) => { setRequestData(result) })
        }
    }, [])

    const approvePasswordClick = () => {
        if (requestData && requestData.user && !requestData.isApproved) {
            logger && logger.trackTrace('approvePasswordClick: Approve Password button clicked');
            approveUserPasswordResetRequest(requestData.user.emailAddress, (result: boolean) => {
                requestData.isApproved = result;
                setRequestData({ ...requestData });
            })
        }
    };

    return (

        <div className={styles.approvePasswordDiv} >
            <h3 className={styles.approvePasswordHeading}>
            Password Reset Approval Request
            </h3>
            <p className={styles.approvePasswordBody}>

                {requestData && requestData.user && (requestData.user.firstName + ' ' + requestData.user.lastName)} is requesting to reset their SafeSend One password. Please<br />
                confirm the request by clicking <span>"Approve"</span> if the request is coming<br />
                from a valid User. Once the request is approved, you can use the<br />
                <WidgetIcon /> menu to navigate away from this page.
            </p>
            <div className={styles.approvePasswordFooter} >
                {requestData && requestData.isApproved ?
                    <button
                        disabled={true}
                        data-test-auto="c4ab3cc5-ceb0-427f-8c59-beea85251ef4"
                        className={styles.alreadyApproved} >
                            Already Approved
                        </button>
                    :
                    <button
                        disabled={!requestData}
                        onClick={approvePasswordClick}
                        data-test-auto="c4ab3cc5-ceb0-427f-8c59-beea85251ef4"
                        className={!requestData ? styles.approveDisabled :styles.approved}>
                            Approve
                        </button>
                }
            </div>
        </div>
    )
};
export default ApprovePassword;