import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OverlayLoader from 'src/components/common/OverlayLoader';
import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';
import ProductDownloads from 'src/modules/generalSettings/ProductDownloads';
import SMSNotifications from 'src/modules/generalSettings/SMSNotifications';
import { TaxSoftwareTypes } from 'src/modules/generalSettings/TaxSoftwares/index';
import { ClientManagement } from 'src/modules/generalSettings/ClientManagement/index';
import * as TaxSoftwareSettingStore from 'src/modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.store';
import {
	ITaxSoftwareSetting,
	TaxSoftwareType,
} from '../../modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.model';
import { ICompanyProfile } from '../common/companySettings/companySettings.types';
import { IOverlayLoader } from '../common/overlayLoader/OverlayLoader.model';
import styles from './index.module.scss';
import ApiAccessSetting from '../../modules/generalSettings/ApiAccess';
import * as ClientManagementSettingStore from 'src/modules/generalSettings/ClientManagement/ClientManagementSetting.store';
import * as UTESettingStore from '@modules/generalSettings/ClientHub/ClientHub.store';
import * as OverlayLoaderStore from '../common/overlayLoader/OverlayLoader.store';
import * as NotificationStore from '../common/notification/notification.reducer';
import { logger } from '../../oidcClient/authProvider';
import ClientHub from '../../modules/generalSettings/ClientHub';
import { CCHSetting } from 'src/modules/generalSettings/CCH/index';
import { CCHSettingState } from '../../modules/generalSettings/CCH/CCHSetting.model';
import * as CCHSettingsStore from '../../modules/generalSettings/CCH/CCHSettings.store';
import { ApplicationState } from '../../store';
import GFRSettings from 'src/modules/generalSettings/GFR';
import * as GFRStore from '../../modules/generalSettings/GFR/GFR.store';
import { GFRSettingsState } from '../../modules/generalSettings/GFR/GFR.model';
import { get } from 'superagent';


export type GeneralSettingsProps = {
	companyProfile: ICompanyProfile;
	overlayLoader: IOverlayLoader;
	requestTaxSoftwareSetting: () => void;
	updateTaxSoftwareSetting: (taxSoftware: ITaxSoftwareSetting, resourceId: string, callback: () => void) => void;
	cchSettings: CCHSettingState,
	gfrSettingsState: GFRSettingsState,
} & typeof TaxSoftwareSettingStore.actionCreators &
	typeof ClientManagementSettingStore.actionCreators &
	typeof UTESettingStore.actionCreators &
	typeof OverlayLoaderStore.actionCreators &
	typeof NotificationStore.actionCreators & 
	typeof CCHSettingsStore.actionCreators &
	typeof GFRStore.actionCreators;

const GeneralSettings: FC<GeneralSettingsProps> = (props) => {
	const taxSoftwares = useSelector((state: ApplicationState) => state.taxSoftwareSetting);
	const [isCchSelected, setCchSelected] = useState<boolean>(false);
	const breadCrumbConfig = [
		{
			name: 'SafeSend One',
			activePage: false,
			link: '/',
		},
		{
			name: 'General',
			activePage: true,
			link: '',
		},
	];

	const dispatch = useDispatch();

	const bindCCH = (isSelected: boolean): void => {
		setCchSelected(isSelected);
	};

	useEffect(() => {
		logger && logger.trackPageView('Navigated to General Settings');
		props.startLoader('Loading...');
		props.getGFRSettings();
	}, []);

	var gfrStore = {
		getGFRSettings : props.getGFRSettings,
		saveGfrCredentials : props.saveGfrCredentials,
		disconnectGFR : props.disconnectGFR,
		getGFRDrawers : props.getGFRDrawers,
		getGFRDrawerIndexes : props.getGFRDrawerIndexes,
		saveIndexMapping: props.saveIndexMapping,
		getGFRIndexeValues : props.getGFRIndexeValues,
		showValidation : props.showValidation
	};
	return (
		
		<div className='suitePageContent'>
			<section className='suitePageInnerContent'>
				<div className={styles.securitySettingsFixedHeader}>
					<BreadCrumbNav config={breadCrumbConfig} />
					<h1 className='suitePageHeading'>General</h1>
				</div>
				<OverlayLoader
					show={props.overlayLoader.show}
					text={props.overlayLoader.text}
				/>
				<section className={styles.mainSection}>
					<ProductDownloads companyProfile={props.companyProfile} />
				</section>
				<hr className={styles.horizontal_divider} />
				<section className={styles.mainSection}>
					<ApiAccessSetting />
				</section>
				<hr className={styles.horizontal_divider} />
				<section className={styles.mainSection}>
					<SMSNotifications />
				</section>
				<hr className={styles.horizontal_divider} />
				<section className={styles.clientHub}>
					<ClientHub
						startLoader={props.startLoader}
						stopLoader={props.stopLoader}
						notification={props.notification}
						requestUTESetting={props.requestUTESetting}
						updateUTESetting={props.updateUTESetting}
						updateRetentionPolicy={props.updateRetentionPolicy}
						requestUTEContactPerson={props.requestUTEContactPerson}
						addUTEContactPerson={props.addUTEContactPerson}
						updateUTEContactPerson={props.updateUTEContactPerson}
					/>
				</section>
				<hr className={styles.horizontal_divider} />
				<ClientManagement
					requestClientManagementSetting={props.requestClientManagementSetting}
					updateClientManagementSetting={props.updateClientManagementSetting}
					isCCHSelected={isCchSelected}
				/>
				<hr className={styles.horizontal_divider} />
				<section className={styles.mainSection}>
					<TaxSoftwareTypes
						taxSoftwareSetting={taxSoftwares}
						requestTaxSoftwareSetting={props.requestTaxSoftwareSetting}
						updateTaxSoftwareSetting={props.updateTaxSoftwareSetting}
						bindCCH={bindCCH}
					/>
				</section> 
				{ taxSoftwares.taxSoftware.find((type) => type === TaxSoftwareType.ProSystems) &&  
					<>
						<hr className={styles.horizontal_divider} />
						<section className={styles.mainSection}>
							<CCHSetting
								updateEfileSetting={props.updateEfileSetting}
								getEFileSettings={props.getEFileSettings}
								cchSettings={props.cchSettings}
								onDisconnect={props.disconnect}
								getAccessToken={props.acessToken}
								getConnectionStatus={props.getConnectionStatus}
								getOAuthDetails={props.getOAuthDetails}
							/>
						</section>
					</>
				}
				{ props.gfrSettingsState.gfrSettings.isGFREnabled && 
				 < >
					<hr className={styles.horizontal_divider} />
					<section className={styles.mainSection}>
						<GFRSettings
							gfrStore={gfrStore}
							gfrSettingsState={props.gfrSettingsState}
						/>
					</section> 	
				  </>	
				}		
			</section>
		</div>
	);
};

export default GeneralSettings;
