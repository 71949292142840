import React, { FC } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import './Disconnect.style.scss';

interface DisconnectModalProps {
	onDisconnectAlertNo: () => void;
	onDisconnectAlertYes: () => void;
	message: string;
	msgTemplate?: React.ReactNode;
}
const DisconnectModal: FC<DisconnectModalProps> = (props) => {
	
	var disconnectMessage = props.msgTemplate ? props.msgTemplate : <div> {props.message} <br /><br />	Do you wish to continue? </div>;
	
	return (
		<>
			<Modal.Body className='show-grid'>
				<Container>
					<div className='disableMessage'>
						{disconnectMessage}
					</div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='45ba105b-d383-4bdd-87e5-a6d3bdc085cf'
					variant='secondary'
					onClick={props.onDisconnectAlertNo}>
					Cancel
				</Button>
				<Button
					data-test-auto='c6568a96-e92e-4d53-afc9-314ffc474bf8'
					variant='danger'
					onClick={props.onDisconnectAlertYes}>
					Disconnect
				</Button>
			</Modal.Footer>
		</>
	);
};

export default DisconnectModal;
