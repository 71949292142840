import { type FC } from 'react';
import OverlayLoader from 'src/components/common/OverlayLoader';
import BreadCrumbNav from 'src/components/userManagement/BreadCrumbNav';
import CompanyDetails from 'src/modules/company-management/company-details';
import PrimaryAdmin from 'src/modules/company-management/primary-admin';
import SubscribedProducts from 'src/modules/company-management/subscribed-products';
import { EditCompany } from '../../modules/company-management/edit-company';

import useCompanyManagement from './company-management.hooks';
import { type ICompanyManagementProps } from './company-management.types';
import styles from './index.module.scss';

const CompanyManagement: FC<ICompanyManagementProps> = (props) => {
	const { breadCrumbConfig, companyDetailsProps, primaryAdminProps, companyTierDetailsProps, editCompanyProps } =
		useCompanyManagement(props);

	const isLoading = 
		props.companyData.isLoading ||
		props.companyManagementData.isUserLoading ||
		props.companyManagementData.isProductUpdating ||
		props.companyManagementData.isCompanyListLoading ||
		props.companyManagementData.isProductLoading;
		// props.companyManagementData.isAddOnFeaturesLoading ||
		// props.companyManagementData.isAddOnFeaturesUpdating;

	return (
		<div className='suitePageContent'>
			<div className='suitePageInnerContent'>
				<BreadCrumbNav config={breadCrumbConfig} />
				<div className='suitePageHeading'>
					<h1 className='suitePageHeading'>My Company</h1>
				</div>
				<OverlayLoader
					show={isLoading}
					text={'Loading...'}
				/>
				<div className='suitePageSection'>
					<div>
						<EditCompany {...editCompanyProps} />
					</div>
					<div>
						<CompanyDetails {...companyDetailsProps} />
					</div>
					<div>
						<PrimaryAdmin {...primaryAdminProps} />
					</div>
					<hr className={styles.horizontal_divider} />
					<div>
						<SubscribedProducts {...companyTierDetailsProps} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyManagement;
