import React, { FC, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LoadingOverlay } from 'react-overlay-loader';
import {
	ApplicationType,
	DeliveryMode,
	IBillingDetailData,
	IPaymentDetails,
	IPaymentSetUpInfo,
	IPaymentViewModel,
	IPurchaseState,
	PaymentGateway,
	PaymentState,
	PaymentType,
} from '../PurchaseReturn.model';
import { ICompanySubscription } from 'src/pages/common/companySettings/companySettings.types';
import { ISaleTaxItem, ISaleTaxSetting, ITaxInfo, TaxCodeType, initSaleTaxData } from '../Tax.model';
import { loadStripe, StripeConstructorOptions, RedirectToCheckoutOptions } from '@stripe/stripe-js';
import { ProductType } from 'src/helper/Constants';
import { getSalesTaxDetails } from '../Tax.apis';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { PurchaseReturnConstants } from 'src/helper/Constants';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { useDispatch } from 'react-redux';
import { PaymentFlowType } from '../PaymentHistory.model';
import PurchaseReturnBody from '../PurchaseReturnBody';
import OverlayLoader from 'src/components/common/OverlayLoader';
import './PurchaseReturnModal.style.scss';
import { logger } from 'src/oidcClient/authProvider';

interface PurchaseReturnModalProps {
	purchaseDetails: IBillingDetailData;
	companySubscription?: ICompanySubscription;
	paymentDetails: IPaymentDetails;
	requestBillingDetail(): void;
	savePurchase(purchaseViewModel: IPaymentViewModel, callback?: (data: any, onError: boolean) => void): void;
	currentPage: string;
	getPaymentSetUpToken(paymentSetUpInfo: IPaymentSetUpInfo, callback?: (data: any, onError: boolean) => void): void;
	setShowPurchaseReturnModal: (showPurchaseReturnModal: boolean) => void;
	saleTaxSetting: ISaleTaxSetting;
}
const PurchaseReturnModal: FC<PurchaseReturnModalProps> = (props) => {
	const [isPurchaseTab, setIsPurchaseTab] = useState(true);
	const [purchaseState, setPurchaseState] = useState<IPurchaseState>({
		ssrPurchaseState: {
			ssrOutStandingBalance: 0,
			ssrReturnQuantity: 0,
			ssrPricePerReturn: 0,
			ssrTotalAmount: 0,
			currentBalance: 0,
			ssrReturnQuantityTemp: 0,
			ssrTotalAmountTemp: 0,
			currentPricePerReturn: 0,
			minDiscountQuntity: 0,
		},
		saving: false,
	});
	const [ssrItemAdded, setSSRItemAdded] = useState(false);
	const [isPurchaseClicked, setIsPurchaseClicked] = useState(false);
	const [isPurchaseValid, setIsPurchaseValid] = useState(false);
	const [taxInfo, setTaxInfo] = useState<ITaxInfo>({
		totalTax: 0,
		baseTaxLineInfo: [],
	});
	const [saleData, setSaleData] = useState<ISaleTaxItem>(initSaleTaxData);
	const [loadingMessage, setLoadingMessage] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		updatePurchaseValid();
	}, [ssrItemAdded]);

	useEffect(() => {
		let purchase = structuredClone(purchaseState);
		if (props.purchaseDetails && Object.keys(props.purchaseDetails).length !== 0) {
			let outStandingBalance =
				props.purchaseDetails.remainingReturns < 0 ? Math.abs(props.purchaseDetails.remainingReturns) : 0;
			let tempprice = props.companySubscription
				? props.companySubscription.products
						.find((x) => x.productType === ProductType.Returns)
						?.pricingInfos.find((y) => y.minQuantity <= outStandingBalance && y.maxQuantity >= outStandingBalance)?.price
				: 0;

			if (tempprice && tempprice > 0) {
				purchase.ssrPurchaseState.ssrPricePerReturn = tempprice;
				purchase.ssrPurchaseState.currentPricePerReturn =
					purchase.ssrPurchaseState.currentPricePerReturn === 0
						? tempprice
						: purchase.ssrPurchaseState.currentPricePerReturn;
				purchase.ssrPurchaseState.ssrOutStandingBalance =
					props.purchaseDetails.remainingReturns < 0 ? Math.abs(props.purchaseDetails.remainingReturns) : 0;
				purchase.ssrPurchaseState.currentBalance = props.purchaseDetails.remainingReturns;
				purchase.ssrPurchaseState.ssrTotalAmount =
					(purchase.ssrPurchaseState.ssrOutStandingBalance + purchase.ssrPurchaseState.ssrReturnQuantity) *
					purchase.ssrPurchaseState.ssrPricePerReturn;
				purchase.ssrPurchaseState.ssrTotalAmountTemp =
					(purchase.ssrPurchaseState.ssrOutStandingBalance + purchase.ssrPurchaseState.ssrReturnQuantity) *
					purchase.ssrPurchaseState.ssrPricePerReturn;
				setPurchaseState(purchase);
			}
		}
	}, [props]);

	const onCancel = () => {
		props.setShowPurchaseReturnModal(false);
	};

	const onPurchaseTabSelect = (value: boolean) => {
		setIsPurchaseTab(value);
	};

	const prepareSalesData = (): ISaleTaxItem => {
		let data: ISaleTaxItem = initSaleTaxData;
		let ssr = props.saleTaxSetting?.taxCodes?.filter((x) => x.taxCodeType == TaxCodeType.SSR)[0];
		let ssrCode = ssr ? (ssr.taxCode ? ssr.taxCode : '') : '';
		let ssrItemCode = ssr.itemCode;

		if (
			ssrItemAdded &&
			purchaseState.ssrPurchaseState.ssrOutStandingBalance + purchaseState.ssrPurchaseState.ssrReturnQuantity > 0
		) {
			data = {
				price: purchaseState.ssrPurchaseState.ssrPricePerReturn,
				quantity:
					purchaseState.ssrPurchaseState.ssrOutStandingBalance + purchaseState.ssrPurchaseState.ssrReturnQuantity,
				productNumber: TaxCodeType.SSR.toString(),
				taxIncluded: false,
				taxCode: ssrCode,
				companyCode: props.saleTaxSetting.companyCode,
				itemCode: ssrItemCode ? ssrItemCode : '',
			};
		}
		return data;
	};

	const onNextClick = (e: React.BaseSyntheticEvent) => {
		if (validatePurchase()) {
			var newSaleData = prepareSalesData();
			setSaleData(newSaleData);
			setLoadingMessage('');
			setIsPurchaseClicked(true);
			getSalesTaxDetails(newSaleData)
				.then((taxInfo: ITaxInfo) => {
					setTaxInfo(taxInfo);
					setIsPurchaseClicked(false);
					onPurchaseTabSelect(false);
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while onNextClick', { 'Error': error?.response?.data});
					dispatch({
						type: notificationTypes.NOTIFICATION,
						statusMessage: PurchaseReturnConstants.FailurefetchSaleTaxDetails,
						statusType: StatusType.Error,
					});
				});
		} else {
			e?.preventDefault();
		}
	};

	const updatePurchaseValid = () => {
		setIsPurchaseValid(isItemsValidInCart());
	};

	const isItemsValidInCart = () => {
		if (!ssrItemAdded) {
			return false;
		}
		if (
			ssrItemAdded &&
			purchaseState.ssrPurchaseState.ssrReturnQuantity + purchaseState.ssrPurchaseState.ssrOutStandingBalance < 1
		) {
			return false;
		}
		return true;
	};

	const proceedtoStripe = (data: IPaymentDetails, onError: boolean) => {
		if (!onError) {
			if (data && data.payemtSessionId && data.payemtSessionId != '') {
				let paymentDetails = data;
				let redirectToCheckoutOptions: RedirectToCheckoutOptions = {
					sessionId: paymentDetails.payemtSessionId,
				};
				if (paymentDetails.publicApiKey && paymentDetails.publicApiKey != '') {
					const stripePromise = loadStripe(paymentDetails.publicApiKey);
					stripePromise
						.then((stripePay: any) => {
							if (stripePay)
								stripePay
									.redirectToCheckout(redirectToCheckoutOptions)
									.then(() => {})
									.catch((error: any) => {
										logger && logger.trackWarning('Error while proceedtoStripe redirectToCheckout', { 'Error': error?.response?.data});
									});
						})
						.catch((error: any) => {
							logger && logger.trackWarning('Error while proceedtoStripe', { 'Error': error?.response?.data});
						});
				}
			}
		} else {
			setIsPurchaseClicked(false);
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Error,
				statusMessage: PurchaseReturnConstants.FailureSavePurchase,
			});
		}
	};

	const onSaveClick = (e: any) => {
		if (!validatePurchase()) {
			return;
		}
        logger && logger.trackTrace('onSaveClick: Proceeding to stripe for payment');
		let currentPage = '/' + props.currentPage;
		let paymentSuccessURL = 'api/returns/payment-operation/status-update/success-payment' + currentPage;
		let paymentCancelURL = 'api/returns/payment-operation/status-update/cancel-payment' + currentPage;

		let appllicationFees = 0;
		let amountTotal = ssrItemAdded ? purchaseState.ssrPurchaseState.ssrTotalAmount : 0;
		let amountTotalWithFee = amountTotal + appllicationFees;

		let purchaseViewModel: IPaymentViewModel = {
			returnsPurchase: undefined,
			purchaseMode: 1,
			checkNumber: '',
			createdBy: '',
			emailReceiptTo: '',

			paymentSuccessPath: paymentSuccessURL,
			paymentCancelPath: paymentCancelURL,
			appllicationFees: appllicationFees,
			amountTotal: amountTotal,
			amountTotalWithFee: amountTotalWithFee,

			transactionId: '',
			paymentSessionId: '',
			paymentGatewayType: PaymentGateway.Stripe,
			originApp: ApplicationType.CPA,
			paymentStatus: PaymentState.Initialized,
			message: 'Purchase',
			details: '',
			receiptPath: '',
			paymentOption: PaymentType.Card,
			paymentFlow: PaymentFlowType.PayNow,
			taxItem: saleData,
		};

		if (ssrItemAdded) {
			purchaseViewModel.returnsPurchase = {
				numberOfDocuments:
					purchaseState.ssrPurchaseState.ssrReturnQuantity + purchaseState.ssrPurchaseState.ssrOutStandingBalance,
				pricePerDocument: purchaseState.ssrPurchaseState.ssrPricePerReturn,
				remarks: '',
				amount: purchaseState.ssrPurchaseState.ssrTotalAmount,
				deliveryMode: DeliveryMode.ElectronicFiled,
				remainingReturns: 0,
			};
		}

		setIsPurchaseClicked(true);
		setLoadingMessage("Please don't refresh the page, Redirecting to stripe...");
		props.savePurchase(purchaseViewModel, proceedtoStripe);
	};

	const validatePurchase = () => {
		if (!ssrItemAdded) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PurchaseReturnConstants.Validation.NoItemInCartValidation,
			});
			return false;
		}

		if (
			ssrItemAdded &&
			purchaseState.ssrPurchaseState.ssrReturnQuantity + purchaseState.ssrPurchaseState.ssrOutStandingBalance < 1
		) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: PurchaseReturnConstants.Validation.SafeSendReturnCountValidation,
			});
			return false;
		}
		return true;
	};

	const onSSRQuantityChange = (quantity: number) => {
		updateSSRPurchaseQuantity(quantity);
	};

	const updateSSRPurchaseQuantity = (quantity: number) => {
		let purchase = structuredClone(purchaseState);
		let limit;
		let statusMessage;
		if (isNaN(quantity)) {
			quantity = 0;
		}
		switch (props?.companySubscription?.id) {
			case 1:
				limit = 50000;
				statusMessage = PurchaseReturnConstants.PurchaseEssentialsReturnLimit;
				break;
			case 2:
				limit = 40000;
				statusMessage = PurchaseReturnConstants.PurchasePreferredReturnLimit;
				break;
			case 3:
				limit = 35000;
				statusMessage = PurchaseReturnConstants.PurchasePremiumReturnLimit;
				break;
		}
	
		if (quantity === 0 || quantity + purchase.ssrPurchaseState.ssrOutStandingBalance <= limit ) {
			purchase.ssrPurchaseState.ssrReturnQuantityTemp = quantity;

			let totalQuantity = quantity + purchase.ssrPurchaseState.ssrOutStandingBalance;
			let tempprice = props.companySubscription
				? props.companySubscription.products
						.find((x) => x.productType === ProductType.Returns)
						?.pricingInfos.find((y) => y.minQuantity <= totalQuantity && y.maxQuantity >= totalQuantity).price
				: 0;

			purchase.ssrPurchaseState.ssrPricePerReturn = tempprice;
			purchase.ssrPurchaseState.ssrReturnQuantity = quantity;
			purchase.ssrPurchaseState.ssrTotalAmountTemp =
				(purchase.ssrPurchaseState.ssrOutStandingBalance + quantity) * purchase.ssrPurchaseState.ssrPricePerReturn;
			purchase.ssrPurchaseState.ssrTotalAmount = purchase.ssrPurchaseState.ssrTotalAmountTemp;
			setPurchaseState(purchase);
		} else if (quantity + purchase.ssrPurchaseState.ssrOutStandingBalance > limit) {
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Warning,
				statusMessage: statusMessage,
			});
		}
	};

	const onSSRItemAdd = () => {
		let purchase = { ...purchaseState };
		if (purchase.ssrPurchaseState.ssrOutStandingBalance + purchase.ssrPurchaseState.ssrReturnQuantityTemp > 0) {
			let quantity = purchase.ssrPurchaseState.ssrReturnQuantityTemp;
			purchase.ssrPurchaseState.ssrReturnQuantity = quantity;
			purchase.ssrPurchaseState.ssrReturnQuantityTemp = quantity;
			purchase.ssrPurchaseState.ssrTotalAmount =
				(purchase.ssrPurchaseState.ssrOutStandingBalance + quantity) * purchase.ssrPurchaseState.ssrPricePerReturn;
			setSSRItemAdded(true);
			setPurchaseState(purchase);
		}
	};

	const onSSRItemRemove = () => {
		updateSSRPurchaseQuantity(0);
		setSSRItemAdded(false);
	};

	const onPurchaseClick = () => {
		onPurchaseTabSelect(true);
	};

	return (
		<div className='purchaseModalClass'>
			<Modal.Body>
				<ul className='nav nav-tabs nav-fill purchaseTabs'>
					<li
						className={`nav-item whiteLink ${isPurchaseTab ? 'purchase-tab-selected' : ''}`}
						id='purchase-li'
						onClick={onPurchaseClick}
						style={{ cursor: 'pointer' }}
						data-test-auto='d890c986-06c8-4b49-8270-2493eb5f0f76'>
						Purchase Information
					</li>
					<li
						className={`nav-item whiteLink ${isPurchaseTab ? '' : 'purchase-tab-selected'}`}
						id='profile-li'
						data-test-auto='550b0a13-b78d-421a-ac2f-4da4e17c4029'>
						Order Summary
					</li>
				</ul>
				<div className='purchaseReturnBody'>
					<LoadingOverlay>
						<PurchaseReturnBody
							isLoading={true}
							onTabSelect={onPurchaseTabSelect}
							isPurchaseTab={isPurchaseTab}
							purchaseData={purchaseState}
							onSsrQuantityChange={onSSRQuantityChange}
							onSSRItemAdd={onSSRItemAdd}
							onSSRItemRemove={onSSRItemRemove}
							ssrItemAdded={ssrItemAdded}
							taxInfo={taxInfo}
						/>
					</LoadingOverlay>
				</div>
			</Modal.Body>
			<Modal.Footer className='purchaseModalFooter'>
				{isPurchaseClicked ? (
					<OverlayLoader
						show={isPurchaseClicked}
						text={loadingMessage}
					/>
				) : (
					<>
						<Button
							data-test-auto='3da781b6-ebb0-4513-b550-4db3c093ebed'
							variant='secondary'
							onClick={onCancel}>
							Cancel
						</Button>
						<Button
							title='Proceed to Order Summary'
							className={isPurchaseTab ? '' : 'hidePurchaseButton'}
							disabled={!isPurchaseValid}
							hidden={!isPurchaseTab}
							onClick={onNextClick}
							data-test-auto='b542baa1-386f-4221-b264-e2b61f1335b3'
							variant='primary'>
							Next
						</Button>
						<Button
							title='Proceed to stripe for payment'
							className={isPurchaseTab ? 'hidePurchaseButton' : ''}
							disabled={isPurchaseClicked}
							hidden={isPurchaseTab}
							onClick={onSaveClick}
							variant='primary'
							data-test-auto='86154e00-b58b-4944-9038-87d3b8dbdb7e'>
							Pay
						</Button>
					</>
				)}
			</Modal.Footer>
		</div>
	);
};

export default PurchaseReturnModal;
