import React, { useEffect, useState } from 'react';
import '@sssuite-component-ui/react-phone-input-2/lib/style.css';
import PhoneInput, { CountryData } from '@sssuite-component-ui/react-phone-input-2';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { IUTEContactPerson } from '..';
import CloseIcon from '../../../../components/svg/CloseIcon';
import { isValidEmailAddress } from '../../../../helper/formValidations';
import './UTEContactPersonModal.style.scss';
import { isValidMobileNumber } from '@sssuite-js-packages/mobile-number-validator';
import { handleInvalidCountryCodeEntered } from '../../../../helper/HelperFunctions';

interface IUTEContactPersonModal {
	contactPerson: IUTEContactPerson;
	setContactPerson: (contactPerson: IUTEContactPerson) => void;
	closeModal: () => void;
	saveContactPerson: () => void;
	saveUteSettingAndContactPerson: () => void;
}

const UTEContactPersonModal: React.FC<IUTEContactPersonModal> = ({
	contactPerson,
	setContactPerson,
	closeModal,
	saveContactPerson,
	saveUteSettingAndContactPerson,
}) => {
	const { id, name, emailAddress, countryCode, mobileNumber } = contactPerson;

	const [mandatoryFieldError, setMandatoryFieldError] = useState({
		name: { error: false, message: '' },
		email: { error: false, message: '' },
		mobileNumber: { error: false, message: '' },
	});

	const [mobileNumberFullValue, setMobileNumberFullValue] = useState<string>(`${countryCode}${mobileNumber}`);

	useEffect(() => {
		setMandatoryFieldError({
			name: { error: false, message: '' },
			email: { error: false, message: '' },
			mobileNumber: { error: false, message: '' },
		});
	}, []);

	const handleMobileChanges = (fullValue: string, countryObject: CountryData) => {
		fullValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
		setContactPerson({
			...contactPerson,
			countryCode: fullValue ? `+${countryObject.dialCode}` : '',
			mobileNumber: fullValue.slice(countryObject.dialCode.length),
		});
		setMobileNumberFullValue(fullValue);
	};

	const handleOnBlurMobile = (id: string) => {
		if (mobileNumber && mobileNumber.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setContactPerson({ ...contactPerson, countryCode: '' });
		} else if (mobileNumber && countryCode && !isValidMobileNumber(countryCode, mobileNumber)) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message =
				'Please enter a valid mobile number, according to the country code selected';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (mobileNumber && mobileNumber.trim().length && countryCode === '') {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please select country code';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
		}
	};

	const handleOnBlurEmail = (id: string, value: string) => {
		if (value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else if (!isValidEmailAddress(value)) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'Please fill valid Email Address';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setContactPerson({ ...contactPerson, emailAddress: value });
		}
	};

	const handleOnBlurName = (id: string, value: string) => {
		if (value.trim().length === 0) {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = true;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = 'This field is required';
			setMandatoryFieldError(newMandatoryFieldError);
		} else {
			const newMandatoryFieldError = { ...mandatoryFieldError };
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].error = false;
			newMandatoryFieldError[id as keyof typeof newMandatoryFieldError].message = '';
			setMandatoryFieldError(newMandatoryFieldError);
			setContactPerson({ ...contactPerson, name: value });
		}
	};

	const checkAllMandatoryFields = () => {
		handleOnBlurName('name', name);
		handleOnBlurEmail('email', emailAddress);
		handleOnBlurMobile('mobileNumber');
		for (const field in mandatoryFieldError) {
			if (mandatoryFieldError[field as keyof typeof mandatoryFieldError].error) {
				return false;
			}
		}
		return true;
	};

	const handleSaveContactPerson = () => {
		if (checkAllMandatoryFields()) {
			if (id === 0) {
				saveUteSettingAndContactPerson();
			} else {
				saveContactPerson();
			}
		}
	};

	return (
		<Modal
			className='uteContactPersonModal'
			show={true}
			onHide={closeModal}>
			<Modal.Header>
				<div>
					<Modal.Title>{id === 0 ? 'Add' : 'Edit'} Contact Person</Modal.Title>
				</div>
				<div
					data-test-auto='a598e548-38a3-46b8-8a67-0735a8d04cd9'
					className='closeIcon'
					onClick={closeModal}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				<Container className='container__topContainer'>
					<Form>
						<div className='flexCenter'>
							<h1 className='informationText'>Contact Person Information</h1>
							<span
								style={{ paddingTop: 0 }}
								className='fieldErrorMessage requiredTag'>
								* <span className='requiredTag__text'>Required</span>
							</span>
						</div>
						<Row>
							<Col>
								<Form.Group controlId='name'>
									<Form.Label>
										Name <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='text'
										name='name'
										autoFocus
										value={name}
										onChange={(e: any) => setContactPerson({ ...contactPerson, name: e.target.value })}
										onBlur={(e) => handleOnBlurName(e.target.id, e.target.value)}
										className={`${mandatoryFieldError.name.error ? 'fieldErrorBorder' : ''}`}
										maxLength={500}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`fieldErrorSVG ${mandatoryFieldError.name.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.name.message}
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId='email'>
									<Form.Label>
										Email <span className='asterisk'>*</span>
									</Form.Label>
									<Form.Control
										type='text'
										name='email'
										value={emailAddress}
										onChange={(e: any) => setContactPerson({ ...contactPerson, emailAddress: e.target.value })}
										onBlur={(e) => handleOnBlurEmail(e.target.id, e.target.value)}
										className={`${mandatoryFieldError.email.error ? 'fieldErrorBorder' : ''}`}
										maxLength={320}
									/>
								</Form.Group>
								<div
									id='clientFieldError'
									className={`fieldErrorSVG ${mandatoryFieldError.email.error ? 'fieldErrorMessage' : ''}`}>
									{mandatoryFieldError.email.message}
								</div>
							</Col>
						</Row>
						<Row>
							<Col
								xs={6}
								sm={6}
								md={6}
								lg={6}
								xl={6}>
								<div className='mobileSection'>
									<label htmlFor='mobileNumber'>
										<span>Office Number</span>
									</label>
									<PhoneInput
										containerClass={`${mandatoryFieldError.mobileNumber.error ? 'fieldErrorBorder ' : ''}inputCCMobile`}
										country={'us'}
										autoFormat={true}
										value={mobileNumberFullValue}
										onChange={handleMobileChanges}
										onBlur={(e) => handleOnBlurMobile('mobileNumber')}
									/>
									<div
										id='clientFieldError'
										className={`${mandatoryFieldError.mobileNumber.error ? 'fieldErrorMessage' : ''}`}>
										{mandatoryFieldError.mobileNumber.message}
									</div>
								</div>
							</Col>
						</Row>
					</Form>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='b061a225-108c-4af9-b1da-ce3438d68cd1'
					className='cancelBtn'
					variant='secondary'
					onClick={closeModal}>
					Cancel
				</Button>
				<Button
					data-test-auto='02b13db7-1419-4486-b51d-c38b0cc3be8b'
					variant='primary'
					className='actionBtn'
					onClick={handleSaveContactPerson}>
					{id === 0 ? 'Add' : 'Save'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default UTEContactPersonModal;
