import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import AddUserIcon from 'src/components/svg/AddUserIcon';
import ExportUserDetailsIcon from 'src/components/svg/ExportUserDetailsIcon';
import SearchIcon from 'src/components/svg/SearchIcon';
import { IUserModel, PageProps } from '../Users.model';
import './UsersHead.style.scss';
import { userTabConstants } from '../../../../helper/rbac-constants';
import Filter from '../../../common/filter';
import { IOfficeLocation } from '../../../../modules/clientManagement/Client.model';
import ImportIcon from 'src/components/svg/Import';
import ExportIcon from 'src/components/svg/Export';
import Downloadcsv from 'src/components/svg/Downloadcsv';
import { downloadImportUserTemplate } from '../Users.api';
import { isValidAndReadableCsv } from '../../../clientManagement/Client.helper';
import { UploadFunctions } from '@sssuite-js-packages/file-utility';
import { AppNotifier as VenusNotifier } from '../../../../helper/AppNotifier';
import { getUsersImportLink, saveUserImport } from '../Users.api';
import { UserManagementConstants } from '../../../../helper/Constants';
import { ICustomDropdownOption } from 'src/components/common/customDropdown/CustomDropdownWithCustomOptions';

interface UsersHeadProps {
    users: IUserModel[];
    exportUserDetailsAsExcel: (queryString: string, callback?: () => void) => void;
    setShowSaveUserModal: (showAddUserModal: boolean) => void;
    setSaveForUserId: (saveForUserId: number) => void;
    page: PageProps;
    newUserIds: number[];
    setSearchText: (searchText: string) => void;
    searchText: string;
    setIsO365User: (isO365User: boolean) => void;
    selectedOfficeLocations: ICustomDropdownOption[];
    setSelectedOfficeLocations: (selectedOfficeLocations: ICustomDropdownOption[]) => void;
    requestAllUsers: (selectedOfficeLocations: ICustomDropdownOption[]) => void;
    officeLocationList: IOfficeLocation[];
}
let UsersHead: FC<UsersHeadProps> = (props) => {

    const { selectedOfficeLocations, setSelectedOfficeLocations, requestAllUsers, officeLocationList } = props;
    const [exportInProgress, setExportInProgress] = useState(false);
	const [importInProgress, setImportInProgress] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

    const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setSearchText(e.target.value);
    };

    const handleExportUserDetailsClick = () => {
        if (props.users.length !== 0) {
            setExportInProgress(true);
        }
    };

    const onExportUserDetails = (onExportUserDetailsComplete: () => void) => {
        const queryString = buildQueryStringForExportUserDetails();
        props.exportUserDetailsAsExcel(queryString, onExportUserDetailsComplete);
    };

    const onExportUserDetailsComplete = () => {
        setExportInProgress(false);
    };

    const buildQueryStringForExportUserDetails = () => {
		let filterLocationList = selectedOfficeLocations.map((x) => x.value).join();
		return (
			'?searchText=' +
			encodeURIComponent(props.searchText) +
			'&offset=' +
			new Date().getTimezoneOffset() +
			`&filterLocation= ${filterLocationList.split(',').includes('0') ? '' : filterLocationList}`
		);
    };

    const handleAddUserButtonClick = () => {
        props.setIsO365User(false);
        props.setSaveForUserId(0);
        props.setShowSaveUserModal(true);
    };

	const handleDownloadImportUserTemplate = () => {
		downloadImportUserTemplate(() => {
			VenusNotifier.Error(UserManagementConstants.DownloadImportUserTemplateError);
		});
	};

    useEffect(() => {
        if (exportInProgress) {
            onExportUserDetails(onExportUserDetailsComplete);
        }
    }, [exportInProgress]);

	const onUploadComplete = (fileToUpload: any) => {
		saveUserImport(fileToUpload.fileGUID, fileToUpload.fileName, () => {
			VenusNotifier.Error(UserManagementConstants.ImportError);
		});
		setImportInProgress(false);
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const handleFileImport = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		let uploadHelperFunctions = new UploadFunctions();
		if (files && files.length > 0) {
			const file = files[0];
			isValidAndReadableCsv(file, () =>
				getUsersImportLink(
					(source: { sas: string; fileName: string }) => {
						uploadHelperFunctions.uploadFile(file, source, file.name, undefined, onUploadComplete);
						VenusNotifier.Success(UserManagementConstants.ImportInProgress);
						setImportInProgress(true);
					},
					() => {
						VenusNotifier.Error(UserManagementConstants.UploadLinkError);
					},
				),
			);
		}
	};

    return (
		<div className='searchAddExport'>
			<div className='searchUser'>
				<input
					type='text'
					id='searchText'
					placeholder='Search'
					onChange={handleSearchText}
					value={props.searchText}
				/>
				<button className='searchIcon'>
					<SearchIcon />
				</button>
            </div>
			<div className='addexport'>
				{officeLocationList.length > 1 ? (
                    <Filter
                        id='filter'
                        className='filterOfficeLocations'
                        selectedOptions={selectedOfficeLocations}
                        setSelectedOptions={setSelectedOfficeLocations}
						options={officeLocationList.map((x) => ({ label: x.name, value: x.locationId, id: x.locationNumber }))}
                        borderColor={'#6B7075'}
                        requestAllUsers={requestAllUsers}
					/>
				) : (
                    <></>
				)}
				<button
					data-test-auto='11542759-5cdb-4370-9e77-4b305b070eca'
					className='exportUserDetails'
					title='Download template for Import CSV'
					onClick={handleDownloadImportUserTemplate}
					data-resource-id={`${userTabConstants.suitePrefix}_${userTabConstants.downloadImportUserTemplate}`}
					style={{ cursor: 'pointer' }}>
					<Downloadcsv />
				</button>

				<label
					data-test-auto='1bae17cd-e6c5-4b97-b4d3-17832558642d'
					className='exportUserDetails'
					title='Import CSV'
					style={{ cursor: 'pointer' }}>
					<ImportIcon color={'#05386B'} />
					<input
						type='file'
						id='fileInput'
						accept='.csv'
						onChange={handleFileImport}
						disabled={importInProgress}
						style={{ display: 'none' }}
						ref={fileInputRef}
					/>
				</label>
                <button
					data-test-auto='f33e066d-4fb5-4071-a7cf-a8815144daa2'
					className={`exportUserDetails${exportInProgress || props.users.length === 0 ? ' exportDisabled' : ''}`}
                    onClick={handleExportUserDetailsClick}
					disabled={exportInProgress || props.users.length === 0}
                    data-resource-id={`${userTabConstants.prefix}_${userTabConstants.exportList}`}
					title='Export to excel'>
					{exportInProgress ? (
						<div
							className='spinner-border exportIPLoader'
							role='status'></div>
					) : (
						<ExportIcon color={'#05386B'} />
					)}
                </button>
                <button
					data-test-auto='155e93af-bb74-4695-ac41-52a9829fc16d'
					className='addUser'
					data-bs-toggle='modal'
					data-bs-target='#staticBackdrop'
                    onClick={handleAddUserButtonClick}
					data-resource-id={`${userTabConstants.prefix}_${userTabConstants.addUser}`}>
					<AddUserIcon />
					Add&nbsp;User
                </button>
            </div>
        </div>
    );
};
UsersHead = React.memo(UsersHead);
export default UsersHead;