import React, { FC, useEffect, useState } from 'react';
import { IUserGroupData } from '../userGroups/user-groups.types';
import EROSignatureModal from './EROSignatureModal';
import ResetPasswordModal from './ResetPasswordModal';
import RevokeO365AccessModal from './RevokeO365AccessModal';
import { useLocation, useNavigate } from "react-router-dom";
import SaveUserModal from './saveUserModal';
import {
	deleteUser,
	getAllUserGroups,
	getCompanyMFAEnabled,
	getIsUseSignatureStampEnabled,
	resetUserPassword,
	revokeO365UserAccess,
} from './Users.api';
import {
	ISaveUserModel,
	IUserData,
	IUserGroups,
	IUserModel,
	resetPasswordModal,
	IUserDeleteResponse,
	IDefaultContactPerson,
	PageProps,
	newUser,
	IContactPersonViewModel,
} from './Users.model';
import './Users.style.scss';
import UsersHead from './usersHead';
import UsersListTable from './usersListTable';
import UsersPagination from './usersPagination';
import ConfirmationPopup from '../../../components/common/confirmationPopup';
import { IUserProfile } from '../../../pages/common/userProfile/userProfile.types';
import {
	UserDeleteConfirmationPopupConstants,
	UserDeleteAlterPopupConstants,
	UserErrorPopupConstants,
} from '../../../helper/Constants';
import DefaultContactPersonModal from '../users/DefaultContactPerson';
import { IOfficeLocation } from '../../../modules/clientManagement/Client.model';
import { fetchOfficeLocation } from '../../../modules/clientManagement/Client.api';
import ChangeContactPersonModal from './changeContactPersonModal';
import { IContactPersonViewModelList } from './saveUserModal/SaveUserModal.model';
import { updateUserInfo } from './saveUserModal/SaveUserModal.api';
import { ICustomDropdownOption } from '../../common/filter';
import { logger } from 'src/oidcClient/authProvider';
import UserGroupAuditModal from './UserGroupAudit';
import { requestCompanyProfile } from 'src/pages/common/companySettings/companySettings.apis';
import { useDispatch } from 'react-redux';

interface UsersProps {
	display: boolean;
	usersList: IUserData;
	userGroupList: IUserGroupData;
	requestAllUsers: (
		reload: boolean,
		pageIndex: number,
		pageSize: number,
		searchString: string,
		newUserIds: string,
		officeLocations: string,
	) => void;
	requestUserById: (id: number, callback: (user: ISaveUserModel) => void) => void;
	exportUserDetailsAsExcel: (queryString: string, callback?: () => void) => void;
	profile: IUserProfile;
	page: PageProps;
	setPage: (page: PageProps) => void;
	newUserIds: number[];
	setNewUserIds: (newUserIds: number[]) => void;
	searchText: string;
	setSearchText: (searchText: string) => void;
}

export enum modificationType {
	None = 0,
	Edit = 1,
	Delete = 2,
}

let Users: FC<UsersProps> = (props) => {
	const { page, setPage, searchText, setSearchText, newUserIds, setNewUserIds } = props;
	const { users, isLoading, count } = props.usersList;
	const [revokeInProgress, setRevokeInProgress] = useState(false);
	const [eroSignatureInProgress, setEroSignatureInProgress] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(resetPasswordModal);
	const [showRevokeO365AccessModal, setShowRevokeO365AccessModal] = useState(false);
	const [showEROSignatureModal, setShowEROSignatureModal] = useState(false);
	const [userEmailRevoke, setUserEmailRevoke] = useState('');
	const [signatureForUserId, setSignatureForUserId] = useState(0);
	const [showSaveUserModal, setShowSaveUserModal] = useState(false);
	const [saveForUserId, setSaveForUserId] = useState(0);
	const [userReviewStatus, setUserReviewStatus] = useState(-1);
	const [isO365User, setIsO365User] = useState(false);
	const [companyEnableMFA, setCompanyEnableMFA] = useState(false);
	const [isUseSignatureStampEnabled, setIsUseSignatureStampEnabled] = useState(false);
	const [availableUserGroupList, setAvailableUserGroupList] = useState<IUserGroups[]>([]);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
	const [deleteDisabled, setDeleteDisabled] = useState(false);
	const { groups } = props.userGroupList;
	const [selectedUser, setSelectedUser] = useState({} as IUserModel);
	const name = selectedUser.firstName + ' ' + selectedUser.lastName;
	const [showDeleteUserAlterModal, setShowDeleteUserAlterModal] = useState(false);
	const [alterMessage, setAlterMessage] = useState('');
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showDefaultContactPersonModal, setShowDefaultContactPersonModal] = useState(false);
	const [defaultContactPersons, setDefaultContactPersons] = useState<IDefaultContactPerson[]>([]);
	const [officeLocationList, setOfficeLocationList] = useState<IOfficeLocation[]>([]);
	const [showContactPersonModal, setShowContactPersonModal] = useState<boolean>(false);
	const [contactPersonModalData, setContactPersonModalData] = useState<IContactPersonViewModelList[]>([]);
	const [inProgressContactPerson, setInProgressContactPerson] = useState<boolean>(false);
	const [processType, setProcessType] = useState<number>(modificationType.None);
	const [editedUser, setEditedUser] = useState<ISaveUserModel>(newUser);
	const [selectedOfficeLocations, setSelectedOfficeLocations] = useState<ICustomDropdownOption[]>([]);
	const [showUserGroupAuditModal, setShowUserGroupAuditModal] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const goToPage = (newPageIndex: number, newPageSize: number) => {
		const totalPages = Math.ceil(count / page.pageSize);
		if (
			(newPageIndex !== page.pageIndex && newPageIndex <= totalPages && newPageIndex >= 1) ||
			newPageSize !== page.pageSize
		) {
			const newPage = { ...page };
			newPage.pageIndex = newPageIndex;
			newPage.pageSize = newPageSize;
			setPage(newPage);
			props.requestAllUsers(
				true,
				newPageIndex,
				newPageSize,
				searchText,
				newUserIds.join(),
				selectedOfficeLocations.map((x) => x.value).join(),
			);
		}
	};

	const resetPassword = (userId: number, userEmail: string, isO365: boolean) => {
		if (!isO365) {
			logger && logger.trackTrace('resetPassword: Resetting password for user in usermanagement', { userId: userId });
			resetUserPassword(userId, (status) => {
				setShowResetPasswordModal({ ...showResetPasswordModal, show: true, type: status, email: userEmail });
			});
		}
	};

	const revokeO365Access = (revokeStatusSelected: number) => {
		logger && logger.trackTrace('revokeO365Access: Revoking O365 access in usermanagement');
		setRevokeInProgress(true);
		revokeO365UserAccess(userEmailRevoke, revokeStatusSelected, (status) => {
			if (status) {
				setRevokeInProgress(false);
				setShowRevokeO365AccessModal(false);
				props.requestAllUsers(
					true,
					page.pageIndex,
					page.pageSize,
					searchText,
					newUserIds.join(),
					selectedOfficeLocations.map((x) => x.value).join(),
				);
			}
		});
	};

	useEffect(() => {
		getCompanyMFAEnabled(setCompanyEnableMFA);
		getIsUseSignatureStampEnabled(setIsUseSignatureStampEnabled);
		fetchOfficeLocation(setOfficeLocationList);
		dispatch(requestCompanyProfile());
	}, []);

	useEffect(() => {
		getAllUserGroups(setAvailableUserGroupList);
	}, [groups]);

	useEffect(() => {
		const newPage = { ...page };
		newPage.pageIndex = 1;
		setPage(newPage);
		props.requestAllUsers(
			true,
			1,
			page.pageSize,
			searchText,
			newUserIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
	}, [searchText]);

	const handleUserDeleteAction = (user: IUserModel) => {
		if (props.profile.userId != user.id) {
			deleteUser(
				user.id,
				true,
				(data: IUserDeleteResponse) => {
					if (!data.isDeleted && data.isPrimaryAdmin) {
						showUserDeletionNotAllowedPopup(UserDeleteAlterPopupConstants.primayAdminUserDeleteMessage);
					}
					else if (!data.isDeleted && data.isDefaultContactPerson) {
						showUserDeletionNotAllowedPopup(getContactPersonDeletionMessage(user));
					}
					else{
						setShowDeleteUserModal(true);
					}
				},
				() => {
					showDeleteErrorPopup();
				},
			);
		} else {
			setDeleteDisabled(false);
			setShowDeleteUserModal(false);
			setAlterMessage(UserDeleteAlterPopupConstants.loggedInUserDeleteMessage);
			setShowDeleteUserAlterModal(true);
		}
	}

	const getContactPersonDeletionMessage = (user: IUserModel) => {
		const message =  
			<>
				<strong>{user.firstName + ' ' + user.lastName}</strong> {UserDeleteAlterPopupConstants.defaultContactPersonDeleteMessage1} 
				<a href='javascript:void(0)' onClick={gotoCompanyManagementPage} style={{color: "#212529"}}>
					{UserDeleteAlterPopupConstants.defaultContactPersonDeleteMessage2}
				</a>.
			</>
		return message;
	}

	const gotoCompanyManagementPage = () => {
		navigate('/company-management');
	}

	const showUserDeletionNotAllowedPopup = (message: any) => {
		setDeleteDisabled(false);
		setShowDeleteUserModal(false);
		setAlterMessage(message);
		setShowDeleteUserAlterModal(true);
	}

	const onDelete = (user: IUserModel) => {
		if (props.profile.userId != user.id) {
			logger && logger.trackTrace('onDelete: Deleting user in usermanagement', { userId: user.id });
			setProcessType(modificationType.Delete);
			setDeleteDisabled(true);
			deleteUser(
				user.id,
				false,
				(data: IUserDeleteResponse) => {
					if (data.isDeleted) {
						onDeleteSuccess();
					}
				 	else if (!data.isDeleted && data.isPrimaryAdmin) {
						showUserDeletionNotAllowedPopup(UserDeleteAlterPopupConstants.primayAdminUserDeleteMessage);
					}
					else if (!data.isDeleted && data.isDefaultContactPerson) {
						showUserDeletionNotAllowedPopup(getContactPersonDeletionMessage(user));
					} else if (!data.isDeleted) {
						setDeleteDisabled(false);
						setShowDeleteUserModal(false);
						showDeleteErrorPopup(UserErrorPopupConstants.deleteUserCustomError);
					}
				},
				() => {
					showDeleteErrorPopup();
				},
			);
		} else {
			setDeleteDisabled(false);
			setShowDeleteUserModal(false);
			setAlterMessage(UserDeleteAlterPopupConstants.loggedInUserDeleteMessage);
			setShowDeleteUserAlterModal(true);
		}
	};

	const showDeleteErrorPopup = (errorMessage?: string) => {
		setDeleteDisabled(false);
		setShowDeleteUserModal(false);
		setErrorMessage(errorMessage ? errorMessage : UserErrorPopupConstants.deleteUserError);
		setShowDefaultContactPersonModal(false);
		setShowContactPersonModal(false);
		setInProgressContactPerson(false);
		setShowErrorModal(true);
	};

	const onDeleteUserSelection = (user: IUserModel) => {
		setSelectedUser(user);
		handleUserDeleteAction(user);
	};

	const onUserGroupAuditSelection = (user: IUserModel) => {
		setSelectedUser(user);
		setShowUserGroupAuditModal(true);
	};

	const onDeleteSuccess = () => {
		setDeleteDisabled(false);
		setShowDeleteUserModal(false);
		setShowDefaultContactPersonModal(false);
		setDeleteDisabled(false);
		props.requestAllUsers(
			true,
			page.pageIndex,
			page.pageSize,
			searchText,
			newUserIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
	};

	const toggleContactPersonModal = (bool: boolean) => {
		setShowContactPersonModal(bool);
	};

	const handleUpdateUserLocation = (contactPersonData: IContactPersonViewModelList[]) => {
		setInProgressContactPerson(true);
		const contactPersons: IContactPersonViewModel[] = [];
		contactPersonData.forEach((ele1) => {
			ele1.contactPerson.forEach((ele2) => {
				if (ele2.selected) {
					contactPersons.push(ele2);
				}
			});
		});
		const user: ISaveUserModel = { ...editedUser, contactPersons };
		updateUserInfo(user, (response) => {
			props.requestAllUsers(
				true,
				page.pageIndex,
				page.pageSize,
				searchText,
				newUserIds.join(),
				selectedOfficeLocations.map((x) => x.value).join(),
			);
			setShowSaveUserModal(false);
			setShowContactPersonModal(false);
			setInProgressContactPerson(false);
		});
	};

	const requestAllUsers = (selectedOfficeLocations: ICustomDropdownOption[]) => {
		props.requestAllUsers(
			true,
			page.pageIndex,
			page.pageSize,
			searchText,
			newUserIds.join(),
			selectedOfficeLocations.map((x) => x.value).join(),
		);
	};

	return (
		<>
			<article
				id='usersDetails'
				className='usersDetails'
				style={{ display: `${props.display ? 'block' : 'none'}` }}>
				<UsersHead
					users={users}
					exportUserDetailsAsExcel={props.exportUserDetailsAsExcel}
					page={page}
					setShowSaveUserModal={setShowSaveUserModal}
					setSaveForUserId={setSaveForUserId}
					newUserIds={newUserIds}
					setSearchText={setSearchText}
					searchText={searchText}
					setIsO365User={setIsO365User}
					selectedOfficeLocations={selectedOfficeLocations}
					setSelectedOfficeLocations={setSelectedOfficeLocations}
					requestAllUsers={requestAllUsers}
					officeLocationList={officeLocationList}
				/>

				<UsersListTable
					users={users}
					isLoading={isLoading}
					setShowSaveUserModal={setShowSaveUserModal}
					setSaveForUserId={setSaveForUserId}
					newUserIds={newUserIds}
					resetPassword={resetPassword}
					setUserEmailRevoke={setUserEmailRevoke}
					setShowRevokeO365AccessModal={setShowRevokeO365AccessModal}
					setShowEROSignatureModal={setShowEROSignatureModal}
					isUseSignatureStampEnabled={isUseSignatureStampEnabled}
					setSignatureForUserId={setSignatureForUserId}
					setIsO365User={setIsO365User}
					setUserReviewStatus={setUserReviewStatus}
					handleDeleteUserButtonClick={onDeleteUserSelection}
					handleAuditReportBtnClick={onUserGroupAuditSelection}
				/>

				<UsersPagination
					count={count}
					page={page}
					goToPage={goToPage}
				/>
			</article>

			{showContactPersonModal && (
				<ChangeContactPersonModal
					toggleContactPersonModal={toggleContactPersonModal}
					contactPersonModalData={contactPersonModalData}
					handleUpdateUserLocation={handleUpdateUserLocation}
					editedUser={editedUser}
					inProgressContactPerson={inProgressContactPerson}
					processType={processType}
				/>
			)}

			{showSaveUserModal && (
				<SaveUserModal
					companyEnableMFA={companyEnableMFA}
					saveForUserId={saveForUserId}
					setShowSaveUserModal={setShowSaveUserModal}
					requestUserById={props.requestUserById}
					availableUserGroupList={availableUserGroupList}
					newUserIds={newUserIds}
					setNewUserIds={setNewUserIds}
					requestAllUsers={props.requestAllUsers}
					page={page}
					isO365User={isO365User}
					userReviewStatus={userReviewStatus}
					searchText={searchText}
					officeLocationList={officeLocationList}
					toggleContactPersonModal={toggleContactPersonModal}
					setContactPersonModalData={setContactPersonModalData}
					setEditedUser={setEditedUser}
					setProcessType={setProcessType}
					selectedOfficeLocations={selectedOfficeLocations}
				/>
			)}

			{showResetPasswordModal.show && (
				<ResetPasswordModal
					setShowResetPasswordModal={setShowResetPasswordModal}
					showResetPasswordModal={showResetPasswordModal}
				/>
			)}

			{showRevokeO365AccessModal && (
				<RevokeO365AccessModal
					setShowRevokeO365AccessModal={setShowRevokeO365AccessModal}
					revokeO365Access={revokeO365Access}
					revokeInProgress={revokeInProgress}
				/>
			)}

			{showEROSignatureModal && (
				<EROSignatureModal
					setShowEROSignatureModal={setShowEROSignatureModal}
					eroSignatureInProgress={eroSignatureInProgress}
					signatureForUserId={signatureForUserId}
					setEroSignatureInProgress={setEroSignatureInProgress}
				/>
			)}
			{showDeleteUserModal && selectedUser && (
				<ConfirmationPopup
					show={showDeleteUserModal}
					className='userDeleteConfirmationPopup'
					actionButtonType={'danger'}
					cancelText={UserDeleteConfirmationPopupConstants.cancelText}
					header={UserDeleteConfirmationPopupConstants.headerText}
					message={
						<>
							You will <span style={{color: "#CC4A43"}}>permanently</span> lose <strong>{name}</strong>. 
							<br/> 
							<br/> 
							{UserDeleteConfirmationPopupConstants.contactPersonInfoMessageLine1}
							<br/>
							{UserDeleteConfirmationPopupConstants.contactPersonInfoMessageLine2}
							<br/> 
						</>
					}
					model={selectedUser}
					okText={UserDeleteConfirmationPopupConstants.okText}
					onCancel={() => {
						setShowDeleteUserModal(false);
					}}
					onOk={onDelete}
					onHide={() => {
						setShowDeleteUserModal(false);
					}}
					actionDisabled={deleteDisabled}
					disabledText={''}
				/>
			)}

			{showDeleteUserAlterModal && (
				<ConfirmationPopup
					show={showDeleteUserAlterModal}
					className='userDeleteAlterPopup'
					cancelButtonType={'primary'}
					cancelText={UserDeleteAlterPopupConstants.cancelText}
					header={UserDeleteAlterPopupConstants.headerText}
					message={alterMessage}
					model={''}
					onCancel={() => {
						setShowDeleteUserAlterModal(false);
					}}
					hideOkBtn={true}
					okText={''}
					onHide={() => {
						setShowDeleteUserAlterModal(false);
					}}
					onOk={() => {
						setShowDeleteUserAlterModal(false);
					}}
				/>
			)}

			{showErrorModal && (
				<ConfirmationPopup
					show={showErrorModal}
					cancelButtonType={'primary'}
					cancelText={UserErrorPopupConstants.cancelText}
					header={UserErrorPopupConstants.headerText}
					message={errorMessage}
					model={''}
					onCancel={() => {
						setShowErrorModal(false);
					}}
					hideOkBtn={true}
					okText={''}
					onHide={() => {
						setShowErrorModal(false);
					}}
					onOk={() => {
						setShowErrorModal(false);
					}}
				/>
			)}

			{showDefaultContactPersonModal && (
				<DefaultContactPersonModal
					defaultContactPersons={defaultContactPersons}
					userId={selectedUser.id}
					setShowDefaultContactPersonModal={(value) => {
						setShowDefaultContactPersonModal(false);
						setDeleteDisabled(false);
					}}
					showDeleteErrorPopup={showDeleteErrorPopup}
					onSuccess={onDeleteSuccess}
				/>
			)}

			{showUserGroupAuditModal && (
				<UserGroupAuditModal
					handleClose={() => {
						setShowUserGroupAuditModal(false);
					}}
					showUserGroupAuditModel={showUserGroupAuditModal}
					userId={selectedUser.id}
				/>
			)}
		</>
	);
};
Users = React.memo(Users);
export default Users;
