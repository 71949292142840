import React, { Component, Dispatch, RefObject, createRef, useState } from 'react';
import styles from './Login.module.scss';
import { EyeIcon, EyeSlashIcon, InfoIcon } from 'src/components/svg/IconCollection';
import { Credentials } from '../GFR.model';
import { AnimatedAlert } from '../Alert';
import { Modal } from 'react-bootstrap';
import CloseIcon from 'src/components/svg/CloseIcon';
import { connect, useDispatch } from "react-redux";
import { actionTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';
import Consent from '../Consent';

interface GFRLoginProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (credentials: Credentials, callback?: (isSuccess: boolean, error?: string) => void) => void;
  dispatch: Dispatch<any>;
}

interface LoginState {
  credentials: Credentials;
  showPassword: boolean;
  showAlert: boolean;
  alertMessage: string;
  showConsent: boolean;
  consentmessage: string;
  consentchecked: boolean;
}

const usernameValidation: string = 'Please enter Username.';
const passwordValidation: string = 'Please enter Password.';
const consentValidation: string = 'Please select checkbox to accept the consent.';
const credentialsValidation: string = 'Please enter valid credentials';
const consentMsg: string = 'By proceeding, you consent to the storage of your credentials on our secure servers. This information will be used solely for authentication and access purposes.';

class GFRLogin extends Component<GFRLoginProps, LoginState> {

  private passwordRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
  private usernameRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
  private alertMsg: string = credentialsValidation;

  private initialState = {
    credentials: {
      loginname: '',
      password: ''
    },
    showPassword: false,
    showAlert: false,
    alertMessage: '',
    showConsent: false,
    consentmessage: '',
    consentchecked: false
  };

  constructor(props: GFRLoginProps) {
    super(props);
    this.state = {
      ...this.initialState
    };

  }

  componentDidUpdate(prevProps: Readonly<GFRLoginProps>, prevState: Readonly<LoginState>, snapshot?: any): void {
    if (prevProps.show !== this.props.show) {
      this.setState({
        ...this.initialState
      });
    }
  }

  handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        loginname: e.target.value
      }
    });
  };

  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        password: e.target.value
      }
    });
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    var validationRes = this.ValidateForm();

    if (!validationRes) {
      return;
    }
    this.showConsent();
  };

  ValidateForm = () => {

    if (this.state.credentials.loginname === '') {
      this.showLoginAlert(usernameValidation, this.usernameRef);
      return false;
    }
    if (this.state.credentials.password === '') {
      this.showLoginAlert(passwordValidation, this.passwordRef);
      return false;
    }

    return true;
  }

  showLoginAlert = (message: string = this.alertMsg, fieldref: RefObject<HTMLInputElement> = this.usernameRef) => {
    this.setState({
      ...this.state,
      showAlert: true,
      alertMessage: message
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        showAlert: false
      });

      if (fieldref.current) {
        fieldref.current.style.border = '';
      }

    }, 5000);

    if (fieldref.current) {
      fieldref.current.focus();
      fieldref.current.style.border = '1px solid red';
    }
  }

  showConsent = (show: boolean = true) => {
    this.setState({
      ...this.state,
      showConsent: show
    });
  }

  saveCredentials = () => {
    var callback = (isSuccess: boolean, error?: string) => {

      this.props.dispatch({ type: actionTypes.LOADING, show: false, text: '', });

      if (isSuccess) {
        this.props.onClose();
        return;
      }

      this.showLoginAlert();
    }

    this.props.dispatch({ type: actionTypes.LOADING, show: true, text: 'Saving Credentials...', });
    this.props.onSubmit(this.state.credentials, callback);
  }

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      consentchecked: e.target.checked
    });
  }

  onAccept = () => {
    this.showConsent(false);
    this.saveCredentials();
  }

  onDecline = () => {
    this.showConsent(false);
  }

  render() {

    return (
      <Modal
        show={this.props.show} onHide={this.props.onClose}
        className={`suiteModal`}>

        <Modal.Header className='lighttheme'>
          <div>
            <Modal.Title> Login to GoFileRoom</Modal.Title>
          </div>
          <div
            data-test-auto='e2ab6c41-b6f5-4181-b38f-3455585b2141'
            className='closeIcon'
            onClick={this.props.onClose}>
            <CloseIcon />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <div className={styles.trProductName}>THOMSON REUTERS</div>
            <h2 className={styles.gfrProductName}>GOFILEROOM</h2>
          </div>

          <AnimatedAlert
            show={this.state.showAlert}
            message={this.state.alertMessage} />

          <form onSubmit={this.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Username</label>
              <input
                data-val-required="The Username field is required."
                type="text"
                className="form-control"
                id="username"
                placeholder="Enter your username"
                value={this.state.credentials.loginname}
                onChange={(e) => this.handleUsernameChange(e)}
                ref={this.usernameRef}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <div className="mb-3 input-with-toggle input-group">
                <input
                  type={this.state.showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Enter your password"
                  value={this.state.credentials.password}
                  onChange={(e) => this.handlePasswordChange(e)}
                  autoComplete='off'
                  ref={this.passwordRef}
                />

                <span className="input-group-text" onClick={this.togglePasswordVisibility}>
                  {this.state.showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </span>
              </div>
            </div>


            <br />

            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary">Log in</button>
            </div>
          </form>

          <Consent
            show={this.state.showConsent}
            title='Consent to Store User Credentials'
            message={consentMsg}
            onAccept={this.onAccept}
            onDecline={this.onDecline}
          />

        </Modal.Body>
      </Modal>

    )
  };
};

export default connect()(GFRLogin);