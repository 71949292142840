/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { type FC, useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from 'src/components/svg/CloseIcon';
import { ReactComponent as UploadIcon } from 'src/components/svg/upload-icon.svg';
import WarningIcon from 'src/components/svg/WarningIcon';
import { logger } from 'src/oidcClient/authProvider';

import ButtonWithTextIcon from '../../../../components/common/button-with-text-icon';
import { useStateWithCallback } from '../../../../custom-hooks/useStateWithCallback';
import { uploadFile } from '../../../../helper/FileUpload';
import ImageCard from '../image-card/image-card';
import styles from './logo-upload-overlay.module.scss';
import { type ILogoUploadOverlayProps } from './logo-upload-overlay.types';

interface IAlert {
	showAlert: boolean;
	alertType: string;
	alertMessage: string;
}

const FileUploader = (props) => {
	const hiddenInputRef = useRef(null);
	const handleClick = () => {
		hiddenInputRef.current.click();
	};

	const onFileChange = (event) => {
		const fileUploaded = event.target.files[0];
		props.handleChange(fileUploaded);
	};
	return (
		<>
			<ButtonWithTextIcon
				label='Upload'
				SvgIcon={<UploadIcon />}
				className={styles.company_logo_action_buttons}
				data-test-auto={`${props.dataTestPrefix}_upload`}
				onClick={handleClick}
				bordered
			/>
			<input
				type='file'
				style={{ display: 'none' }}
				ref={hiddenInputRef}
				onChange={(event) => {
					onFileChange(event);
				}}
			/>
		</>
	);
};

const LogoUploadOverlay: FC<ILogoUploadOverlayProps> = (props) => {
	const {
		show,
		onHide,
		onCancel,
		onSave,
		header,
		description,
		logo,
		showDefaultLogoCheckbox = false,
		isSsrLogo,
		requestUploadLink,
		uploadLink,
		callbackAfterUpload,
		handleCheckboxSave,
		dataTestPrefix,
		isWhiteLogo,
	} = props;

	const initialAlert = {
		alertType: '',
		alertMessage: '',
		showAlert: false,
	};

	const [uploadedFile, setUploadedFile] = useState('');
	const [isLogoUploaded, setIsLogoUploaded] = useState(false);
	const [isSaveDisabled, setIsSaveDisabled] = useState(true);
	const [isChecked, setIsChecked] = useStateWithCallback(false);
	const [alert, setAlert] = useState<IAlert>(initialAlert);

	const validateCompanyLogo = (file: any) => {
		if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') return true;
		setAlert({
			showAlert: true,
			alertMessage: 'Files must be of jpeg, jpg or png format.',
			alertType: 'danger',
		});

		return false;
	};

	const validateCompanyLogoDimension = async (dataURL: any): Promise<boolean> => {
		return await new Promise<boolean>((resolve) => {
			const img = new Image();
			img.src = dataURL;
			img.onload = function () {
				if (img.width <= 300 && img.height <= 100) resolve(true);

				resolve(false);
			};
		});
	};
	const handleFileUpload = (file: any) => {
		if (validateCompanyLogo(file)) {
			const dataUrl = URL.createObjectURL(file);
			validateCompanyLogoDimension(dataUrl)
				.then((result) => {
					if (result) {
						callbackAfterUpload(dataUrl);
						requestUploadLink();
						setUploadedFile(file);
						setIsLogoUploaded(true);
						setIsSaveDisabled(false);
						setIsChecked(false);
						setAlert({
							showAlert: false,
							alertMessage: '',
							alertType: 'danger',
						});
					} else
						setAlert({
							showAlert: true,
							alertMessage: 'Max dimensions are 300x100px.',
							alertType: 'danger',
						});
				})
				.catch((error) => {
					logger && logger.trackWarning('Error while uploading logo');
				});
		}
	};

	const handleUploadFile = (): void => {
		if (isLogoUploaded && uploadLink.sas.length > 0) {
			setIsSaveDisabled(true);
			setAlert({
				showAlert: false,
				alertMessage: '',
				alertType: 'danger',
			});
			uploadFile(uploadedFile, uploadLink.sas, uploadFile.name, () => {
				uploadFile(uploadedFile, uploadLink.commonStorageLogoPath, uploadFile.name, () => {
					onSave();
					setIsSaveDisabled(true);
					setUploadedFile('');
					setIsLogoUploaded(false);
				});
			});
		} else
			setAlert({
				showAlert: true,
				alertMessage: 'Please upload a company logo or use default logo.',
				alertType: 'danger',
			});
	};

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
		if (!isChecked === isSsrLogo && !isLogoUploaded) setIsSaveDisabled(true);
		else setIsSaveDisabled(false);
	};

	useEffect(() => {
		setIsChecked(isSsrLogo);
	}, [isSsrLogo]);

	const handleHide = (): void => {
		onHide();
		handleCancel();
		setIsChecked(isSsrLogo);
		setAlert(initialAlert);
		setIsSaveDisabled(true);
		setUploadedFile('');
		setIsLogoUploaded(false);
	};

	const handleCancel = (): void => {
		onCancel();
		setIsChecked(isSsrLogo);
		setAlert(initialAlert);
		setIsSaveDisabled(true);
		setUploadedFile('');
		setIsLogoUploaded(false);
	};

	return (
		<Modal
			className={styles.logo_upload_modal}
			show={show}
			onHide={handleHide}>
			<Modal.Header>
				<Modal.Title className={styles.logo_upload_modal_tilte}>{header}</Modal.Title>
				<div
					className={styles.logo_upload_modal_closeIcon}
					data-test-auto={`${dataTestPrefix}_hide`}
					onClick={handleHide}>
					<CloseIcon />
				</div>
			</Modal.Header>
			<Modal.Body>
				<Alert
					variant={alert.alertType}
					hidden={!alert.showAlert}
					onClose={() => {
						setAlert(initialAlert);
					}}
					className={styles.logoUploadAlert}>
					<span className={styles.logoUploadAlertSpan}>
						<WarningIcon />
					</span>
					{alert.alertMessage}
				</Alert>
				<Container className={styles.logo_upload_modal_description}>{description}</Container>
				<div className={styles.logo_upload_modal_upload_section}>
					<ImageCard
						src={logo}
						alt='company logo'
						isWhiteLogo={isWhiteLogo}
					/>
					<div className={styles.logo_upload_modal_upload_action_section}>
						<div className={styles.logo_upload_modal_upload_button}>
							<FileUploader
								handleChange={handleFileUpload}
								dataTestPrefix={dataTestPrefix}
							/>
						</div>
						{showDefaultLogoCheckbox && (
							<div className={styles.logo_upload_modal_checkbox}>
								<label>
									<input
										type='checkbox'
										checked={isChecked}
										data-test-auto={`${dataTestPrefix}_default-logo`}
										onChange={() => {
											handleCheckboxChange();
										}}
									/>
									Use SafeSend One Logo
								</label>
							</div>
						)}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='outline-secondary'
					data-test-auto={`${dataTestPrefix}_cancel`}
					onClick={handleCancel}>
					Cancel
				</Button>

				<Button
					disabled={isSaveDisabled}
					data-test-auto={`${dataTestPrefix}_save`}
					onClick={() => {
						if (isChecked === true) {
							setAlert({
								showAlert: false,
								alertMessage: '',
								alertType: 'danger',
							});
							handleCheckboxSave();
						} else handleUploadFile();
					}}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default LogoUploadOverlay;
