import React, { useEffect } from 'react';
import './ClientManagement.style.scss';
import Clients from '../../modules/clientManagement';
import BreadCrumbNav from '../../components/userManagement/BreadCrumbNav';
import { ClientManagementProps } from './ClientManagement.model';
import { logger } from '../../oidcClient/authProvider';

const ClientManagement: React.FC<ClientManagementProps> = (props) => {
    const breadCrumbConfig = [
        {
            name: 'SafeSend One',
            activePage: false,
            link: '/',
        },
        {
            name: 'Client Management',
            activePage: true,
            link: '',
        },
    ];

	useEffect(() => {
		logger && logger.trackPageView('Navigated to Client Management');
	}, []);

    return (
		<div className='content'>
			<section className='clientManagement'>
                <BreadCrumbNav config={breadCrumbConfig} />
				<h1 className='clientManagementHeading'>Client Management</h1>
				<section className='mainSection'>
                    <Clients
                        clientsList={props.clientsList}
                        requestAllClients={props.requestAllClients}
                        requestResourceIds={props.requestResourceIds}
                        deleteClient={props.deleteClient}
						deleteMultipleClients={props.deleteMultipleClients}
						bulkDeleteClients={props.bulkDeleteClients}
						editMultipleClients={props.editMultipleClients}
						bulkEditClients={props.bulkEditClients}
                        requestClientInfoById={props.requestClientInfoById}
                        exportClientDetailsAsExcel={props.exportClientDetailsAsExcel}
                    />
                </section>
            </section>
        </div>
    );
};

export default ClientManagement;
