import { NotificationAction } from 'src/pages/common/notification/notification.reducer';
import { actionTypes } from './PurchaseReturn.actions';
import { OverlayLoaderAction } from 'src/pages/common/overlayLoader/OverlayLoader.model';
import { PaymentFlowType, ProductType } from './PaymentHistory.model';
import { ISaleTaxItem } from './Tax.model';

export enum PaymentType {
    None = 0,
    Card = 1,
}

export enum PaymentGateway {
    None = 0,
    Stripe = 1,
}

export enum PaymentState {
    Initialized = 0,
    Success = 1,
    Incomplete,
    Failed,
    Other,
}

export enum PaymentStatus {
    Success,
    Cancelled,
    InvalidLink,
}

export enum DeliveryMode {
    ElectronicFiled = 0,
    PaperFiled = 1,
}

export enum ApplicationType {
    None,
    CPA = 1,
    TaxPayer,
    RecognizerService,
    DeliveryService,
}

export interface ProductPurchase {
    numberOfDocuments: number;
    pricePerDocument: number;
    remarks: string;
    amount: number;
}

export interface ISSRPurchase extends ProductPurchase {
    remainingReturns: number;
    deliveryMode: DeliveryMode;
}

export interface ISignaturePurchase extends ProductPurchase {
    remainingDocuments: number;
}

export interface ISSOPurchase extends ProductPurchase {
    remainingReturns: number;
}

export interface IPurchaseState {
    ssrPurchaseState: ISSRPurchaseState;
    saving: boolean;
}

export interface ISSRPurchaseState {
    ssrOutStandingBalance: number;
    ssrReturnQuantity: number;
    ssrPricePerReturn: number;
    ssrTotalAmount: number;
    currentBalance: number;
    ssrReturnQuantityTemp: number;
    ssrTotalAmountTemp: number;
    currentPricePerReturn?: number;
    minDiscountQuntity?: number;
}

export interface IBulkPurchaseDiscountDetails {
    productType: ProductType;
    minQuantity: number;
    maxQuantity?: number;
    discountPrice?: number;
}

export interface IPaymentSetUpInfo {
    customerId: string;
    id: string;
    setUpId: string;
    successPath: string;
    cancelPath: string;
    publicApiKey: string;
    accountId: string;
    productType: ProductType;
}

export interface IPurchaseData {
    billingDetail: IBillingDetailData;
    purchaseViewModel: IPaymentViewModel;
    paymentDetails: IPaymentDetails;
};

export interface IBillingDetailData {
    id: number;
    pricePerReturn: number;
    totalReturns: number;
    remainingReturns: number;
    lastPurchase: number;
    deliveryMode: DeliveryMode;
}

export interface IPaymentViewModel {
    returnsPurchase: ISSRPurchase | undefined;
    purchaseMode: number;
    checkNumber: string;
    checkDate?: Date;
    emailReceiptTo: string;
    createdBy: string;
    createdOn?: Date;

    paymentSuccessPath: string;
    paymentCancelPath: string;
    appllicationFees: number;
    amountTotal: number;
    amountTotalWithFee: number;

    transactionId: string;
    paymentSessionId: string;
    paymentGatewayType: PaymentGateway;
    originApp: ApplicationType;
    paymentStatus: PaymentState;
    message: string;
    details: string;
    receiptPath: string;
    paymentOption: PaymentType;
    paymentFlow: PaymentFlowType;
    taxItem?: ISaleTaxItem;
    paymentId: string
}

export const intialPurchaseViewModel: IPaymentViewModel = {
    returnsPurchase: {
        numberOfDocuments: 0,
        pricePerDocument: 0,
        amount: 0,
        remarks: '',
        remainingReturns: 0,
        deliveryMode: DeliveryMode.ElectronicFiled,
    },    
    purchaseMode: 1,
    checkNumber: '',
    emailReceiptTo: '',
    createdBy: '',

    paymentSuccessPath: '',
    paymentCancelPath: '',
    appllicationFees: 0,
    amountTotal: 0,
    amountTotalWithFee: 0,

    transactionId: '',
    paymentSessionId: '',
    paymentGatewayType: PaymentGateway.Stripe,
    originApp: ApplicationType.CPA,
    paymentStatus: PaymentState.Initialized,
    message: '',
    details: '',
    receiptPath: '',
    paymentOption: PaymentType.Card,
    paymentFlow: PaymentFlowType.PayNow,    
    checkDate: new Date(),
    createdOn: new Date(),
    paymentId:'',
};

export interface IPaymentDetails {
    publicApiKey: string;
    accountId: string;
    paymentId: string;
    payemtSessionId: string;
    totAmount: number;
    paymentSuccessPath: string;
    paymentCancelPath: string;
    transactionId: string;
    status: PaymentState;
    message: string;
}

export const initializePaymentDetails: IPaymentDetails = {
    accountId: '',
    payemtSessionId: '',
    paymentCancelPath: '',
    paymentId: '',
    paymentSuccessPath: '',
    publicApiKey: '',
    totAmount: 0,
    transactionId: '',
    status: PaymentState.Initialized,
    message: '',
};

export const initialBillingDetailData = {
    id: 0,
    pricePerReturn: 0,
    totalReturns: 0,
    remainingReturns: 0,
    lastPurchase: 0,
    deliveryMode: DeliveryMode.ElectronicFiled,
};

export interface IPaymentReceiptLine {
    name: string;
    quntity: number;
    costPerItem: number;
    total: number;
    docType: string;
}

export interface IPaymentReceiptInfo {
    totalLineAmount: number;
    totalAmount: number;
    taxAmount: number;
    lineItems: IPaymentReceiptLine[];
    invoiceNumber: number;
}

export const paymentReceiptInit: IPaymentReceiptInfo = {
    totalLineAmount: 0,
    totalAmount: 0,
    taxAmount: 0,
    lineItems: [],
    invoiceNumber: 0,
};

interface ReceiveBillingDetail {
    type: actionTypes.RECEIVE_BILLING_DETAIL;
    billingDetail: IBillingDetailData;
}

export interface RequestSavePurchase {
    type: actionTypes.REQUEST_SAVE_PURCHASE;
    purchaseViewModel: IPaymentViewModel;
}
export interface ResponseSavePurchase {
    type: actionTypes.RESPONSE_SAVE_PURCHASE;
    paymentDetails: IPaymentDetails;
}

export type KnownAction = ReceiveBillingDetail | RequestSavePurchase | ResponseSavePurchase | NotificationAction | OverlayLoaderAction;