import { useEffect, useState } from 'react';
import * as Bs from 'react-bootstrap';

export const AnimatedAlert = ({ message, show }: { message: string, show: boolean }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        if (show) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsLeaving(true);
                setTimeout(() => {
                    setIsVisible(false)
                    setIsLeaving(false);
                }, 500);
            }, 3000);
        }
    }, [show]);

    if (!isVisible) return null;

    return (
        <div className={`
        alert-container
        animate-in fade-in duration-300
        ${isLeaving ? 'animate-out fade-out-up duration-500' : ''}
      `}>
            <Bs.Alert variant="danger" style={{ borderRadius: '0.5rem' }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg> &nbsp;

                {message}

            </Bs.Alert >
        </div>
    );
};
